import React from 'react';
import { FilterBranch } from '../../types';
import SearchFormFilter from '../SearchFormFilter/SearchFormFilter';
import { Box } from '@mui/material';
import { useFilterBranchUpdate, useFilterDelete } from '../../hooks';
import SearchFormFilterAddButton from '../SearchFormFilterAddButton';
import CollapsePanel from '../CollapsePanel';
import NestedBorderBox from '../NestedBorderBox';

interface SearchFormFilterGroupProps extends FilterBranch {
  depth: number;
  id: string;
  index: number;
  isRoot?: boolean;
}

const SearchFormFilterBranch = ({
  connective,
  depth,
  id,
  index,
  isRoot,
  ...rest
}: SearchFormFilterGroupProps) => {
  const { children } = rest;
  const updateFilterBranch = useFilterBranchUpdate();
  const deleteFilter = useFilterDelete();

  function handleAddChild(newId: string) {
    updateFilterBranch({
      id,
      changes: {
        children: [...children, newId],
      },
    });
  }

  const handleDeleteFilter = () => {
    deleteFilter(id);
  };

  if (isRoot) {
    return (
      <Box
        display="flex"
        alignItems="flex-start"
        py={1}
        flexDirection="column"
        bgcolor="transparent"
      >
        <Box width="100%">
          {children.map((childId, childIndex) => (
            <SearchFormFilter
              key={childId}
              depth={depth + 1}
              id={childId}
              index={childIndex}
            />
          ))}
          <Box pt={children.length > 0 ? 2 : 0}>
            <SearchFormFilterAddButton
              depth={depth}
              parentId={id}
              onClick={handleAddChild}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <CollapsePanel name="group" onRemove={handleDeleteFilter}>
      <NestedBorderBox pl={2} py={2} depth={depth}>
        {children.map((childId, childIndex) => (
          <SearchFormFilter
            key={childId}
            depth={depth + 1}
            id={childId}
            index={childIndex}
          />
        ))}
        <Box pt={children.length > 0 ? 2 : 0}>
          <SearchFormFilterAddButton
            depth={depth}
            parentId={id}
            onClick={handleAddChild}
          />
        </Box>
      </NestedBorderBox>
    </CollapsePanel>
  );
};

export default SearchFormFilterBranch;
