import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/system';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import {
  PossibleInputOptions,
  SelectInputOptions,
} from '../EntityTypeDetailInputOptionsModal/EntityTypeDetailInputOptionsModal';
import {
  LocalSelectInputOptions,
  RemoteSelectInputOptions,
} from '../../types/newEntityTypeTypes';
import EntityTypeFieldManagedSelectInputOptionsForm from './EntityTypeFieldManagedSelectInputOptionsForm';
import EntityTypeFieldManualSelectInputOptionsForm from './EntityTypeFieldManualSelectInputOptionsForm';

interface EntityTypeFieldSelectInputOptionsFormProps {
  setInputOptions: Dispatch<SetStateAction<PossibleInputOptions>>;
  inputOptions: SelectInputOptions;
  columnId: string;
}

enum ListType {
  MANAGED,
  MANUAL,
}

const EntityTypeFieldSelectInputOptionsForm = ({
  setInputOptions,
  inputOptions,
  columnId,
}: EntityTypeFieldSelectInputOptionsFormProps) => {
  const isManual = (
    obj: RemoteSelectInputOptions | LocalSelectInputOptions,
  ): obj is LocalSelectInputOptions => {
    //if it has an options field, its manually defined.
    return !!obj.options;
  };

  React.useEffect(() => {
    console.log('isManual ?:', isManual(inputOptions));
  }, [inputOptions]);
  const [useManagedList, setUseManagedList] = React.useState<ListType | null>(
    isManual(inputOptions) ? ListType.MANUAL : ListType.MANAGED,
  );

  const handleManagedToggle = () => {
    let selectType;
    setUseManagedList((prev) => {
      selectType =
        prev === ListType.MANUAL ? ListType.MANAGED : ListType.MANUAL;
      return selectType;
    });
    if (selectType === ListType.MANAGED) {
      console.log('saving managed');
      setInputOptions((prev) => {
        const { omit: options, ...rest } = prev;
        return { ...rest };
      });
    } else {
      setInputOptions((prev) => {
        console.log('saving manual');
        return {
          ...prev,
          options: [],
        };
      });
    }
  };

  return (
    <Box>
      <label>
        <Box py={1} color="grey.200">
          <Typography variant="h6" color="inherit">
            Use managed list, or manually create a list?
          </Typography>
        </Box>
        <RadioGroup value={useManagedList} onChange={handleManagedToggle}>
          <Box display="flex">
            <FormControlLabel
              value={ListType.MANAGED}
              control={<Radio />}
              label="Use managed list"
            />
            <FormControlLabel
              value={ListType.MANUAL}
              control={<Radio />}
              label="Manually enter List"
            />
          </Box>
        </RadioGroup>
      </label>
      <Box>
        {useManagedList !== null &&
          (useManagedList === ListType.MANAGED ? (
            <EntityTypeFieldManagedSelectInputOptionsForm
              setInputOptions={
                setInputOptions as Dispatch<
                  SetStateAction<RemoteSelectInputOptions>
                >
              }
              inputOptions={inputOptions as RemoteSelectInputOptions}
              columnId={columnId}
            />
          ) : (
            <EntityTypeFieldManualSelectInputOptionsForm
              setInputOptions={
                setInputOptions as Dispatch<
                  SetStateAction<LocalSelectInputOptions>
                >
              }
              inputOptions={inputOptions as LocalSelectInputOptions}
            />
          ))}
      </Box>
    </Box>
  );
};

export default EntityTypeFieldSelectInputOptionsForm;
