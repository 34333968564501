// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import * as serviceWorker from './serviceWorker';
import { Route, Routes, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';
import './fonts/Akkurat-Bold.ttf';
import './fonts/Akkurat-Normal.ttf';
import './index.css';
import { RouterProvider } from 'react-router';

// A function that routes the user to the right place
// after login
const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (
  appState,
) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

const audience = env('API_AUDIENCE');
const domain = env('API_LOGIN_DOMAIN');
const clientId = env('API_CLIENT_ID');
const useFullStory = env('FULLSTORY_ENABLED');
const useSentry = env('SENTRY_ENABLED');

if (useFullStory === 'TRUE') {
  FullStory.init({ orgId: 'GWQX1' });
}
if (useSentry === 'TRUE') {
  Sentry.init({
    dsn: 'https://797c5996e7744902a0907231f07a44a9@o247991.ingest.sentry.io/5263687',
  });
}

const AppWrapper = () => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <Routes>
        <Route path="/*" element={<PrivateRoute element={<App />} />} />
      </Routes>
    </Auth0Provider>
  );
};

const router = createBrowserRouter([
  {
    path: '*',
    Component: AppWrapper,
  },
]);

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw Error('No root element found. Please contact the Snthesis team.');
}
const root = createRoot(rootElement);

root.render(<RouterProvider router={router} />);

serviceWorker.unregister();
