import React from 'react';
import { Box } from '@mui/system';
import {
  PossibleAddedValidation,
  WorkingAreaValidations,
} from './EntityTypeDetailsFieldValidationsModal';
import SNInput from '../SNInput';
import {
  FieldDefinition,
  MemberOfValidation,
} from '../../types/newEntityTypeTypes';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '../Autocomplete';

interface MemberOfFieldValidationFormProps {
  setAddedValidation: React.Dispatch<
    React.SetStateAction<WorkingAreaValidations>
  >;
  addedValidation: PossibleAddedValidation;
  fields: FieldDefinition[];
}

const MemberOfFieldValidationForm = ({
  addedValidation,
  setAddedValidation,
  fields,
}: MemberOfFieldValidationFormProps) => {
  const currentValues = React.useMemo(() => {
    return addedValidation.validation as MemberOfValidation;
  }, [addedValidation]);
  const [_parentFieldInput, setParentFieldInput] = React.useState('');

  const handleChange = (e: any) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  const handleParentFieldSelect = (e: any, value: FieldDefinition | null) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            parent_field: value?.id,
          },
        },
      };
    });
  };

  const handleFieldInputChange = (e: any) => {
    setParentFieldInput(e.target.value);
  };

  return (
    <Box>
      <Box my={2}>
        <Typography color="textSecondary">
          A &#39;Member Of&#39; validation checks a value against an ontology
          graph
        </Typography>
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Ontology"
          name="ontology"
          helperText={
            currentValues.ontology || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.ontology}
        />
      </Box>
      <Box m={1}>
        <SNInput
          label="Depth"
          name="depth"
          type="number"
          onChange={handleChange}
          value={currentValues.depth}
        />
      </Box>
      <Box m={1}>
        <Autocomplete
          multiple={false}
          freeSolo={false}
          onChange={handleParentFieldSelect}
          onInputChange={handleFieldInputChange}
          options={fields}
          value={fields.find((field) => {
            return field.id === currentValues.parent_field;
          })}
          getOptionLabel={(option) => option.id.split('.')[1]}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              key={option.id}
              display="flex"
              sx={{ whiteSpace: 'nowrap' }}
              overflow="hidden"
            >
              <Box>{option.id.split('.')[1]}</Box>
              <Box
                pl={1}
                overflow="hidden"
                textOverflow="ellipsis"
                color="text.secondary"
              >
                <em>{option.description}</em>
              </Box>
            </Box>
          )}
          renderInput={({ InputProps, InputLabelProps, ...params }) => (
            <SNInput
              {...InputProps}
              {...params}
              className=""
              autoFocus
              label="Parent Column"
              placeholder="Search for column"
              startAdornment={
                <Box display="flex" ml={1}>
                  <SearchIcon />
                </Box>
              }
            />
          )}
        />
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Error Message"
          name="message"
          helperText={
            currentValues.message || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.message}
        />
      </Box>
    </Box>
  );
};

export default MemberOfFieldValidationForm;
