import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditableText from '../../components/EditableText';
import { SearchSaveAdd, SearchSaveEdit } from '../../components/SearchSave';
import SNButton from '../../components/SNButton';
import {
  searchTreeActions,
  TabOverflowProvider,
  useSaveSearchDispatch,
  useSearchTreeDispatch,
  useUserState,
} from '../../contexts';
import { useUpdateSavedSearchMutation } from '../../mutations';
import { useGetSavedSearchQuery } from '../../queries';
import styled from '../../services/styled';
import { searchQueryTransform } from '../../utils';
import { IconMenu } from '../../components';
import LoadingOverlay from '../../components/LoadingOverlay';
import ReviewBanner from '../../components/ReviewBanner';
import {
  useShowFiltersDispatch,
  useShowFiltersState,
} from '../../contexts/ShowFiltersContext';
import SearchFilterToggle from '../../components/SearchFilterToggle';
import { unsavedSearchVar } from '../../localState';
import { useRecoilValue } from 'recoil';
import { ScreenWidthAtom } from '../../atoms/screenWidth';
import SNDialogTitle from '../../components/SNDialogTitle';
import ReviewContainer from '../ReviewContainer/ReviewContainer';

const SearchToolbarButton = styled(SNButton)`
  min-width: 150px;
`;

const ReviewEdit = () => {
  const [localName, setLocalName] = React.useState('');
  const { id } = useParams<{ id: string }>();
  const setSaveSearchState = useSaveSearchDispatch();
  const dispatch = useSearchTreeDispatch();
  const navigate = useNavigate();
  const user = useUserState();
  const screenWidth = useRecoilValue(ScreenWidthAtom);
  const showFilters = useShowFiltersState();
  const setShowFilters = useShowFiltersDispatch();

  const [updateSaveSearch, { loading: updating }] =
    useUpdateSavedSearchMutation();

  const { data, loading } = useGetSavedSearchQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: id ? id : '',
    },
  });

  React.useEffect(() => {
    if (data?.getSavedSearch.data?.name) {
      setLocalName(data?.getSavedSearch.data.name);
    }
  }, [data?.getSavedSearch.data?.name]);

  React.useEffect(() => {
    if (data?.getSavedSearch.data?.searchQuery) {
      const { branches, roots } = searchQueryTransform(
        data.getSavedSearch.data.searchQuery,
      );
      dispatch(
        searchTreeActions.tree.set({
          key: id ? id : '',
          branches,
          roots,
        }),
      );
      unsavedSearchVar(false);
    }
  }, [data?.getSavedSearch.data?.searchQuery, dispatch, id]);

  const overwritePermission = React.useMemo(() => {
    if (data?.getSavedSearch.data?.user) {
      return data.getSavedSearch.data.user.id === user.userId;
    }
  }, [data, user]);

  const notFound = Boolean(
    !data?.getSavedSearch.data &&
      data?.getSavedSearch?.errors &&
      data.getSavedSearch.errors.length > 0,
  );

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalName(event.target.value);
    },
    [],
  );

  const handleNameBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (
        data?.getSavedSearch.data?.name !== value &&
        data?.getSavedSearch.data?.searchQuery
      ) {
        updateSaveSearch({
          variables: {
            input: {
              id: id ? id : '',
              name: event.target.value,
              searchQuery: data?.getSavedSearch.data?.searchQuery,
              public: data?.getSavedSearch.data?.public || false,
            },
          },
        });
      }
    },
    [data, id, updateSaveSearch],
  );

  const handleSaveClick = React.useCallback(() => {
    setSaveSearchState('SAVE');
  }, [setSaveSearchState]);

  const handleSaveAsClick = React.useCallback(() => {
    setSaveSearchState('SAVE_AS');
  }, [setSaveSearchState]);

  const handleSaveComplete = React.useCallback(() => {
    unsavedSearchVar(false);
  }, []);

  if (!id) {
    return null;
  }

  return (
    <Box position="relative">
      <ReviewBanner
        onBack={navigate.length > 2 ? () => navigate(-1) : undefined}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        py={2}
        px={4}
      >
        <Tooltip title="Rename">
          <Box ml={-1} minWidth={208} position="relative">
            <Box position="absolute" left={0} right={0}>
              <EditableText
                value={localName}
                onBlur={handleNameBlur}
                onChange={handleNameChange}
                disableUnderline
              />
            </Box>
            <Box visibility="hidden" px={2}>
              <Typography variant="h1">{localName}</Typography>
            </Box>
          </Box>
        </Tooltip>
        <Box>
          <Grid container spacing={1}>
            {screenWidth > 1160 && (
              <>
                <Grid item>
                  <SearchFilterToggle />
                </Grid>
                {overwritePermission && (
                  <Grid item>
                    <SearchToolbarButton
                      snVariant="text"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                    >
                      Save Search
                    </SearchToolbarButton>
                  </Grid>
                )}
              </>
            )}
            <Grid item>
              <IconMenu
                orientation="horizontal"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    width: 142,
                  },
                }}
              >
                {overwritePermission && screenWidth < 1160 && (
                  <MenuItem onClick={handleSaveClick}>Save Search</MenuItem>
                )}
                <MenuItem onClick={handleSaveAsClick}>Save As...</MenuItem>
                {screenWidth < 1160 && (
                  <MenuItem
                    onClick={() => setShowFilters((prevState) => !prevState)}
                  >
                    {showFilters ? 'Hide' : 'Show'} filters
                  </MenuItem>
                )}
              </IconMenu>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <TabOverflowProvider>
        <ReviewContainer searchId={id} />
      </TabOverflowProvider>
      <LoadingOverlay isLoading={loading || updating} />
      <SearchSaveEdit
        id={id}
        onSaveComplete={handleSaveComplete}
        searchId={id}
        title="Save Review"
      />
      <SearchSaveAdd
        defaultName={`${localName} - copy`}
        defaultDescription={data?.getSavedSearch.data?.description || undefined}
        onSaveComplete={handleSaveComplete}
        searchId={id}
        title="Save Review As"
      />
      <Dialog open={notFound}>
        <SNDialogTitle>Review Not Found</SNDialogTitle>
        <DialogContent>
          {data?.getSavedSearch?.errors?.map((error, index) => (
            <Typography key={`${error}_${index}`}>{error}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          {history.length > 2 && (
            <SNButton onClick={() => navigate(-1)}>Go Back</SNButton>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReviewEdit;
