import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { LinkTypesQueryVariables } from '../types/new/graphql';

export const LINK_TYPES = gql`
  query LinkTypes {
    linkTypes {
      id
      name
      description
      fields {
        id
        name
        description
        hiddenInReadMode
        alwaysShowField
        inputOptions {
          ... on TextInputOptions {
            minLength
            maxLength
            type
          }
          ... on LocalSelectInputOptions {
            options {
              value
              text
            }
            type
            minItems
            maxItems
          }
          ... on NumericInputOptions {
            type
            minValue
            maxValue
          }
          ... on DateTimeInputOptions {
            minDate
            maxDate
            captureTime
          }
        }
        dataPoint {
          id
          name
          description
          dataType
        }
        dependsOn {
          fieldId
          trigger {
            ... on MaximumDependencyTrigger {
              max
              maxInclusive
            }
            ... on MinimumDependencyTrigger {
              min
              minInclusive
            }
            ... on PatternDependencyTrigger {
              pattern
            }
            ... on RangeDependencyTrigger {
              max
              maxInclusive
              min
              minInclusive
            }
            ... on ValueDependencyTrigger {
              value
            }
          }
        }
        validationRules {
          required
        }
      }
    }
  }
`;

export const useLinkTypesQuery = (options?: QueryHookOptions) => {
  return useQuery<LinkTypesQueryVariables>(LINK_TYPES, { ...options });
};
