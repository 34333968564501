import { Box } from '@mui/system';
import SelectableListToolbar from '../SelectableListToolbar';
import ListSelectionControl from '../ListSelectionControl';
import SNPagination from '../SNPagination';
import { Dialog, Grid } from '@mui/material';
import ToolbarActionGroup from '../ToolbarActionGroup';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import ListSearchControl from '../ListSearchControl';
import React from 'react';
import { useDebounce } from '../../hooks';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import SNTable from '../SNTable';
import { GenericItemModalOpen } from '../EntityTypeDetailFieldsTable/EntityTypeDetailsFieldsTable';
import EntityTypeDetailRelationshipModal from '../EntityTypeDetailRelationshipModal';
import EntityTypeDetailsRelationshipTableRow from '../EntityTypeDetailsRelationshipTableRow';
import EditIcon from '@mui/icons-material/Edit';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import { useLinkTypesQuery } from '../../queries/useLinkTypesQuery';
import { useEntityTypesQuery } from '../../queries';

const RelationshipTableHeaders = [
  'Name',
  'Link Type',
  'Allowed Entity Types',
  'Fields',
];

const EntityTypeDetailsRelationshipTable = () => {
  //where do we get this from? prob should be an env var.
  const namespace = 'sn';
  const [entityTypeInfo, _setEntityTypeInfo] =
    useRecoilState(newEntityTypeAtom);

  const setPageIds = useSetRecoilState(pageIdsAtom);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);

  const relationships = React.useMemo(() => {
    return entityTypeInfo.relationships;
  }, [entityTypeInfo]);

  const { data: entityTypesData } = useEntityTypesQuery();
  const { data: linkTypesData } = useLinkTypesQuery();

  const [searchTerm, setSearchTerm] = React.useState('');
  const _debouncedSearchTerm = useDebounce(searchTerm, 200);

  const [relationshipModalOpenId, setRelationshipModalOpenId] =
    React.useState<GenericItemModalOpen>({ open: false, columnId: undefined });

  const relationshipValues = Object.values(relationships);
  const relationshipKeys = Object.keys(relationships);

  React.useEffect(() => {
    setPageIds(relationshipKeys);
  }, [setPageIds, relationshipKeys]);

  const handleNewRelationship = () => {
    setRelationshipModalOpenId({ open: true, columnId: `${namespace}:new` });
  };

  const handleCloseRelationshipModal = () => {
    setRelationshipModalOpenId({ open: false, columnId: undefined });
  };

  const handleEditRelationship = () => {
    setRelationshipModalOpenId({ open: true, columnId: selectedIds[0] });
    resetSelectedIds();
  };

  return (
    <Box>
      <Box>
        <SelectableListToolbar>
          <Box display="flex" alignItems="center" pl={2}>
            <Box display="flex" alignItems="center" pr={2}>
              <ListSelectionControl />
            </Box>
            <SNPagination
              loading={false}
              pageSize={15}
              pageTotal={1}
              totalCount={relationshipKeys.length}
            />
          </Box>
          <Box>
            <Grid container spacing={1}>
              <ToolbarActionGroup
                comparisonFunction={(count: number) => count === 0}
              >
                <Dialog open={relationshipModalOpenId.open} fullWidth>
                  <EntityTypeDetailRelationshipModal
                    onClose={handleCloseRelationshipModal}
                    relationshipId={relationshipModalOpenId.columnId as string}
                    linkTypesData={linkTypesData?.linkTypes}
                  />
                </Dialog>
                <Grid item>
                  <SNButton
                    startIcon={<AddIcon />}
                    snVariant="text"
                    onClick={handleNewRelationship}
                  >
                    New Relationship
                  </SNButton>
                </Grid>
                <Grid item>
                  <ListSearchControl
                    setValue={setSearchTerm}
                    value={searchTerm}
                  />
                </Grid>
              </ToolbarActionGroup>
              <ToolbarActionGroup
                comparisonFunction={(count: number) => count === 1}
              >
                <Grid item>
                  <SNButton
                    startIcon={<EditIcon />}
                    snVariant="text"
                    onClick={handleEditRelationship}
                  >
                    Edit Relationship
                  </SNButton>
                </Grid>
              </ToolbarActionGroup>
            </Grid>
          </Box>
        </SelectableListToolbar>
        <SNTable
          headers={RelationshipTableHeaders}
          hasResults={relationshipKeys.length > 0}
          id="entity-type-relationships-table"
          loading={false}
          rowCount={relationshipKeys.length}
          selectable
        >
          {relationshipKeys.map((key, index) => {
            return (
              <EntityTypeDetailsRelationshipTableRow
                key={key}
                relationship={relationshipValues[index]}
                index={index}
                linkTypes={linkTypesData?.linkTypes}
                allEntityTypes={entityTypesData?.entityTypes}
              />
            );
          })}
        </SNTable>
      </Box>
    </Box>
  );
};

export default EntityTypeDetailsRelationshipTable;
