import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  useDataPointsQuery,
  useEntityTypesQuery,
  useSearchTargetsQuery,
  useSystemFields,
  useUserQuery,
} from './queries';
import {
  SearchTreeProvider,
  UploadProvider,
  useUserState,
  SaveSearchProvider,
} from './contexts';
import NewSearchButton from './components/NewSearchButton';
import ProfileTabs from './components/ProfileTabs';
import TopBarNavItem from './components/TopBarNavItem';
import UploadSnackbar from './components/UploadSnackbar';
import {
  DataViews,
  EntityDetail,
  ReviewEdit,
  ReviewNew,
  SearchEdit,
  SearchNew,
  TopBar,
  Uploads,
} from './layout';
import ExportSnackbar from './components/ExportSnackbar';
import { ScreenWidthAtom } from './atoms/screenWidth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ShowFiltersProvider } from './contexts/ShowFiltersContext';
import SettingsTabs from './layout/SettingsTabs/SettingsTabs';
import ProjectDetail from './layout/ProjectDetail';
import UserDetail from './layout/UserDetail';
import GroupDetail from './layout/GroupDetail';
import ProtectedRoute from './components/ProtectedRoute';
import { SETTINGS_ACCESS } from './constants/rolesAndPermissions';
import { searchTableColumnsSelector } from './atoms/entityTableColumns';
import Review from './layout/Review';
import ClassificationJobsSnackbar from './components/ClassificationJobsSnackbar';
import { latestSearchAtom } from './atoms/latestSearchAtom';
import { entityTypeTabMap } from './atoms/entityTypes';
import { entityTypeFieldsToColumns } from './utils/entityTypeFieldsToColumns';
import GeneralErrorSnackbar from './components/GeneralErrorSnackbar';
import { GeneralErrorSnackbarAtom } from './atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from './utils/collectErrors';
import ListItemsTable from './components/ListItemsTable/ListItemsTable';
import EntityTypeDetails from './components/EntityTypeDetails/EntityTypeDetails';
import DataPointsTable from './components/DataPointsTable';
import LinkTypesTable from './components/LinkTypesTable';
import LinkTypeDetails from './components/LinkTypeDetails';

const AppContent = () => {
  const user = useUserState();
  const setSearchTableColumns = useSetRecoilState(searchTableColumnsSelector);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const setTabMap = useSetRecoilState(entityTypeTabMap);
  const { data: userData } = useUserQuery({
    variables: { id: user.userId },
  });
  useSearchTargetsQuery();
  useSystemFields();
  useDataPointsQuery();
  const { data: entityTypesData } = useEntityTypesQuery();

  React.useEffect(() => {
    if (entityTypesData?.entityTypes) {
      setSearchTableColumns(
        entityTypeFieldsToColumns(entityTypesData.entityTypes),
      );
      setTabMap(
        entityTypesData.entityTypes.reduce(
          (result, type) => ({ ...result, [type.id]: type.name }),
          {},
        ),
      );
    }
  }, [entityTypesData, setSearchTableColumns, setTabMap]);

  const setScreenWidth = useSetRecoilState(ScreenWidthAtom);
  window.onresize = React.useCallback(() => {
    setScreenWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    );
  }, [setScreenWidth]);

  const latestSearch = useRecoilValue(latestSearchAtom);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([userData?.user?.errors]);
  }, [userData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Unable to fetch user info',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <TopBar>
        <Box>
          <Grid container spacing={4} alignItems="center">
            <Grid item>
              <TopBarNavItem to="/upload">Upload</TopBarNavItem>
            </Grid>
            <Grid item>
              <TopBarNavItem to="/review">Review</TopBarNavItem>
            </Grid>
            <Grid item>
              <TopBarNavItem
                to="/search/latest"
                additionalMatches={['/search']}
              >
                Search
              </TopBarNavItem>
            </Grid>
            <Grid item>
              <TopBarNavItem to="/data-views">Data Views</TopBarNavItem>
            </Grid>
            <ProtectedRoute requiredRoles={SETTINGS_ACCESS}>
              <Grid item>
                <TopBarNavItem to="/settings">Settings</TopBarNavItem>
              </Grid>
            </ProtectedRoute>
          </Grid>
        </Box>
      </TopBar>
      <Box position="absolute" top={72} overflow="auto" width="100%">
        <SearchTreeProvider>
          <SaveSearchProvider>
            <UploadProvider>
              <ShowFiltersProvider>
                <Routes>
                  <Route path="/" element={<Navigate to="/upload" />} />
                  <Route path="/upload/*" element={<Uploads />}>
                    <Route path=":tab" element={<Uploads />} />
                  </Route>

                  <Route
                    path="/review/entity/:id/:tab"
                    element={<EntityDetail />}
                  >
                    <Route path=":tab" element={<EntityDetail />} />
                  </Route>
                  <Route path="/review/saved/new" element={<ReviewNew />} />
                  <Route path="/review/saved/:id" element={<ReviewEdit />} />
                  <Route path="/review/*" element={<Review />}>
                    <Route path=":tab" element={<Review />} />
                  </Route>

                  <Route path="/search/entity/:id/*" element={<EntityDetail />}>
                    <Route path=":tab" element={<EntityDetail />} />
                  </Route>
                  <Route path="/search/new" element={<SearchNew />} />
                  <Route
                    path="/search/latest"
                    element={<Navigate to={`/search/${latestSearch}`} />}
                  />
                  <Route path="/search/:id" element={<SearchEdit />} />
                  <Route
                    path="/search"
                    element={<Navigate to={`/search/${latestSearch}`} />}
                  />

                  <Route path="/data-views/*" element={<DataViews />}>
                    <Route path=":tab" element={<DataViews />} />
                  </Route>

                  <Route path="/profile/*" element={<ProfileTabs />}>
                    <Route path=":tab" element={<ProfileTabs />} />
                  </Route>

                  <Route
                    path="/settings/entity-type/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <EntityTypeDetails />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<EntityTypeDetails />} />
                  </Route>

                  <Route
                    path="/settings/projects/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <ProjectDetail />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<ProjectDetail />} />
                  </Route>

                  <Route
                    path="/settings/data-points/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <Route path=":tab" element={<DataPointsTable />} />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<DataPointsTable />} />
                  </Route>

                  <Route
                    path="/settings/users/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <UserDetail />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<UserDetail />} />
                  </Route>

                  <Route
                    path="/settings/groups/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <GroupDetail />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<GroupDetail />} />
                  </Route>

                  <Route
                    path="/settings/link-types/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <LinkTypeDetails />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<LinkTypesTable />} />
                  </Route>

                  <Route
                    path="/settings/lists/:id/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <ListItemsTable />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<GroupDetail />} />
                  </Route>

                  <Route
                    path="/settings/*"
                    element={
                      <ProtectedRoute
                        redirect="/upload"
                        requiredRoles={SETTINGS_ACCESS}
                      >
                        <SettingsTabs />
                      </ProtectedRoute>
                    }
                  >
                    <Route path=":tab" element={<SettingsTabs />} />
                  </Route>
                </Routes>
                <NewSearchButton />
                <UploadSnackbar />
                <ExportSnackbar />
                <GeneralErrorSnackbar />
                <ClassificationJobsSnackbar />
              </ShowFiltersProvider>
            </UploadProvider>
          </SaveSearchProvider>
        </SearchTreeProvider>
      </Box>
    </Box>
  );
};

export default AppContent;
