import { Box } from '@mui/system';
import { FieldDefinition } from '../../types/newEntityTypeTypes';
import { useRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import { DataFieldDefinition, EntityType } from '../../types/new/graphql';
import React, { Dispatch, SetStateAction } from 'react';
import Autocomplete from '../Autocomplete';
import { Typography } from '@mui/material';
import SNInput from '../SNInput';
import SearchIcon from '@mui/icons-material/Search';
import {
  WorkingFieldRef,
  WorkingFieldRefsByLinkableType,
} from './EntityTypeDetailRelationshipModal';

interface EntityTypeRelationshipModalPairedColumnsFormProps {
  foreignEntityType: EntityType | undefined;
  fieldReference?: WorkingFieldRef;
  linkableTypeId: string;
  setFieldReferences: Dispatch<
    SetStateAction<WorkingFieldRefsByLinkableType[]>
  >;
}

const EntityTypeRelationshipModalPairedColumnsForm = ({
  fieldReference,
  foreignEntityType,
  setFieldReferences,
  linkableTypeId,
}: EntityTypeRelationshipModalPairedColumnsFormProps) => {
  const [entityTypeInfo, _setEntityTypeInfo] =
    useRecoilState(newEntityTypeAtom);

  const [foreignFieldInputValue, setForeignFieldInputValue] =
    React.useState('');
  const [localFieldInputValue, setLocalFieldInputValue] = React.useState('');

  const localFieldOptions: FieldDefinition[] = React.useMemo(() => {
    return Object.values(entityTypeInfo.fields);
  }, [entityTypeInfo]);
  const foreignFieldOptions: DataFieldDefinition[] = React.useMemo(() => {
    return foreignEntityType?.fields || [];
  }, [foreignEntityType]);

  if (!fieldReference) {
    return null;
  }

  const handleForeignFieldValueChange = (e: any, value: string) => {
    setForeignFieldInputValue(value);
  };
  const handleLocalFieldValueChange = (e: any, value: string) => {
    setLocalFieldInputValue(value);
  };

  const changeLocalColumn = (e: any, value: FieldDefinition | null) => {
    setFieldReferences((prev) => {
      const linkableTypeToChange = prev.find((type) => {
        return type.linkableTypeId === linkableTypeId;
      });
      const restOfTypes = prev.filter((type) => {
        return type.linkableTypeId !== linkableTypeId;
      });

      const changedType: WorkingFieldRefsByLinkableType = {
        linkableTypeId: linkableTypeId,
        fieldRefs: {
          ...linkableTypeToChange?.fieldRefs,
          [fieldReference.id]: {
            id: fieldReference.id,
            ref: {
              local_column: value?.id || '',
              foreign_column: fieldReference.ref.foreign_column,
            },
          },
        },
      };

      return [...restOfTypes, { ...changedType }];
    });
  };

  const changeForeignColumn = (e: any, value: DataFieldDefinition | null) => {
    setFieldReferences((prev) => {
      const linkableTypeToChange = prev.find((type) => {
        return type.linkableTypeId === linkableTypeId;
      });
      const restOfTypes = prev.filter((type) => {
        return type.linkableTypeId !== linkableTypeId;
      });

      const changedType: WorkingFieldRefsByLinkableType = {
        linkableTypeId: linkableTypeId,
        fieldRefs: {
          ...linkableTypeToChange?.fieldRefs,
          [fieldReference.id]: {
            id: fieldReference.id,
            ref: {
              local_column: fieldReference.ref.local_column,
              foreign_column: value?.id || '',
            },
          },
        },
      };

      return [...restOfTypes, { ...changedType }];
    });
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box mr={2}>
        <Autocomplete
          multiple={false}
          freeSolo={false}
          onChange={changeLocalColumn}
          inputValue={localFieldInputValue}
          onInputChange={handleLocalFieldValueChange}
          value={localFieldOptions.find((opt) => {
            return opt.id === fieldReference.ref.local_column;
          })}
          options={localFieldOptions}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              key={option.label}
              display="flex"
              sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}
              overflow="hidden"
            >
              <Box>
                <Box>{option.label}</Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: '.6rem' }}
                    color="textSecondary"
                  >
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          renderInput={({ InputProps, InputLabelProps, ...params }) => (
            <SNInput
              {...InputProps}
              {...params}
              label="Local Field"
              className=""
              placeholder="Select Local Field"
              startAdornment={
                <Box display="flex" ml={1}>
                  <SearchIcon />
                </Box>
              }
            />
          )}
        />
      </Box>
      <Box>
        <Autocomplete
          multiple={false}
          freeSolo={false}
          onChange={changeForeignColumn}
          inputValue={foreignFieldInputValue}
          onInputChange={handleForeignFieldValueChange}
          value={foreignFieldOptions.find((opt) => {
            return opt.id === fieldReference.ref.foreign_column;
          })}
          options={foreignFieldOptions}
          getOptionLabel={(option) => option.name || ''}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              key={option.name}
              display="flex"
              sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}
              overflow="hidden"
            >
              <Box>
                <Box>{option.name}</Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: '.6rem' }}
                    color="textSecondary"
                  >
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          renderInput={({ InputProps, InputLabelProps, ...params }) => (
            <SNInput
              {...InputProps}
              {...params}
              label="Foreign Field"
              className=""
              placeholder="Select Foreign Field"
              startAdornment={
                <Box display="flex" ml={1}>
                  <SearchIcon />
                </Box>
              }
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default EntityTypeRelationshipModalPairedColumnsForm;
