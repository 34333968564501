import React from 'react';
import { Route, Routes } from 'react-router';
import UploadsTable from '../UploadsTable';
import { UploadTabsProvider } from '../../contexts';
import { SavedSearchType, UploadState } from '../../types/schemaTypes';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import QualityReviewDashboard from '../QualityReviewDashboard/QualityReviewDashboard';
import SavedSearchesTable from '../SavedSearchesTable';
import SNTabs from '../SNTabs';

const ReviewTabs = () => {
  const { tab } = useParams<{ tab?: string }>();
  const navigate = useNavigate();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      navigate(`/review/${tab}`);
    },
    [navigate],
  );

  const handleCreateReview = () => {
    navigate(`/review/saved/new`);
  };

  const handleSearchSelect = React.useCallback(
    (id: string) => {
      navigate(`/review/saved/${id}`);
    },
    [navigate],
  );

  return (
    <>
      <SNTabs
        active={tab || ''}
        onTabClick={handleTabClick}
        tabs={['dashboard', 'uploads', 'saved', 'public']}
      />
      <Routes>
        <Route index path="dashboard" element={<QualityReviewDashboard />} />
        <Route
          path="uploads"
          element={
            <UploadTabsProvider initialState={[UploadState.NEEDS_REVIEW]}>
              <UploadsTable disableStateFilter />
            </UploadTabsProvider>
          }
        />
        <Route
          path="saved"
          element={
            <SavedSearchesTable
              onNewClick={handleCreateReview}
              onSearchSelect={handleSearchSelect}
              savedSearchType={SavedSearchType.REVIEW}
            />
          }
        />
        <Route
          path="public"
          element={
            <SavedSearchesTable
              isPublic
              onSearchSelect={handleSearchSelect}
              savedSearchType={SavedSearchType.REVIEW}
            />
          }
        />
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Routes>
    </>
  );
};

export default ReviewTabs;
