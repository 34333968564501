import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/system';
import { PossibleInputOptions } from '../EntityTypeDetailInputOptionsModal/EntityTypeDetailInputOptionsModal';
import { DateTimeInputOptions } from '../../types/newEntityTypeTypes';
import SNDatePicker from '../SNDatePicker';
import moment, { Moment } from 'moment';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

interface EntityTypeFieldDateTimeInputOptionsFormProps {
  setInputOptions: Dispatch<SetStateAction<PossibleInputOptions>>;
  inputOptions: DateTimeInputOptions;
  setDefaultValue: Dispatch<SetStateAction<number | string | boolean>>;
  defaultValue: string | number | boolean;
}

const EntityTypeFieldDateTimeInputOptionsForm = ({
  setDefaultValue,
  setInputOptions,
  inputOptions,
  defaultValue,
}: EntityTypeFieldDateTimeInputOptionsFormProps) => {
  const handleMinDateChange = (date: Moment | null) => {
    const newDate = date ? moment(date.toString()).format('YYYY-MM-DD') : null;
    setInputOptions((prev) => {
      return {
        ...prev,
        minDateField: newDate?.toString(),
      };
    });
  };

  const handleMaxDateChange = (date: Moment | null) => {
    const newDate = date ? moment(date.toString()).format('YYYY-MM-DD') : null;
    setInputOptions((prev) => {
      return {
        ...prev,
        maxDateField: newDate?.toString(),
      };
    });
  };

  const handleDefaultDateChange = (date: Moment | null) => {
    const newDate = date ? moment(date.toString()).format('YYYY-MM-DD') : null;
    setDefaultValue(newDate?.toString() || '');
  };

  const handleIncludeTimeChange = (e: any) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        captureTime: e.target.value,
      };
    });
  };

  return (
    <Box>
      The following options will determine specifics for how this date column
      must be filled in. fill out as many or as few of the options as you
      prefer.
      <Box>
        <label>
          <Box py={1} color="grey.200">
            <Typography variant="h6" color="inherit">
              Minimum Date
            </Typography>
          </Box>
          <SNDatePicker
            value={moment(inputOptions.minDateField)}
            onChange={handleMinDateChange}
          />
        </label>
        <label>
          <Box py={1} color="grey.200">
            <Typography variant="h6" color="inherit">
              Maximum Date
            </Typography>
          </Box>
          <SNDatePicker
            value={moment(inputOptions.maxDateField)}
            onChange={handleMaxDateChange}
          />
        </label>
        <label>
          <Box py={1} color="grey.200">
            <Typography variant="h6" color="inherit">
              Include Time?
            </Typography>
          </Box>
          <RadioGroup
            aria-label="Export Options"
            value={inputOptions.captureTime}
            onChange={handleIncludeTimeChange}
          >
            <Box display="flex">
              <FormControlLabel
                value
                control={<Radio />}
                label="Include time and date"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Date only"
              />
            </Box>
          </RadioGroup>
        </label>
        <label>
          <Box py={1} color="grey.200">
            <Typography variant="h6" color="inherit">
              Default Value
            </Typography>
          </Box>
          <SNDatePicker
            value={moment(defaultValue.toString())}
            onChange={handleDefaultDateChange}
          />
        </label>
      </Box>
    </Box>
  );
};

export default EntityTypeFieldDateTimeInputOptionsForm;
