import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SNInputProps } from '../SNInput/SNInput';
import { Moment } from 'moment';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';

interface SNDatePickerProps extends Omit<SNInputProps, 'onChange' | 'value'> {
  onChange: (value: Moment | null) => void;
  onDateConfirm?: () => void;
  value: Moment | null;
}

const SNDatePicker: React.FC<SNDatePickerProps> = ({ onChange, value }) => {
  const handleChange = React.useCallback(
    (
      date: Moment | null,
      _context?: PickerChangeHandlerContext<DateValidationError>,
    ) => {
      onChange(date);
    },
    [onChange],
  );

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      slotProps={{
        field: {
          format: 'YYYY-MM-DD',
        },
      }}
    />
  );
};

export default SNDatePicker;
