import React, { PropsWithChildren } from 'react';
import { useUserState } from '../contexts';
import { UserRole } from '../types/schemaTypes';
import { useUserQuery } from '../queries';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps extends PropsWithChildren {
  redirect?: string;
  requiredRoles: UserRole[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirect,
  requiredRoles,
  children,
}) => {
  const user = useUserState();
  const { data, loading } = useUserQuery({ variables: { id: user.userId } });

  const hasPermission = React.useMemo(
    () =>
      data?.user?.user?.defaultRole &&
      requiredRoles.includes(data.user.user.defaultRole),
    [data?.user?.user?.defaultRole, requiredRoles],
  );

  if (loading) {
    return null;
  }

  if (!hasPermission && redirect) {
    return <Navigate to={redirect} />;
  }

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
