import React from 'react';
import { Box, Typography } from '@mui/material';
import { useEntityTypesQuery } from '../../queries';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '../../services/styled';
import { SearchTargetType } from '../../types/schemaTypes';
import { useBranchAdd, useRootsSet } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const StyledEntity = styled(Box)`
  width: 250px;
  height: 80px;
  margin: 8px;
  padding: 16px;
  background: #000000;
  cursor: pointer;
`;

interface SearchByEntityProps {
  target: string;
}

const SearchByEntity: React.FC<SearchByEntityProps> = ({ target }) => {
  const addBranch = useBranchAdd(target);
  const setRootIds = useRootsSet();
  const navigate = useNavigate();

  const { data, loading, error } = useEntityTypesQuery();

  if (!data || !data.entityTypes) {
    return null;
  }

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box>ERROR</Box>;
  }

  const addSearchTreeRootNode = (id: string) => {
    const [newRootId] = addBranch({
      target: {
        id,
        type: SearchTargetType.ENTITY_TYPE,
      },
      joinData: null,
    });
    setRootIds([newRootId], target);
    navigate('/search/new');
  };

  const handleEntityClick = (entityId: string) => {
    addSearchTreeRootNode(entityId);
  };

  return (
    <Box display="flex" flexWrap="wrap" px={3}>
      {data.entityTypes.map((entityType) => (
        <StyledEntity
          data-testid={`search_start_${entityType.name}`}
          key={entityType.id}
          onClick={() => handleEntityClick(entityType.id)}
        >
          <Box textOverflow="ellipsis">
            <Typography variant="h5">{entityType.name}</Typography>
          </Box>
          <Box mt={1}>
            <Typography color="textSecondary">
              {entityType.totalCount ? entityType.totalCount : '0'}
              &nbsp;results
            </Typography>
          </Box>
        </StyledEntity>
      ))}
    </Box>
  );
};

export default SearchByEntity;
