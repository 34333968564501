import { Box, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';

import AddIcon from '@mui/icons-material/Add';
import { useGetLazyProjectsQuery } from '../../queries/useGetProjectsQuery';
import SNTable from '../SNTable';
import ProjectsTableRow from '../ProjectsTableRow';
import SNPagination from '../SNPagination';
import { useDebounce } from '../../hooks';
import CreateProjectModal from '../CreateProjectModal';
import ToolbarActionGroup from '../ToolbarActionGroup';
import ListSearchControl from '../ListSearchControl';
import { useDeleteProjectMutation } from '../../mutations/useDeleteProjectMutation';
import { Delete } from '@mui/icons-material';
import OwnerOfProjectMask from '../OwnerOfProjectMask/OwnerOfProjectMask';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

const headers = ['name', 'description', 'users', 'groups', 'updated'];

const ProjectsTable = () => {
  const pageSize = 10;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [getProjects, { data, error, loading, fetchMore }] =
    useGetLazyProjectsQuery({
      onCompleted: (result) => {
        const pageIds = result?.projects?.data?.edges?.map(
          (edge) => edge.node.id,
        );
        setPageIds(pageIds || []);
      },
    });
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const [deleteProject, { data: deleteData }] = useDeleteProjectMutation({
    onCompleted: () => {
      resetSelectedIds();
    },
    refetchQueries: ['Projects'],
    variables: { input: { id: selectedIds[0] } },
  });

  React.useEffect(() => {
    getProjects({
      variables: {
        first: pageSize,
        filter: {
          searchTerm: debouncedSearchTerm,
        },
      },
    });
  }, [getProjects, debouncedSearchTerm, pageSize]);

  const projects = React.useMemo(
    () => data?.projects?.data?.edges?.map((edge) => edge.node),
    [data],
  );

  const pageTotal = projects?.length || 0;

  const setNewProjectModalClosed = () => {
    setNewProjectModalOpen(false);
  };

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      deleteData?.removeProject?.errors,
      data?.projects?.errors,
    ]);
  }, [deleteData, data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching or deleting projects',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.projects?.data?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.projects?.data?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <CreateProjectModal
                  isOpen={newProjectModalOpen}
                  setClosed={setNewProjectModalClosed}
                />
                <SNButton
                  startIcon={<AddIcon />}
                  snVariant="text"
                  onClick={() => setNewProjectModalOpen(true)}
                >
                  Add Project
                </SNButton>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <OwnerOfProjectMask projectId={selectedIds[0]}>
                  <SNButton
                    onClick={() => deleteProject()}
                    snVariant="destructive"
                    startIcon={<Delete />}
                  >
                    Delete Project
                  </SNButton>
                </OwnerOfProjectMask>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!projects}
        headers={headers}
        id="projects"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {projects?.map(
          ({ projectAccess, groupProjectAccess, ...project }, index) => (
            <ProjectsTableRow
              key={project.id}
              index={index}
              projectAccess={projectAccess?.edges}
              totalProjectAccessCount={projectAccess?.totalCount || 0}
              totalGroupCount={groupProjectAccess?.totalCount || 0}
              {...project}
            />
          ),
        )}
      </SNTable>
    </Box>
  );
};

export default ProjectsTable;
