import React, { PropsWithChildren, ReactNode } from 'react';
import styled from '../../services/styled';
import SNButton from '../SNButton';
import { Box, Typography, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useUploadWizardState,
  useUploadWizardDispatch,
  UploadWizardKeys,
} from '../../contexts';
import { SNVariant } from '../SNButton/SNButton';

const StyledWizardButton = styled(SNButton)`
  width: 252px;
  height: 80px;
  font-size: 18px;
`;

const StyledExpandControl = styled(Box)`
  cursor: pointer;
`;

interface UploadModalQuestionProps extends PropsWithChildren {
  optionAText?: string;
  optionAIcon?: React.ReactNode;
  optionAVariant?: SNVariant;
  optionAValue?: boolean;
  optionBText?: string;
  optionBIcon?: React.ReactNode;
  optionBVariant?: SNVariant;
  optionBValue?: boolean;
  field: UploadWizardKeys;
  question: string;
  details?: string | ReactNode;
}

const UploadModalQuestion: React.FC<UploadModalQuestionProps> = ({
  optionAText = 'Yes',
  optionAIcon = <CheckCircleIcon fontSize="large" />,
  optionAVariant = 'confirm',
  optionAValue = true,
  optionBText = 'No',
  optionBIcon = <CancelIcon fontSize="large" />,
  optionBVariant = 'deny',
  optionBValue = false,
  field,
  question,
  details,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const wizardState = useUploadWizardState();
  const setWizardSate = useUploadWizardDispatch();

  const handleClick = (value: boolean) => () => {
    setWizardSate((previous) => ({
      ...previous,
      [field]: value,
    }));
  };

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={534}>
        <Box fontSize={30} mb={4}>
          <Typography variant="h1">{question}</Typography>
        </Box>
        {details && (
          <Box>
            <StyledExpandControl
              display="flex"
              alignItems="center"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Typography variant="h4">More Info</Typography>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </StyledExpandControl>
            <Collapse in={isOpen}>
              {typeof details === 'string' ? (
                <Typography>{details}</Typography>
              ) : (
                <>{details}</>
              )}
            </Collapse>
          </Box>
        )}
        <Box width="100%" display="flex" justifyContent="space-between" mt={6}>
          <StyledWizardButton
            data-testid={`upload-wizard-${field}-${optionAValue.toString()}`}
            snVariant={optionAVariant}
            hover={wizardState[field] === optionAValue}
            startIcon={optionAIcon}
            onClick={handleClick(optionAValue)}
          >
            {optionAText}
          </StyledWizardButton>
          <StyledWizardButton
            data-testid={`upload-wizard-${field}-${optionBValue.toString()}`}
            snVariant={optionBVariant}
            hover={wizardState[field] === optionBValue}
            startIcon={optionBIcon}
            onClick={handleClick(optionBValue)}
          >
            {optionBText}
          </StyledWizardButton>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default UploadModalQuestion;
