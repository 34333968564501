import { Box, BoxProps } from '@mui/material';
import styled from '../../services/styled';
import React, { PropsWithChildren } from 'react';

interface NestedBorderBoxStyledProps extends BoxProps {
  depth: number;
}

export const NestedBorderBoxStyled = styled(
  ({ depth, ...rest }: NestedBorderBoxStyledProps) => <Box {...rest} />,
)`
  border-left: 1px solid
    ${({ theme, depth }) =>
      depth > 1 ? theme.palette.grey[600] : theme.palette.background.default};

  border-bottom: 1px solid
    ${({ theme, depth }) =>
      depth > 1 ? theme.palette.grey[600] : theme.palette.background.default};
`;

const NestedBorderBox: React.FC<
  PropsWithChildren<NestedBorderBoxStyledProps>
> = ({ depth, children, ...rest }: NestedBorderBoxStyledProps) => {
  return (
    <NestedBorderBoxStyled depth={depth} {...rest}>
      {children}
    </NestedBorderBoxStyled>
  );
};

export default NestedBorderBox;
