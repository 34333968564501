import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import {
  searchTreeActions,
  useCompleteSearchObject,
  useSearchTreeDispatch,
} from '../../contexts';
import SNButton from '../SNButton';
import { useRecoilValue } from 'recoil';
import { ScreenWidthAtom } from '../../atoms/screenWidth';
import { NEW_SEARCH_KEY } from '../../constants';
import SNDialogTitle from '../SNDialogTitle';
import { useToggle } from '../../hooks/useToggle';

const NewSearchButton = () => {
  const newSearchNode = document.getElementById('new-search');
  const dispatch = useSearchTreeDispatch();
  const navigate = useNavigate();
  const screenWidth = useRecoilValue(ScreenWidthAtom);
  const searchState = useCompleteSearchObject(NEW_SEARCH_KEY);
  const [open, setOpen, setClosed] = useToggle(false);

  const handleResumeSearch = React.useCallback(() => {
    setClosed();
    navigate('/search/new');
  }, [navigate, setClosed]);

  const handleNewSearchClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event.metaKey) {
        window.open('/search/new');
      } else {
        if (searchState.roots.length === 0) {
          handleResumeSearch();
        } else {
          setOpen();
        }
      }
    },
    [handleResumeSearch, searchState.roots.length, setOpen],
  );

  const handleNewSearch = React.useCallback(() => {
    dispatch(
      searchTreeActions.tree.set({
        key: NEW_SEARCH_KEY,
        branches: {},
        roots: [],
      }),
    );
    handleResumeSearch();
  }, [dispatch, handleResumeSearch]);

  return (
    <div>
      {newSearchNode &&
        ReactDOM.createPortal(
          <Box height={72} display="flex" alignItems="center">
            <SNButton
              snVariant="primary"
              startIcon={screenWidth >= 750 ? <SearchIcon /> : null}
              onClick={handleNewSearchClick}
              rounded
            >
              {screenWidth >= 750 ? 'New Search' : <SearchIcon />}
            </SNButton>
          </Box>,
          newSearchNode,
        )}
      <Dialog open={open} onClose={setClosed}>
        <SNDialogTitle onClose={setClosed}>New Search</SNDialogTitle>
        <DialogContent>
          You currently have a search in progress. Would you like to resume your
          current search or start fresh?
        </DialogContent>
        <DialogActions>
          <SNButton onClick={setClosed}>Cancel</SNButton>
          <SNButton onClick={handleNewSearch}>New Search</SNButton>
          <SNButton onClick={handleResumeSearch} snVariant="primary">
            Resume
          </SNButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewSearchButton;
