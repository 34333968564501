import moment from 'moment';
import { Maybe } from '../types';

export const getSafeDateValue = (
  localValue: Maybe<string>,
  value: string | null,
) => {
  if (localValue === null) return localValue;
  if (typeof localValue === 'string') {
    return moment(localValue).isValid() ? moment(localValue) : null;
  }
  return typeof value === 'string' && moment(value).isValid()
    ? moment(value)
    : null;
};

export const getGlideSafeDateValue = (value: Maybe<string>) => {
  if (typeof value === 'string') {
    return moment(value).isValid() ? moment(value) : undefined;
  }
  return typeof value === 'string' && moment(value).isValid()
    ? moment(value)
    : undefined;
};
