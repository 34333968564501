import React from 'react';
import { Box } from '@mui/system';
import {
  PossibleAddedValidation,
  WorkingAreaValidations,
} from './EntityTypeDetailsFieldValidationsModal';
import SNInput from '../SNInput';
import { OneOfValidation } from '../../types/newEntityTypeTypes';
import { IconButton, Typography } from '@mui/material';
import SNButton from '../SNButton';
import ClearIcon from '@mui/icons-material/Clear';

interface OneOfFieldValidationFormProps {
  setAddedValidation: React.Dispatch<
    React.SetStateAction<WorkingAreaValidations>
  >;
  addedValidation: PossibleAddedValidation;
}

const OneOfFieldValidationForm = ({
  addedValidation,
  setAddedValidation,
}: OneOfFieldValidationFormProps) => {
  const currentValues = React.useMemo(() => {
    return addedValidation.validation as OneOfValidation;
  }, [addedValidation]);
  const [termInput, setTermInput] = React.useState('');

  const handleChange = (e: any) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  const handleAddTerm = () => {
    if (currentValues?.terms?.includes(termInput)) {
      setTermInput('');
      return null;
    } else {
      setAddedValidation((prev) => {
        const previousTerms = currentValues.terms || [];
        return {
          ...prev,
          [addedValidation.id]: {
            ...addedValidation,
            validation: {
              ...currentValues,
              terms: [...previousTerms, termInput],
            },
          },
        };
      });
      setTermInput('');
    }
  };

  const handleTermInputChange = (e: any) => {
    setTermInput(e.target.value);
  };

  const handleDeleteTerm = (term: string) => {
    setAddedValidation((prev) => {
      const indexToDelete = currentValues.terms.indexOf(term);
      const newTerms = currentValues.terms.toSpliced(indexToDelete, 1);
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            terms: [...newTerms],
          },
        },
      };
    });
  };

  return (
    <Box>
      <Box my={2}>
        <Typography color="textSecondary">
          &#39;One of&#39; validations check the value in this column against a
          set list of terms
        </Typography>
      </Box>
      <Box m={1} display="flex" alignItems="center">
        <SNInput
          required
          label="term"
          helperText={
            (currentValues.terms && currentValues.terms.length > 0) ||
            !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleTermInputChange}
          value={termInput}
        />
        <Box
          ml={6}
          mt={
            currentValues.terms.length > 0 || !addedValidation.showInputErrors
              ? 4
              : 1
          }
        >
          <SNButton onClick={handleAddTerm}>Add Term</SNButton>
        </Box>
      </Box>
      {currentValues.terms && currentValues.terms.length > 0 && (
        <Box ml={1}>
          <Typography color="textSecondary">Allowed Terms:</Typography>
        </Box>
      )}
      <Box width="100%" display="flex" flexWrap="wrap">
        {currentValues.terms?.map((term) => {
          return (
            <Box
              bgcolor="grey.900"
              borderRadius={3}
              key={term}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={1}
              px={1}
              minWidth="15%"
            >
              {term}
              <Box mx={1} />
              <IconButton onClick={() => handleDeleteTerm(term)}>
                <ClearIcon />
              </IconButton>
            </Box>
          );
        })}
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Error Message"
          name="message"
          helperText={
            currentValues.message || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.message}
        />
      </Box>
    </Box>
  );
};

export default OneOfFieldValidationForm;
