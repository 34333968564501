import React from 'react';
import { Box } from '@mui/system';
import Checkbox from '../Checkbox';
import { Link, Typography } from '@mui/material';
import SNTableRow from '../SNTableRow';
import styled from '../../services/styled';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import { Link as RouterLink } from 'react-router-dom';
import { DefinitionState } from '../../types/new/graphql';
import UserAvatar from '../UserAvatar';
import { Maybe } from '../../types';

const StyledCell = styled('td')`
  border-bottom-color: ${({ theme }) => theme.palette.grey[600]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

interface EntityTypesTableRowProps {
  id: string;
  index: number;
  name: string;
  totalCount: number;
  state: DefinitionState;
  description: Maybe<string>;
  updatedAt: string;
  createdBy: {
    name?: Maybe<string>;
    picture?: Maybe<string>;
  };
}

const EntityTypesTableRow = ({
  id,
  index,
  name,
  totalCount,
  state,
  description,
  createdBy,
  updatedAt,
}: EntityTypesTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleChange = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow key={id} selected={selected}>
      <StyledCell>
        <Box pl={4} pr={3}>
          <Checkbox
            id={`row-select-${id}`}
            onChange={handleChange}
            checked={selected}
          />
        </Box>
      </StyledCell>
      <StyledCell>
        <Link
          to={`/settings/entity-type/${id}/data-types`}
          component={RouterLink}
        >
          <Typography>{name}</Typography>
        </Link>
      </StyledCell>
      <StyledCell>
        <Link
          to={`/settings/entity-type/${id}/data-types`}
          component={RouterLink}
        >
          <Typography>{description}</Typography>
        </Link>
      </StyledCell>
      <StyledCell>
        <Link
          to={`/settings/entity-type/${id}/data-types`}
          component={RouterLink}
        >
          <UserAvatar
            picture={createdBy.picture || ''}
            name={createdBy.name || ''}
          />
        </Link>
      </StyledCell>
      <StyledCell>
        <Link
          to={`/settings/entity-type/${id}/data-types`}
          component={RouterLink}
        >
          <Typography>{updatedAt}</Typography>
        </Link>
      </StyledCell>
      <StyledCell>
        <Link
          to={`/settings/entity-type/${id}/data-types`}
          component={RouterLink}
        >
          <Typography>{state}</Typography>
        </Link>
      </StyledCell>
      <StyledCell>
        <Link
          to={`/settings/entity-type/${id}/data-types`}
          component={RouterLink}
        >
          <Typography>{totalCount}</Typography>
        </Link>
      </StyledCell>
    </SNTableRow>
  );
};

export default EntityTypesTableRow;
