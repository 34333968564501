import DataEditor, {
  GridCellKind,
  SizedGridColumn,
  getMiddleCenterBias,
  DataEditorProps,
  useCustomCells,
  Item,
} from '@glideapps/glide-data-grid';
import React from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { entityTableRows } from '../../atoms/entityTableRows';
import { entityTableColumns } from '../../atoms/entityTableColumns';
import { useNavigate } from 'react-router-dom';
import NoResultsMessage from '../NoResultsMessage';
import {
  EntityTypes_entityTypes_fields,
  SearchFilterValueType,
} from '../../types/schemaTypes';
import moment from 'moment';
import {
  getGlideSafeDateValue,
  getSafeDateValue,
} from '../../utils/getSafeDateValue';
import { useEditEntitiesDispatch, useEditEntitiesState } from '../../contexts';
import produce from 'immer';
import { getValueFromCell } from './CustomCells';
import LocalSelectCell, { LocalSelectCellType } from './LocalSelectCell';
import DateTimeCell, { DateTimeCellType } from './DateTimeCell';
import AnnotatedTextCell, { AnnotatedTextCellType } from './AnnotatedTextCell';
import MetadataCell, { MetadataCellType } from './MetadataCell';
import {
  EMPTY_GRID_SELECTION,
  glideTableSelection,
} from '../../atoms/glideTableSelection';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';
import { GlideHeaderInfo, PaletteStatus, UserMetadata } from '../../types';
import { IBounds, useLayer } from 'react-laag';
import LinkSummaryCell, { LinkSummaryCellType } from './LinkSummaryCell';
import { getThemeStatusFromServerStatus } from '../../utils';
import AnnotationTooltip from './AnnotationTooltip';
import MetadataTooltip from './MetadataTooltip';
import GeneralTooltip from './GeneralTooltip';
import headerIcons from './HeaderIcons';
import '@glideapps/glide-data-grid/dist/index.css';
import { dataGridTheme } from '../../services/dataGridTheme';
import { isInRange } from '../../utils/isInRange';
import useGrabScrolling from '../../hooks/useGrabScrolling';
import { userMetadataModalOpenAtom } from '../../atoms/userMetadataModalOpenAtom';

const zeroBounds: IBounds = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  bottom: 0,
  right: 0,
};

interface EntitiesTableProps {
  currentTypeId: string;
  page: number;
  rowCount: number | undefined;
  tableId: string;
}

interface ColumnWidths {
  [key: string]: number;
}

const emptyAnnotationMessages: string[] = [];
const emptyMetadata: UserMetadata[] = [];

interface GeneralTooltip {
  message: string;
  bounds: IBounds;
}

interface MetadataTooltip {
  metadata: UserMetadata[];
  bounds: IBounds;
}

interface AnnotationTooltip {
  status: PaletteStatus | undefined;
  messages: string[];
  bounds: IBounds;
}

const isAnnotationTooltip = (
  response: unknown,
): response is AnnotationTooltip =>
  Object.prototype.hasOwnProperty.call(response, 'messages');

const isMetadataTooltip = (response: unknown): response is MetadataTooltip =>
  Object.prototype.hasOwnProperty.call(response, 'metadata');

const EntitiesTable = ({
  currentTypeId,
  page,
  rowCount,
  tableId,
}: EntitiesTableProps) => {
  const isEditing = useRecoilValue(isEditingEntitiesAtom);
  const navigate = useNavigate();
  const props = useCustomCells([
    AnnotatedTextCell,
    DateTimeCell,
    LinkSummaryCell,
    LocalSelectCell,
    MetadataCell,
  ]);
  const [tooltip, setTooltip] = React.useState<
    AnnotationTooltip | MetadataTooltip | GeneralTooltip | undefined
  >();
  const [gridSelection, setGridSelection] = useRecoilState(
    glideTableSelection(tableId),
  );
  const resetGridSelection = useResetRecoilState(glideTableSelection(tableId));

  React.useEffect(() => {
    resetGridSelection();
  }, [currentTypeId, resetGridSelection]);

  React.useEffect(() => {
    setGridSelection((prev) => {
      return prev[page] === undefined
        ? { ...prev, [page]: EMPTY_GRID_SELECTION }
        : prev;
    });
  }, [page, setGridSelection]);

  const handleGridSelectionChange = React.useCallback<
    Required<DataEditorProps>['onGridSelectionChange']
  >(
    (newGridSelection) => {
      setGridSelection((prev) => ({ ...prev, [page]: newGridSelection }));
    },
    [page, setGridSelection],
  );

  const editState = useEditEntitiesState();
  const setEditState = useEditEntitiesDispatch();
  const setUserMetadataModalOpen = useSetRecoilState(userMetadataModalOpenAtom);

  const columnsRef = React.useRef<GlideHeaderInfo[]>([]);
  columnsRef.current = useRecoilValue(entityTableColumns(currentTypeId));

  const rowData = useRecoilValue(entityTableRows(tableId));

  const [columnWidths, setColumnWidths] = React.useState<ColumnWidths>({
    ['system.displayName']: 300,
  });

  const dataGridColumns: SizedGridColumn[] = React.useMemo(
    () =>
      columnsRef.current.map<SizedGridColumn>((column) => ({
        ...column,
        width: columnWidths[column.id] || 200,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnWidths, currentTypeId],
  );

  const handleColumnResize = React.useCallback<
    Required<DataEditorProps>['onColumnResize']
  >((column, newSize) => {
    setColumnWidths((prev) => {
      return column.id ? { ...prev, [column.id]: newSize } : prev;
    });
  }, []);

  const getDisplayStatus = React.useCallback(
    ([columnIndex, rowIndex]: Item) => {
      const { id: columnId } = columnsRef.current[columnIndex];
      const isRowHeading = columnId === 'system.displayName';
      const row = rowData[rowIndex];
      if (row && row[columnId]) {
        const validations = editState.validations[row.id.value];
        if (validations) {
          if (isRowHeading) {
            return validations.displayStatus;
          }
          if (validations.fieldValidations[columnId]) {
            return validations.fieldValidations[columnId].displayStatus;
          }
        }
        return row[columnId].displayStatus || null;
      }
      return null;
    },
    [editState.validations, rowData],
  );

  const getAnnotationMessages = React.useCallback(
    ([columnIndex, rowIndex]: Item) => {
      const column = columnsRef.current[columnIndex];
      let annotationMessages = emptyAnnotationMessages;
      if (column) {
        const columnId = column.id;
        const isRowHeading = columnId === 'system.displayName';
        const row = rowData[rowIndex];
        if (row && row[columnId]) {
          const validations = editState.validations[row.id.value];
          if (validations) {
            if (isRowHeading) {
              annotationMessages = validations.generalAnnotations.map(
                ({ message }) => message,
              );
            } else if (validations.fieldValidations[columnId]) {
              annotationMessages = validations.fieldValidations[
                columnId
              ].annotations.map(({ message }) => message);
            }
          } else {
            annotationMessages = (row[columnId].annotations || []).map(
              ({ message }) => message,
            );
          }
        }
      }
      return annotationMessages;
    },
    [editState.validations, rowData],
  );

  const getMetadata = React.useCallback(
    ([columnIndex, rowIndex]: Item) => {
      const column = columnsRef.current[columnIndex];
      if (column) {
        const columnId = column.id;
        const row = rowData[rowIndex];
        if (row && row[columnId]) {
          return row[columnId].additionalData || emptyMetadata;
        }
      }
      return emptyMetadata;
    },
    [rowData],
  );

  const getValue = React.useCallback(
    ([columnIndex, rowIndex]: Item) => {
      let value = '';
      const { id: columnId, field } = columnsRef.current[columnIndex];
      const row = rowData[rowIndex];
      if (row) {
        const edits = editState.edits[row.id.value];
        if (edits && edits[columnId]) {
          return edits[columnId];
        }
        value = rowData[rowIndex][columnId]?.value || '';
        if (value && field.type === SearchFilterValueType.DATE) {
          value = moment(getSafeDateValue(value, null)).format('YYYY-MM-DD');
        }
      }
      return value;
    },
    [editState.edits, rowData],
  );

  const getField = React.useCallback(
    ([columnIndex]: Item): Pick<
      EntityTypes_entityTypes_fields,
      'type' | 'inputOptions'
    > => {
      return columnsRef.current[columnIndex]
        ? columnsRef.current[columnIndex].field
        : {
            type: SearchFilterValueType.STRING,
            inputOptions: {
              __typename: 'Autogenerated',
              displayOnEntry: true,
            },
          };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTypeId],
  );

  const getColumnId = React.useCallback(
    ([columnIndex]: Item) => {
      return (
        columnsRef.current[columnIndex] && columnsRef.current[columnIndex].id
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTypeId],
  );

  const getColumnType = React.useCallback(
    ([columnIndex]: Item) => {
      return (
        columnsRef.current[columnIndex] && columnsRef.current[columnIndex].type
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTypeId],
  );

  const getEntityId = React.useCallback(
    ([, rowIndex]: Item) => {
      return rowData[rowIndex] && rowData[rowIndex].id.value;
    },
    [rowData],
  );

  const viewLinkSummary = React.useCallback(
    (entityId: string) => {
      navigate(`/search/entity/${entityId}/links`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate],
  );

  /**
   * In order to gain manual control over table re-rendering this
   * method needs to have 0 dependencies or at least 0 dependencies
   * that update. For example, getEntityId() currently depends on rowData.
   * In order to remove rowData as a dependency rowData needs to be changed
   * into a ref like columnsRef. This isn't something that needs to be done
   * unless performance is becoming an issue.
   */
  const getCellContent = React.useCallback<
    Required<DataEditorProps>['getCellContent']
  >(
    (cell) => {
      /**
       * Regarding the notes above, this list would need to be reviewed
       * and all of the methods below would need to be updated to only
       * depend upon stable references, e.g. the ref from React.useRef()
       * is stable and doesn't change even if the value of ref.current
       * does update. Pure functions are also stable references.
       */
      const columnId = getColumnId(cell);
      const field = getField(cell);
      const value = getValue(cell);
      const displayStatus = getDisplayStatus(cell);
      const type = getColumnType(cell);
      const entityId = getEntityId(cell);

      if (columnId === 'system.displayName') {
        const cell: AnnotatedTextCellType = {
          kind: GridCellKind.Custom,
          copyData: value,
          allowOverlay: false,
          readonly: isEditing,
          themeOverride: {
            baseFontStyle: '600 0.859375rem',
          },
          data: {
            kind: 'annotated-text-cell',
            displayStatus,
            displayValue: value,
          },
        };
        return cell;
      }

      if (columnId === 'user.metadata') {
        const cell: MetadataCellType = {
          kind: GridCellKind.Custom,
          copyData: '',
          allowOverlay: true,
          data: {
            kind: 'metadata-cell',
            displayValue: value,
            entityId,
          },
        };
        return cell;
      }

      if (type === 'linkSummary') {
        const cell: LinkSummaryCellType = {
          kind: GridCellKind.Custom,
          allowOverlay: !!value,
          copyData: value,
          data: {
            kind: 'link-summary-cell',
            displayStatus,
            displayValue: value,
            entityId,
            onClick: viewLinkSummary,
          },
        };
        return cell;
      }

      switch (field.inputOptions.__typename) {
        case 'LocalSelectInputOptions': {
          const cell: LocalSelectCellType = {
            kind: GridCellKind.Custom,
            copyData: value,
            allowOverlay: isEditing,
            data: {
              kind: 'local-select-cell',
              value,
              options: field.inputOptions.options,
              displayStatus,
            },
          };
          return cell;
        }
        case 'DateTimeInputOptions': {
          const cell: DateTimeCellType = {
            kind: GridCellKind.Custom,
            copyData: value,
            allowOverlay: isEditing,
            data: {
              kind: 'date-time-cell',
              displayDate: value,
              date: getGlideSafeDateValue(value),
              displayStatus,
            },
          };
          return cell;
        }
        case 'NumericInputOptions': {
          const cell: AnnotatedTextCellType = {
            kind: GridCellKind.Custom,
            copyData: value,
            allowOverlay: isEditing,
            data: {
              kind: 'annotated-text-cell',
              displayStatus,
              displayValue: value,
              type: 'number',
            },
          };
          return cell;
        }
        case 'TextInputOptions': {
          const cell: AnnotatedTextCellType = {
            kind: GridCellKind.Custom,
            copyData: value,
            allowOverlay: isEditing,
            data: {
              kind: 'annotated-text-cell',
              displayStatus,
              displayValue: value,
            },
          };
          return cell;
        }
        case 'Autogenerated':
        default: {
          const cell: AnnotatedTextCellType = {
            kind: GridCellKind.Custom,
            copyData: value,
            allowOverlay: false,
            readonly: isEditing,
            data: {
              kind: 'annotated-text-cell',
              displayStatus,
              displayValue: value,
            },
          };
          return cell;
        }
      }
    },
    [
      getColumnId,
      getColumnType,
      getDisplayStatus,
      getEntityId,
      getField,
      getValue,
      isEditing,
      viewLinkSummary,
    ],
  );

  const handleCellClicked = React.useCallback<
    Required<DataEditorProps>['onCellClicked']
  >(
    ([column, row], event) => {
      const entityId = rowData[row].id.value;
      if (column === 0 && rowData) {
        event.preventDefault();
        navigate(`/search/entity/${entityId}`);
      }
      if (columnsRef.current[column].type === 'metadata' && !isEditing) {
        event.preventDefault();
        setUserMetadataModalOpen({ entityId: entityId, open: true });
      }
      if (columnsRef.current[column].type === 'linkSummary' && !isEditing) {
        event.preventDefault();
        navigate(`/search/entity/${entityId}/links`);
      }
    },
    [setUserMetadataModalOpen, isEditing, navigate, rowData],
  );

  const handleItemHovered = React.useCallback<
    Required<DataEditorProps>['onItemHovered']
  >(
    (args) => {
      const {
        kind,
        location: [column, row],
      } = args;
      if (column === 0 && row > -1) {
        document
          .getElementsByClassName('dvn-scroller')[0]
          .classList.add('pointer');
      } else if (
        !isEditing &&
        columnsRef.current[column] &&
        columnsRef.current[column]?.type &&
        (columnsRef.current[column].type === 'linkSummary' ||
          columnsRef.current[column].type === 'metadata')
      ) {
        document
          .getElementsByClassName('dvn-scroller')[0]
          .classList.add('pointer');
      } else {
        document
          .getElementsByClassName('dvn-scroller')[0]
          .classList.remove('pointer');
      }

      if (kind === 'cell' && column !== -1) {
        const messages = getAnnotationMessages([column, row]);
        const displayStatus = getDisplayStatus([column, row]);
        const metadata = getMetadata([column, row]);
        const status = getThemeStatusFromServerStatus(displayStatus);
        const bounds = {
          // translate to react-laag types
          left: args.bounds.x,
          top: args.bounds.y,
          width: args.bounds.width,
          height: args.bounds.height,
          right: args.bounds.x + args.bounds.width,
          bottom: args.bounds.y + args.bounds.height,
        };

        if (
          gridSelection[page] &&
          isInRange([column, row], gridSelection[page])
        ) {
          setTooltip({
            message: 'Ctrl+C to copy selection',
            bounds,
          });
        } else if (messages.length > 0) {
          setTooltip({
            status,
            messages,
            bounds,
          });
        } else if (metadata.length > 0) {
          setTooltip({
            metadata,
            bounds,
          });
        } else {
          setTooltip(undefined);
        }
      } else {
        setTooltip(undefined);
      }
    },
    [
      isEditing,
      page,
      getAnnotationMessages,
      getDisplayStatus,
      getMetadata,
      gridSelection,
    ],
  );

  const drawHeader = React.useCallback<Required<DataEditorProps>['drawHeader']>(
    (args) => {
      const { column, ctx, rect, theme, spriteManager, isSelected } = args;
      const { x, y, height } = rect;
      const xPad = 16;
      const desiredIconSize = 24;
      const fillStyle = args.isSelected
        ? args.theme.textHeaderSelected
        : args.theme.textHeader;

      const drawX = x + xPad;
      ctx.fillStyle = fillStyle;

      if (column.icon) {
        spriteManager.drawSprite(
          column.icon,
          isSelected ? 'selected' : 'normal',
          ctx,
          drawX,
          y + height / 2 - desiredIconSize / 2,
          desiredIconSize,
          theme,
        );
      }

      // draw title header
      ctx.fillText(
        column.title.toUpperCase(),
        drawX + (column.icon ? 36 : 0),
        y +
          height / 2 +
          getMiddleCenterBias(
            ctx,
            `${theme.headerFontStyle} ${theme.fontFamily}`,
          ),
      );

      return true;
    },
    [],
  );

  const handleCellEdited = React.useCallback<
    Required<DataEditorProps>['onCellEdited']
  >(
    ([columnIndex, rowIndex], cell) => {
      const { id: fieldDefinitionId } = columnsRef.current[columnIndex];
      const row = rowData[rowIndex];
      const newValue = getValueFromCell(cell);
      setEditState((prevState) =>
        produce(prevState, (draft) => {
          draft.edits[row.id.value] = {
            ...draft.edits[row.id.value],
            [fieldDefinitionId]: newValue,
          };
          delete draft.validations[row.id.value]?.fieldValidations[
            fieldDefinitionId
          ];
        }),
      );
    },
    [rowData, setEditState],
  );

  const isOpen = tooltip !== undefined;
  const { renderLayer, layerProps } = useLayer({
    isOpen,
    triggerOffset: 4,
    auto: true,
    container: 'portal',
    placement: 'top-end',
    trigger: {
      getBounds: () => tooltip?.bounds ?? zeroBounds,
    },
  });

  useGrabScrolling(document.getElementsByClassName('dvn-scroller')[0]);

  if (typeof rowCount !== 'number' || dataGridColumns.length === 0) {
    return null;
  }

  if (rowCount === 0) {
    return <NoResultsMessage />;
  }

  return (
    <>
      <a id="entities-table-link">
        <DataEditor
          {...props}
          theme={dataGridTheme}
          getCellsForSelection
          data-testid="search-results-list"
          showSearch={false}
          gridSelection={gridSelection[page]}
          onGridSelectionChange={handleGridSelectionChange}
          columns={dataGridColumns}
          getCellContent={getCellContent}
          onColumnResize={handleColumnResize}
          onCellClicked={handleCellClicked}
          onCellEdited={handleCellEdited}
          overscrollX={100}
          rows={rowCount}
          rowHeight={56}
          headerHeight={42}
          rowMarkers="checkbox"
          rowMarkerWidth={48}
          rowSelectionMode="multi"
          freezeColumns={1}
          smoothScrollX
          smoothScrollY
          drawHeader={drawHeader}
          headerIcons={headerIcons}
          onItemHovered={handleItemHovered}
          width="100%"
          maxColumnWidth={900}
        />
      </a>
      {isOpen &&
        renderLayer(
          isAnnotationTooltip(tooltip) ? (
            <AnnotationTooltip
              messages={tooltip.messages}
              themeColor={tooltip.status}
              themeVariant="light"
              {...layerProps}
            />
          ) : isMetadataTooltip(tooltip) ? (
            <MetadataTooltip metadata={tooltip.metadata} {...layerProps} />
          ) : (
            <GeneralTooltip message={tooltip.message} {...layerProps} />
          ),
        )}
    </>
  );
};

export default EntitiesTable;
