import React from 'react';
import { useSearchTargetsQuery } from '../../queries';
import { useSearchFormState } from '../../contexts';
import Autocomplete from '../Autocomplete';
import { Box, CircularProgress, Fade } from '@mui/material';
import {
  SearchTargets_searchTargets,
  SearchTargetType,
  SearchTreeTarget,
} from '../../types/schemaTypes';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import SNInput from '../SNInput';
import { entityTypes } from '../../constants';

interface SearchFormTargetSelectProps {
  isRoot: boolean;
  updateTarget: (target: SearchTreeTarget) => void;
}

const emptyResults: SearchTargets_searchTargets[] = [];

const SearchFormTargetSelect: React.FC<SearchFormTargetSelectProps> = ({
  isRoot,
  updateTarget,
}) => {
  const [defaultRelationship, setDefaultRelationship] =
    React.useState<null | SearchTargets_searchTargets>(null);
  const { data, loading } = useSearchTargetsQuery({
    onCompleted: (res) => {
      setDefaultRelationship(
        res.searchTargets.find((searchTarget) => {
          return (
            searchTarget.searchTargetType === SearchTargetType.RELATIONSHIP_TYPE
          );
        }) || null,
      );
    },
  });
  const searchTreeBranch = useSearchFormState();
  const selectedTargetType = searchTreeBranch.target.type;

  const allowedSearchTargets = React.useMemo(() => {
    return data
      ? data.searchTargets.filter(
          (searchTarget) =>
            searchTarget.searchTargetType === selectedTargetType,
        )
      : emptyResults;
  }, [data, selectedTargetType]);

  function handleChange(
    _event: React.ChangeEvent<unknown>,
    value: SearchTargets_searchTargets | null,
  ) {
    if (value && searchTreeBranch.target.id !== value.id) {
      updateTarget({
        id: value.id,
        type: value.searchTargetType,
      });
    }
  }

  const options = allowedSearchTargets
    .slice()
    .sort(
      (a, b) =>
        (a.searchTargetType === SearchTargetType.RELATIONSHIP_TYPE ? 1 : 0) -
        (b.searchTargetType === SearchTargetType.RELATIONSHIP_TYPE ? 1 : 0),
    );

  const value = React.useMemo(() => {
    return options.find(
      (searchTarget) => searchTarget.id === searchTreeBranch.target.id,
    );
  }, [options, searchTreeBranch.target.id]);

  const handleSearchTargetTypeChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: SearchTargetType | null,
  ) => {
    if (value !== null && defaultRelationship !== null) {
      updateTarget({
        id:
          value === SearchTargetType.RELATIONSHIP_TYPE
            ? defaultRelationship.id
            : entityTypes.any,
        type: value,
      });
    }
  };

  return (
    <Box>
      {!isRoot && (
        <Box py={1}>
          <ToggleButtonGroup
            value={selectedTargetType}
            exclusive
            color="primary"
            size="small"
            onChange={handleSearchTargetTypeChange}
          >
            <ToggleButton disableRipple value={SearchTargetType.ENTITY_TYPE}>
              Entity Type
            </ToggleButton>
            <ToggleButton
              disableRipple
              value={SearchTargetType.RELATIONSHIP_TYPE}
            >
              Relationship Type
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      <Box py={1}>
        {value ? (
          <Autocomplete
            defaultValue={options[0]}
            options={options}
            onChange={handleChange}
            disableClearable
            freeSolo={false}
            multiple={false}
            value={value}
            renderInput={({ InputProps, InputLabelProps, ...params }) => (
              <SNInput {...params} {...InputProps} className="" />
            )}
            groupBy={(option) =>
              option.searchTargetType === SearchTargetType.RELATIONSHIP_TYPE
                ? 'Relationship'
                : 'Entity'
            }
            getOptionLabel={(option) => option.name}
            data-testid="search-target-select"
          />
        ) : (
          <SNInput readOnly fullWidth />
        )}
      </Box>
      {loading && (
        <Fade in={loading}>
          <Box
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            data-testid="search-target-options-loading"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(0, 0, 0, 0.3)"
            zIndex={1}
          >
            <Box>
              <CircularProgress size={30} />
            </Box>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default SearchFormTargetSelect;
