import React from 'react';
import { Box } from '@mui/system';
import ListSelectionControl from '../ListSelectionControl';
import SNPagination from '../SNPagination';
import SelectableListToolbar from '../SelectableListToolbar';
import ToolbarActionGroup from '../ToolbarActionGroup';
import { Dialog, Grid } from '@mui/material';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import ListSearchControl from '../ListSearchControl';
import { useDebounce } from '../../hooks';
import SNTable from '../SNTable';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import DataPointsTableRow from '../DataPointsTableRow';
import { useGetDataPointsListQuery } from '../../queries/useGetDataPointsListQuery';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import { GenericItemModalOpen } from '../EntityTypeDetailFieldsTable/EntityTypeDetailsFieldsTable';
import cuid from 'cuid';
import DataPointCreateEditModal from '../DataPointCreateEditModal';

const DataPointsTableHeaders = ['Name', 'Description', 'Data Type', 'Id'];

const DataPointsTable = () => {
  const pageSize = DEFAULT_PAGE_SIZE;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);

  const [addEditOpen, setAddEditOpen] = React.useState<GenericItemModalOpen>({
    open: false,
    columnId: undefined,
  });

  const {
    data: dataPointsData,
    loading,
    fetchMore,
  } = useGetDataPointsListQuery({
    variables: {
      first: pageSize,
      filter: {
        query: debouncedSearchTerm,
      },
    },
    onCompleted: (res) => {
      const pageIds = res.dataPointsList.data?.edges.map((edge) => {
        return edge.node.id;
      });
      setPageIds(pageIds || []);
    },
  });
  const dataPoints = React.useMemo(() => {
    return dataPointsData?.dataPointsList.data?.edges.map((edge) => {
      return edge.node;
    });
  }, [dataPointsData]);

  const handleAddEditDataPoint = () => {
    setAddEditOpen({
      open: true,
      columnId: selectedIds.length === 0 ? cuid() : selectedIds[0],
    });
    resetSelectedIds();
  };

  const handleModalClose = () => {
    setAddEditOpen({ open: false, columnId: undefined });
  };

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...dataPointsData?.dataPointsList.data?.pageInfo}
            loading={loading}
            fetchMore={fetchMore}
            pageSize={pageSize}
            pageTotal={dataPoints?.length || 0}
            totalCount={dataPointsData?.dataPointsList.data?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <SNButton
                  startIcon={<AddIcon />}
                  snVariant="text"
                  onClick={handleAddEditDataPoint}
                >
                  New Data Point
                </SNButton>
                <Dialog open={addEditOpen.open} fullWidth>
                  <DataPointCreateEditModal
                    onClose={handleModalClose}
                    dataPoints={dataPoints}
                    pointId={addEditOpen.columnId || ''}
                  />
                </Dialog>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <SNButton
                  startIcon={<AddIcon />}
                  snVariant="text"
                  onClick={handleAddEditDataPoint}
                >
                  Edit Data Point
                </SNButton>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        headers={DataPointsTableHeaders}
        hasResults={!!dataPoints}
        id="data-point-table"
        loading={false}
        rowCount={dataPointsData?.dataPointsList.data?.totalCount || 0}
        selectable
      >
        {dataPoints?.map((point, index) => {
          return (
            <DataPointsTableRow
              key={point.id}
              description={point.description}
              id={point.id}
              name={point.name}
              index={index}
              dataType={point.dataType}
            />
          );
        })}
      </SNTable>
    </Box>
  );
};

export default DataPointsTable;
