import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { pageIdsAtom, selectedIdsAtom } from '../../atoms/listSelection';

import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import SNPagination from '../SNPagination';
import SNTable from '../SNTable';
import { useGroupsOfProjectQuery } from '../../queries/useGroupsOfProjectQuery';
import AddGroupsToProjectForm from '../AddGroupsToProjectForm';
import ToolbarActionGroup from '../ToolbarActionGroup';
import { Delete } from '@mui/icons-material';
import { useRevokeGroupProjectAccess } from '../../mutations/useRevokeGroupProjectAccess';
import ListSearchControl from '../ListSearchControl';
import GroupsTableRow from '../GroupsTableRow';
import { useToggle } from '../../hooks/useToggle';
import OwnerOfProjectMask from '../OwnerOfProjectMask/OwnerOfProjectMask';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useDebounce } from '../../hooks';

const headers = ['name', 'description', 'users', 'projects', 'date added'];

const ProjectGroupsTable = () => {
  const pageSize = 10;
  const { id } = useParams<{ id: string }>();
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [addGroupsModalOpen, setAddGroupsModalOpen, setAddGroupsModalClosed] =
    useToggle(false);
  const [revokeGroupAccess, { data: revokeData }] = useRevokeGroupProjectAccess(
    {
      refetchQueries: ['GroupsOfProject'],
      onCompleted: () => {
        setSelectedIds([]);
      },
    },
  );

  const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } = useGroupsOfProjectQuery({
    variables: {
      id: id ? id : '',
      filter: { searchTerm: debouncedSearchTerm },
    },
    onCompleted: (result) => {
      setPageIds(
        result?.project?.project?.groupProjectAccess?.edges.map(
          (access) => access.node.id,
        ) || [],
      );
    },
  });
  React.useEffect(() => {
    refetch({
      id: id ? id : '',
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [data, refetch, id, debouncedSearchTerm]);

  const handleRemoveGroupAccess = () => {
    revokeGroupAccess({
      variables: {
        input: {
          id: selectedIds[0],
        },
      },
    });
  };

  const projectAccesses = React.useMemo(
    () =>
      data?.project?.project?.groupProjectAccess?.edges.map(
        (edge) => edge.node,
      ),
    [data],
  );
  const pageTotal = projectAccesses?.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      data?.project?.errors,
      revokeData?.revokeGroupProjectAccess?.errors,
    ]);
  }, [data, revokeData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: "Error fetching project's groups",
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  if (!id) {
    return null;
  }

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.project?.project?.groupProjectAccess?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.project?.project?.groupProjectAccess?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <Dialog
                  fullWidth
                  open={addGroupsModalOpen}
                  onClose={setAddGroupsModalClosed}
                >
                  <AddGroupsToProjectForm onClose={setAddGroupsModalClosed} />
                </Dialog>
                <OwnerOfProjectMask projectId={id}>
                  <SNButton
                    startIcon={<AddIcon />}
                    onClick={setAddGroupsModalOpen}
                    snVariant="text"
                  >
                    Add Groups
                  </SNButton>
                </OwnerOfProjectMask>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <OwnerOfProjectMask projectId={id}>
                  <SNButton
                    onClick={handleRemoveGroupAccess}
                    snVariant="destructive"
                    startIcon={<Delete />}
                  >
                    Remove Group Access
                  </SNButton>
                </OwnerOfProjectMask>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!data?.project?.project?.groupProjectAccess?.edges}
        headers={headers}
        id="project-groups"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {projectAccesses?.map(
          ({ id: projectAccessId, group, ...projectAccess }, index) => (
            <GroupsTableRow
              key={projectAccessId}
              id={group?.id}
              index={index}
              groupMembership={group?.groupMembership?.edges}
              totalGroupMembershipCount={
                group?.groupMembership?.totalCount || 0
              }
              totalGroupProjectAccessCount={
                group?.groupProjectAccess?.totalCount || 0
              }
              name={group?.name}
              description={group?.description}
              {...projectAccess}
            />
          ),
        )}
      </SNTable>
    </Box>
  );
};

export default ProjectGroupsTable;
