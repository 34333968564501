import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
} from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import styled from '../../services/styled';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FieldDefinition } from '../../types/newEntityTypeTypes';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import SNTableRow from '../SNTableRow';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import * as changeCase from 'change-case';
import { useDebounce } from '../../hooks';
import { DataPoints, DataPoints_dataPoints } from '../../types/schemaTypes';
import Autocomplete from '../Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { GenericItemModalOpen } from '../EntityTypeDetailFieldsTable/EntityTypeDetailsFieldsTable';

const StyledCell = styled('td')`
  border-bottom-color: ${({ theme }) => theme.palette.grey[600]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

interface EntityTypeDetailTableRowProps {
  setInputOptionsOpenId: Dispatch<SetStateAction<GenericItemModalOpen>>;
  setValidationsModalOpenId: Dispatch<SetStateAction<GenericItemModalOpen>>;
  field: FieldDefinition;
  index: number;
  dataPoints: DataPoints | undefined;
  dragRef?: any;
  dragProps?: any;
  dragHandleProps?: any;
}

export const DataTypeMapping = {
  STRING: 'string',
  INT: 'number',
  FLOAT: 'number',
  DATETIME: 'string',
  BOOLEAN: 'boolean',
  URI: 'string',
  URL: 'string',
};

const EntityTypeDetailFieldsTableRow = ({
  setInputOptionsOpenId,
  setValidationsModalOpenId,
  field,
  index,
  dataPoints,
  dragRef,
  dragProps,
  dragHandleProps,
}: EntityTypeDetailTableRowProps) => {
  const { label, id, description } = field;
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));
  const setEntityTypeInfo = useSetRecoilState(newEntityTypeAtom);

  const [localValue, setLocalValue] = React.useState(label || '');
  const debouncedLocalValue = useDebounce(localValue, 1500);

  const dataPointOptions = React.useMemo(() => {
    return dataPoints?.dataPoints || [];
  }, [dataPoints]);

  const [dataPointSearchTerm, setDataPointSearchTerm] = React.useState('');
  const [localDataPointValue, setLocalDataPointValue] = React.useState<
    DataPoints_dataPoints | undefined
  >(
    dataPointOptions.filter((point) => {
      return point.id === field.data_point;
    })[0] || undefined,
  );

  const handleRowSelect = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  React.useEffect(() => {
    const newValue = debouncedLocalValue;
    const typeId =
      id.split('.')[0] === 'new' ? id.split('.')[1] : id.split('.')[0];
    const newId = `${typeId}.${changeCase.snakeCase(newValue)}`;

    setEntityTypeInfo((prev) => {
      const oldFields = { ...prev.fields };
      delete oldFields[id];
      const oldIdIndex = prev.export_details.export_ordering?.indexOf(id) || 0;

      const newOrder =
        prev.export_details?.export_ordering === undefined
          ? []
          : prev.export_details?.export_ordering;
      const fieldsBefore = newOrder.slice(0, oldIdIndex);
      const fieldsAfter = newOrder.slice(oldIdIndex + 1, newOrder.length);
      const orderToWrite = [...fieldsBefore, newId, ...fieldsAfter];

      return {
        ...prev,
        fields: {
          [newId]: {
            ...prev.fields[id],
            id: newId,
            label: newValue,
          },
          ...oldFields,
        },
        export_details: {
          ...prev.export_details,
          export_ordering: [...orderToWrite],
        },
      };
    });
  }, [debouncedLocalValue, id, setEntityTypeInfo]);

  const handleTypeInputChange = (
    _event: ChangeEvent<NonNullable<unknown>>,
    value: string,
  ) => {
    setDataPointSearchTerm(value);
  };
  const handleTypeSelect = (
    _event: SyntheticEvent<Element, Event>,
    value: DataPoints_dataPoints | null,
  ) => {
    let dataPointAndType = {};
    if (value === null) {
      setLocalDataPointValue(undefined);
      dataPointAndType = {
        data_point: '',
        type: '',
      };
    } else {
      setLocalDataPointValue(value);
      dataPointAndType = {
        data_point: value.id,
        type: DataTypeMapping[value.dataType] as
          | 'string'
          | 'boolean'
          | 'number'
          | 'ignored',
      };
    }

    setEntityTypeInfo((prev) => {
      const oldFields = { ...prev.fields };
      delete oldFields[id];
      return {
        ...prev,
        fields: {
          [id]: {
            ...prev.fields[id],
            ...dataPointAndType,
          },
          ...oldFields,
        },
      };
    });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDesc = e.target.value;
    setEntityTypeInfo((prev) => {
      const oldFields = { ...prev.fields };
      delete oldFields[id];
      return {
        ...prev,
        fields: {
          [id]: {
            ...prev.fields[id],
            description: newDesc,
          },
          ...oldFields,
        },
      };
    });
  };

  return (
    <SNTableRow key={id} selected={selected} {...dragProps} ref={dragRef}>
      <StyledCell>
        <Box pl={4} pr={3}>
          <Checkbox
            id={`row-select-${id}`}
            onChange={handleRowSelect}
            checked={selected}
          />
        </Box>
      </StyledCell>
      <StyledCell>
        <Box
          pl={4}
          pr={5}
          {...dragHandleProps}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DragHandleIcon sx={{ marginLeft: -2 }} />
        </Box>
      </StyledCell>
      <StyledCell>
        <SNInput value={localValue} onChange={handleLabelChange} />
      </StyledCell>
      <StyledCell>
        <Autocomplete
          multiple={false}
          freeSolo={false}
          onChange={handleTypeSelect}
          inputValue={dataPointSearchTerm}
          onInputChange={handleTypeInputChange}
          value={localDataPointValue}
          options={dataPointOptions}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              key={option.id}
              display="flex"
              sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}
              overflow="hidden"
            >
              <Box>
                <Box>{option.name}</Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: '.6rem' }}
                    color="textSecondary"
                  >
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          renderInput={({ InputProps, InputLabelProps, ...params }) => (
            <SNInput
              {...InputProps}
              {...params}
              className=""
              placeholder="Search for Data Point"
              startAdornment={
                <Box display="flex" ml={1}>
                  <SearchIcon />
                </Box>
              }
            />
          )}
        />
      </StyledCell>
      <StyledCell>
        <SNInput value={description} onChange={handleDescriptionChange} />
      </StyledCell>
      <StyledCell>
        <SNButton
          disabled={!localDataPointValue}
          onClick={() =>
            setValidationsModalOpenId({ columnId: id, open: true })
          }
        >
          Add Column Validations
        </SNButton>
      </StyledCell>
      <StyledCell>
        <SNButton
          disabled={!localDataPointValue}
          onClick={() => setInputOptionsOpenId({ columnId: id, open: true })}
        >
          Input Options Configuration
        </SNButton>
      </StyledCell>
    </SNTableRow>
  );
};

export default EntityTypeDetailFieldsTableRow;
