import { Fade } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useTabOverflowDispatch } from '../../contexts';
import { useOnScreen } from '../../hooks/useOnScreen';

interface TabOverflowProps extends IntersectionObserverInit {
  tabId: string;
}

const TabOverflow: React.FC<PropsWithChildren<TabOverflowProps>> = ({
  root,
  rootMargin,
  tabId,
  threshold,
  children,
}) => {
  const dispatch = useTabOverflowDispatch();

  const ref = React.useRef<HTMLDivElement>(null);

  const isOnScreen = useOnScreen(
    ref,
    {
      root,
      rootMargin,
      threshold,
    },
    false,
  );

  React.useEffect(() => {
    dispatch((previous) => ({ ...previous, [tabId]: isOnScreen }));
    return () => {
      dispatch((previous) => {
        const { [tabId]: omit, ...rest } = previous;
        return rest;
      });
    };
  }, [dispatch, isOnScreen, tabId]);

  return (
    <div ref={ref}>
      <Fade in={isOnScreen}>
        <div>{children}</div>
      </Fade>
    </div>
  );
};

export default TabOverflow;
