import { RelationshipDefinition } from '../../types/newEntityTypeTypes';
import { Box } from '@mui/system';
import React from 'react';
import SNTableRow from '../SNTableRow';
import { Checkbox } from '@mui/material';
import styled from '../../services/styled';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import { EntityTypesQuery, LinkTypesQuery } from '../../types/new/graphql';

const StyledCell = styled('td')`
  border-bottom-color: ${({ theme }) => theme.palette.grey[600]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

interface EntityTypeDetailsRelationshipTableRowProps {
  relationship: RelationshipDefinition;
  index: number;
  linkTypes: LinkTypesQuery['linkTypes'];
  allEntityTypes: EntityTypesQuery['entityTypes'];
}

const EntityTypeDetailsRelationshipTableRow = ({
  relationship,
  index,
  linkTypes,
  allEntityTypes = [],
}: EntityTypeDetailsRelationshipTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const linkType = linkTypes?.find((type) => {
    return type.id === relationship.link_type;
  });
  const fields = linkType?.fields.map((field) => {
    return field.name;
  });

  const thisRelationshipsAllowedTypes = allEntityTypes?.filter((type) => {
    return relationship.types.includes(type.id);
  });

  const handleRowSelect = React.useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow key={relationship.id} selected={selected}>
      <StyledCell>
        <Box pl={4} pr={3}>
          <Checkbox
            id={`row-select-${relationship.id}`}
            onChange={handleRowSelect}
            checked={selected}
          />
        </Box>
      </StyledCell>
      <StyledCell>{relationship.label}</StyledCell>
      <StyledCell>{linkType?.name}</StyledCell>
      <StyledCell>
        {thisRelationshipsAllowedTypes?.map((type) => {
          return (
            <Box key={type.id} display="flex">
              {type.name}, &nbsp;
            </Box>
          );
        })}
      </StyledCell>
      <StyledCell>{fields}</StyledCell>
    </SNTableRow>
  );
};

export default EntityTypeDetailsRelationshipTableRow;
