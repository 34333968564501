import SNDialogTitle from '../SNDialogTitle';
import {
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import SNButton from '../SNButton';
import { NewEntityTypeImage } from '../SvgIcon';
import SNInput from '../SNInput';
import Theme from '../../services/theme';
import SNTooltip from '../SNTooltip';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';
import { ulid } from 'ulid';
import { useSetRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import { useNavigate } from 'react-router-dom';

interface NewEntityTypeModalProps {
  onClose: () => void;
}

const NewEntityTypeModal = ({ onClose }: NewEntityTypeModalProps) => {
  const navigate = useNavigate();
  const newTypeId = ulid();
  const [newTypeName, setNewTypeName] = React.useState('');
  const [newTypeDescription, setNewTypeDescription] = React.useState('');
  const setNewTypeData = useSetRecoilState(newEntityTypeAtom);

  const handleNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setNewTypeName(e.target.value);
  };
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setNewTypeDescription(e.target.value);
  };

  const handleSubmit = () => {
    setNewTypeData((previous) => {
      return {
        ...previous,
        name: newTypeName,
        description: newTypeDescription,
        id: newTypeId,
      };
    });
    navigate(`/settings/entity-type/${newTypeId}/datatypes`);
    onClose();
  };

  return (
    <>
      <SNDialogTitle onClose={onClose} fullScreen>
        Create Entity Type
      </SNDialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box display="flex" height="100%" width="100%" alignItems="center">
          <Box
            display="flex"
            bgcolor={Theme.palette.background.default}
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <NewEntityTypeImage />
            <Box display="flex" alignItems="center">
              <Typography>
                Drag and drop or browse to upload an example CSV file{' '}
              </Typography>

              <Box pl={2}>
                <SNTooltip title="Coming Soon.">
                  <InfoOutlined color="disabled" />
                </SNTooltip>
              </Box>
            </Box>
          </Box>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <SNInput
              id="name"
              label="New Entity Type Name"
              onChange={handleNameChange}
              required
            />
            <SNInput
              id="description"
              label="Description"
              onChange={handleDescriptionChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <SNButton
          disabled={newTypeName.length === 0}
          snVariant="primary"
          onClick={handleSubmit}
        >
          Create
        </SNButton>
      </DialogActions>
    </>
  );
};

export default NewEntityTypeModal;
