import { useNavigate, useParams } from 'react-router-dom';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useGetGroupByIdQuery } from '../../queries/useGetGroupByIdQuery';
import GroupDetailUsersTable from '../../components/GroupUsersTable';
import GroupDetailProjectsTable from '../../components/GroupProjectsTable';
import DetailTabs from '../../components/DetailTabs';
import React from 'react';
import { collectErrors } from '../../utils/collectErrors';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { Box } from '@mui/material';
import SNEditableLabel from '../../components/SNEditableLabel';
import { useUpdateGroup } from '../../mutations';

const groupDetailTabsArray = ['users', 'projects'];

const GroupDetail = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useGetGroupByIdQuery({
    variables: { id: id ? id : '' },
  });
  const navigate = useNavigate();
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const [updateGroup, { data: updatedData, loading: updatedLoading }] =
    useUpdateGroup();

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      data?.group?.errors,
      updatedData?.updateGroup?.errors,
    ]);
  }, [updatedData, data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching group',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  if (!id) {
    return null;
  }

  const handleOnBack = () => {
    navigate('/settings/groups');
  };

  const handleNameSave = (value: string) => {
    updateGroup({
      variables: { input: { id: id, name: value } },
      fetchPolicy: 'network-only',
      refetchQueries: ['Group'],
    });
  };
  const handleDescriptionSave = (value: string) => {
    updateGroup({
      variables: { input: { id: id, description: value } },
      fetchPolicy: 'network-only',
      refetchQueries: ['Group'],
    });
  };

  const DetailTitle =
    loading || updatedLoading ? null : (
      <Box>
        <SNEditableLabel
          value={data?.group?.group?.name || 'Group Not Found'}
          onSave={handleNameSave}
          name="name"
          subtitle={false}
        />
        <SNEditableLabel
          value={data?.group?.group?.description || ''}
          onSave={handleDescriptionSave}
          name="description"
          subtitle
        />
      </Box>
    );

  return (
    <DetailTabs
      baseRoute="settings/groups"
      id={id}
      loading={loading}
      tabs={groupDetailTabsArray}
      title={DetailTitle}
      onBack={handleOnBack}
    >
      <Routes>
        <Route path="users" element={<GroupDetailUsersTable />} />
        <Route path="projects" element={<GroupDetailProjectsTable />} />
        <Route path="*" element={<Navigate to="users" />} />
      </Routes>
    </DetailTabs>
  );
};

export default GroupDetail;
