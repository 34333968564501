import React, { PropsWithChildren, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import SNButton from '../SNButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import SNTabs from '../SNTabs';
import { Skeleton } from '@mui/material';
import * as changeCase from 'change-case';

interface DetailTabsProps extends PropsWithChildren {
  actionLabel?: string;
  baseRoute: string;
  id: string;
  loading: boolean;
  onBack?: () => void;
  tabs: string[];
  title: string | ReactNode;
}

const DetailTabs: React.FC<DetailTabsProps> = ({
  actionLabel,
  baseRoute,
  id,
  loading,
  onBack,
  tabs,
  title,
  children,
}) => {
  const { tab = '' } = useParams<{ tab?: string }>();
  const navigate = useNavigate();

  const handleBackClick = React.useCallback(() => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  }, [navigate, onBack]);

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      navigate(`/${baseRoute}/${id}/${changeCase.kebabCase(tab)}`, {
        replace: true,
      });
    },
    [baseRoute, id, navigate],
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box mt={1} px={4} width="100%">
          <Box my={1} ml={-1}>
            <SNButton
              snVariant="underline"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon fontSize="small" />}
            >
              Back
            </SNButton>
          </Box>
          {typeof title === 'string' ? (
            <Typography variant="h1">
              {loading ? <Skeleton width={200} /> : title}
            </Typography>
          ) : (
            <Box pb={2}>{title}</Box>
          )}
        </Box>
        {actionLabel && (
          <Box mt={2} mr={5}>
            <SNButton snVariant="primary">{actionLabel}</SNButton>
          </Box>
        )}
      </Box>
      <SNTabs active={tab} onTabClick={handleTabClick} tabs={tabs} />
      {children}
    </>
  );
};

export default DetailTabs;
