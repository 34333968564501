import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  EntityTypesListQuery,
  EntityTypesListQueryVariables,
} from '../types/new/graphql';

export const ENTITY_TYPES_LIST = gql`
  query EntityTypesList(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: EntityTypeFilter
  ) {
    entityTypesList(
      last: $last
      first: $first
      before: $before
      after: $after
      filter: $filter
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
          endIndex @client
          startIndex @client
        }
        edges {
          cursor
          node {
            id
            name
            description
            rawDefinition
            state
            createdBy {
              name
              picture
            }
            updatedAt
            fields {
              id
              name
              description
              dataPoint {
                dataType
              }
              type @client
              inputOptions {
                __typename
                ... on TextInputOptions {
                  type
                }
                ... on Autogenerated {
                  displayOnEntry
                }
                ... on DateTimeInputOptions {
                  captureTime
                }
                ... on LocalSelectInputOptions {
                  type
                  options {
                    text
                    value
                  }
                }
                ... on NumericInputOptions {
                  type
                  precision
                }
              }
            }
            objectLinks {
              id
              name
              objectLabel
            }
            subjectLinks {
              id
              name
            }
            totalCount
          }
        }
      }
      errors
    }
  }
`;

export const useGetLazyEntityTypesListQuery = (
  options?: LazyQueryHookOptions<
    EntityTypesListQuery,
    EntityTypesListQueryVariables
  >,
) => {
  return useLazyQuery<EntityTypesListQuery, EntityTypesListQueryVariables>(
    ENTITY_TYPES_LIST,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useGetEntityTypesListQuery = (
  options?: QueryHookOptions<
    EntityTypesListQuery,
    EntityTypesListQueryVariables
  >,
) => {
  return useQuery<EntityTypesListQuery, EntityTypesListQueryVariables>(
    ENTITY_TYPES_LIST,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};
