import {
  Navigate,
  Routes,
  Route,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { useGetProjectByIdQuery } from '../../queries/useGetProjectByIdQuery';
import ProjectUsersTable from '../../components/ProjectUsersTable';
import DetailTabs from '../../components/DetailTabs';
import ProjectGroupsTable from '../../components/ProjectGroupsTable';
import React from 'react';
import { collectErrors } from '../../utils/collectErrors';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { Box } from '@mui/material';
import SNEditableLabel from '../../components/SNEditableLabel';
import { useUpdateProject } from '../../mutations';

const projectDetailTabsArray = ['users', 'groups'];

const ProjectDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetProjectByIdQuery({
    variables: { id: id ? id : '' },
  });
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const navigate = useNavigate();

  const [updateProject, { data: updatedData, loading: updateLoading }] =
    useUpdateProject();

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      data?.project?.errors,
      updatedData?.updateProject?.errors,
    ]);
  }, [updatedData, data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching default project',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError, updatedData]);

  const handleOnBack = () => {
    navigate('/settings/projects');
  };

  const handleNameSave = (value: string) => {
    updateProject({
      variables: { input: { id: id || '', name: value } },
      fetchPolicy: 'network-only',
      refetchQueries: ['Project'],
    });
  };
  const handleDescriptionSave = (value: string) => {
    updateProject({
      variables: { input: { id: id || '', description: value } },
      fetchPolicy: 'network-only',
      refetchQueries: ['Project'],
    });
  };

  const DetailTitle =
    loading || updateLoading ? null : (
      <Box>
        <SNEditableLabel
          value={data?.project?.project?.name || 'Project Not Found'}
          onSave={handleNameSave}
          name="name"
          subtitle={false}
        />
        <SNEditableLabel
          value={data?.project?.project?.description || ''}
          onSave={handleDescriptionSave}
          name="description"
          subtitle
        />
      </Box>
    );

  if (!id) {
    return null;
  }

  return (
    <DetailTabs
      actionLabel={data?.project?.project?.id && 'View Project Data'}
      baseRoute="settings/projects"
      id={id}
      loading={loading}
      tabs={projectDetailTabsArray}
      title={DetailTitle}
      onBack={handleOnBack}
    >
      <Routes>
        <Route path="users" element={<ProjectUsersTable />} />
        <Route path="groups" element={<ProjectGroupsTable />} />
        <Route path="*" element={<Navigate to="users" />} />
      </Routes>
    </DetailTabs>
  );
};

export default ProjectDetail;
