import React from 'react';
import { Box } from '@mui/system';
import {
  PossibleAddedValidation,
  WorkingAreaValidations,
} from './EntityTypeDetailsFieldValidationsModal';
import SNInput from '../SNInput';
import { PresenceValidation } from '../../types/newEntityTypeTypes';
import { Typography } from '@mui/material';

interface PresenceFieldValidationFormProps {
  setAddedValidation: React.Dispatch<
    React.SetStateAction<WorkingAreaValidations>
  >;
  addedValidation: PossibleAddedValidation;
}

const PresenceFieldValidationForm = ({
  addedValidation,
  setAddedValidation,
}: PresenceFieldValidationFormProps) => {
  const currentValues = React.useMemo(() => {
    return addedValidation.validation as PresenceValidation;
  }, [addedValidation]);

  const handleChange = (e: any) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  return (
    <Box>
      <Box my={2}>
        <Typography color="textSecondary">
          A presence validation ensures that this column has a value
        </Typography>
      </Box>
      <SNInput
        required
        label="Error Message"
        name="message"
        helperText={
          currentValues.message || !addedValidation.showInputErrors ? (
            ''
          ) : (
            <Typography color="error">This value is required</Typography>
          )
        }
        onChange={handleChange}
        value={currentValues.message || 'This field is required'}
      />
    </Box>
  );
};

export default PresenceFieldValidationForm;
