import { Box } from '@mui/system';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { RemoteSelectInputOptions } from '../../types/newEntityTypeTypes';
import { useDebounce } from '../../hooks';
import { useGetListsQuery } from '../../queries';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import Autocomplete from '../Autocomplete';
import SNInput from '../SNInput';
import SearchIcon from '@mui/icons-material/Search';
import { ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node } from '../../types/schemaTypes';
import SNTooltip from '../SNTooltip';
import { InfoOutlined } from '@mui/icons-material';
import LoadingOverlay from '../LoadingOverlay';
import { DEFAULT_PAGE_SIZE } from '../../constants';

interface EntityTypeFieldManagedSelectInputOptionsFormProps {
  setInputOptions: Dispatch<SetStateAction<RemoteSelectInputOptions>>;
  inputOptions: RemoteSelectInputOptions;
  columnId: string;
}

const EntityTypeFieldManagedSelectInputOptionsForm = ({
  setInputOptions,
  inputOptions,
}: EntityTypeFieldManagedSelectInputOptionsFormProps) => {
  const [pageSize, _setPageSize] = React.useState(DEFAULT_PAGE_SIZE);
  const [listSearchTerm, setListSearchTerm] = React.useState('');
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [usingPlatformManagedList, setUsingPlatformManagedList] =
    React.useState<boolean>(true);

  const debouncedListSearchTerm = useDebounce(listSearchTerm, 300);
  const { data, loading } = useGetListsQuery({
    variables: {
      first: pageSize,
      filter: {
        searchTerm: debouncedListSearchTerm,
      },
    },
    onCompleted: (res) => {
      // we do this to determine if we are already using a platform managed list
      const match = res?.getControlledVocabularyCategories?.data?.edges.filter(
        (edge) => {
          return edge.node.listEndpoint === inputOptions.source_url;
        },
      );
      if (match) {
        setUsingPlatformManagedList(match?.length === 1);
      }
    },
    onError: (e) => {
      setGeneralError({
        open: true,
        message: e.message,
        details: 'Error getting Lists',
      });
    },
  });

  const listOptions = React.useMemo(() => {
    return (
      data?.getControlledVocabularyCategories?.data?.edges.map((edge) => {
        return edge.node;
      }) || []
    );
  }, [data]);

  // this compares the url in the input options to the urls of the managed lists in the platform to find the platform managed list we're using if there is one.
  const selectedSystemList = React.useMemo(() => {
    return (
      listOptions.find((option) => {
        return option.listEndpoint === inputOptions.source_url;
      }) || undefined
    );
  }, [listOptions, inputOptions]);

  const handleAllowNewToggle = () => {
    setInputOptions((prev) => {
      return {
        ...prev,
        allowNew: !prev.allowNew,
      };
    });
  };

  const handleListSelect = (
    e: any,
    value: ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node | null,
  ) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        source_url: value?.listEndpoint,
      };
    });
    setListSearchTerm('');
  };

  const handleListInputChange = (e: any, value: string) => {
    setListSearchTerm(value);
  };

  const handleUsePlatformListChange = () => {
    setUsingPlatformManagedList((prev) => {
      return !prev;
    });
  };

  const handleCustomURLInputChange = React.useCallback(
    (e: any) => {
      setInputOptions((prev) => {
        return {
          ...prev,
          source_url: e.target.value,
        };
      });
    },
    [setInputOptions],
  );

  return (
    <Box>
      <label>
        <Box py={1} color="grey.200">
          <Typography variant="h6" color="inherit">
            Allow users to add new entries?
          </Typography>
        </Box>
        <RadioGroup
          value={inputOptions.allowNew}
          onChange={handleAllowNewToggle}
        >
          <Box display="flex">
            <FormControlLabel value control={<Radio />} label="Allow" />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Disallow"
            />
          </Box>
        </RadioGroup>
      </label>
      <label>
        <Box py={1} color="grey.200">
          <Typography variant="h6" color="inherit">
            List in Platform or External List?
          </Typography>
        </Box>
        <RadioGroup
          value={usingPlatformManagedList}
          onChange={handleUsePlatformListChange}
        >
          <Box display="flex">
            <FormControlLabel
              value
              control={<Radio />}
              label="List in Platform"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="External List"
            />
          </Box>
        </RadioGroup>
      </label>
      <Box>
        {usingPlatformManagedList && !loading ? (
          <Box>
            <LoadingOverlay isLoading={loading} />
            <Autocomplete
              multiple={false}
              freeSolo={false}
              onChange={handleListSelect}
              onInputChange={handleListInputChange}
              options={listOptions}
              value={selectedSystemList}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  component="li"
                  key={option.id}
                  display="flex"
                  sx={{ whiteSpace: 'nowrap' }}
                  overflow="hidden"
                >
                  <Box>{option.name}</Box>
                  <Box
                    pl={1}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color="text.secondary"
                  >
                    <em>{option.description}</em>
                  </Box>
                </Box>
              )}
              renderInput={({ InputProps, InputLabelProps, ...params }) => (
                <SNInput
                  {...InputProps}
                  {...params}
                  className=""
                  autoFocus
                  label="Choose Managed List"
                  placeholder="Search for list"
                  startAdornment={
                    <Box display="flex" ml={1}>
                      <SearchIcon />
                    </Box>
                  }
                />
              )}
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <SNInput
              label="Custom URL"
              value={inputOptions.source_url}
              onChange={handleCustomURLInputChange}
            />
            <Box mt={5} ml={1}>
              <SNTooltip title="Please make sure this url is a REST endpoint that returns your preferred list items in an array. See the Snthesis platform documentation for further information.">
                <InfoOutlined color="disabled" />
              </SNTooltip>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EntityTypeFieldManagedSelectInputOptionsForm;
