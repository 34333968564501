import { Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import React, { PropsWithChildren } from 'react';
import DialogCloseButton from '../DialogCloseButton';

interface SNDialogTitleProps extends PropsWithChildren {
  fullScreen?: boolean;
  onClose?: () => void;
  variant?: TypographyProps['variant'];
  id?: string;
}

const SNDialogTitle: React.FC<SNDialogTitleProps> = ({
  fullScreen,
  onClose,
  variant = 'h2',
  children,
  id,
}) => {
  return (
    <Box id={id} px={4} py={3} flex="0 0 auto">
      {typeof children === 'string' ? (
        <Typography variant={variant}>{children}</Typography>
      ) : (
        children
      )}
      {onClose && (
        <DialogCloseButton
          onClose={onClose}
          size={fullScreen ? 'large' : 'medium'}
        />
      )}
    </Box>
  );
};

export default SNDialogTitle;
