import React from 'react';
import { Box, Typography } from '@mui/material';
import { countValidations } from '../../utils/countValidations';
import { SEVERITY_PALETTE } from '../../constants';
import { EditEntitiesState, useEditEntitiesState } from '../../contexts';
import { AnnotationSeverity } from '../../types/schemaTypes';
import SNStatusBanner from '../SNStatusBanner';
import pluralize from 'pluralize';
import styled from '../../services/styled';

const StyledList = styled('ul')`
  padding-left: ${({ theme }) => theme.spacing(2)};
  list-style-type: initial;
`;

interface EditValidationsBannerProps {
  secondaryFieldValidationMessage?: string;
}

const EditValidationsBanner = ({
  secondaryFieldValidationMessage,
}: EditValidationsBannerProps) => {
  const editState = useEditEntitiesState();
  const validationNumbers = React.useMemo(() => {
    return countValidations(editState);
  }, [editState]);

  const getOverallDisplayStatus = (editState: EditEntitiesState) => {
    const entityIds = Object.keys(editState.validations);
    const uniqueStatuses = new Set(
      entityIds.map((id) => {
        return editState.validations[id].displayStatus;
      }),
    );
    const overallStatus = uniqueStatuses.has(AnnotationSeverity.ERROR)
      ? AnnotationSeverity.ERROR
      : uniqueStatuses.has(AnnotationSeverity.WARNING)
        ? AnnotationSeverity.WARNING
        : AnnotationSeverity.INFO;
    return overallStatus;
  };

  const annotationStatus = React.useMemo(() => {
    return SEVERITY_PALETTE[getOverallDisplayStatus(editState)];
  }, [editState]);

  const getGeneralValidationMessages = (editState: EditEntitiesState) => {
    const editedRowIds = Object.keys(editState.validations);

    return (
      <Box mt={1}>
        <StyledList>
          {editedRowIds.map((entityId) => {
            return editState.validations[entityId].generalAnnotations.map(
              (genAnnotation) => {
                return (
                  <li key={genAnnotation.message + entityId}>
                    <Typography color="textPrimary">
                      {genAnnotation.message}
                    </Typography>
                  </li>
                );
              },
            );
          })}
        </StyledList>
      </Box>
    );
  };

  return (
    <Box m={-1}>
      {validationNumbers.generalAnnotations > 0 && (
        <Box m={1}>
          <SNStatusBanner status={annotationStatus} alignItems="flex-start">
            <Typography variant="h5">
              {validationNumbers.generalAnnotations}&nbsp;entity&nbsp;
              {pluralize('error', validationNumbers.generalAnnotations)}
            </Typography>
            {getGeneralValidationMessages(editState)}
          </SNStatusBanner>
        </Box>
      )}
      {validationNumbers.fieldsWithValidations > 0 && (
        <Box m={1}>
          <SNStatusBanner status={annotationStatus} alignItems="flex-start">
            <Typography variant="h5">
              {validationNumbers.fieldsWithValidations}&nbsp;
              {pluralize('value', validationNumbers.fieldsWithValidations)}
              &nbsp;
              {validationNumbers.fieldsWithValidations === 1 ? 'needs' : 'need'}
              &nbsp;review
            </Typography>
            {secondaryFieldValidationMessage && (
              <Box mt={1}>{secondaryFieldValidationMessage}</Box>
            )}
          </SNStatusBanner>
        </Box>
      )}
    </Box>
  );
};

export default EditValidationsBanner;
