import SNDialogTitle from '../SNDialogTitle';
import React from 'react';
import { useRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import {
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import {
  FieldReference,
  LinkDefinition,
  RelationshipDefinition,
} from '../../types/newEntityTypeTypes';
import { Box } from '@mui/system';
import Autocomplete from '../Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { LinkTypesQuery } from '../../types/new/graphql';
import EntityTypeRelationshipModalLinkableTypeForm from './EntityTypeRelationshipModalLinkableTypeForm';
import cuid from 'cuid';
import { useEntityTypesQuery } from '../../queries';

export interface AddedLinkableType {
  id: string;
  link: LinkDefinition;
}

export type WorkingLinkableTypes = Record<
  string,
  AddedLinkableType | undefined
>;

export interface WorkingFieldRef {
  id: string;
  ref: FieldReference;
}

export type FieldRefs = Record<string, WorkingFieldRef | undefined>;

export interface WorkingFieldRefsByLinkableType {
  linkableTypeId: string;
  fieldRefs: FieldRefs;
}

interface EntityTypeDetailRelationshipModalProps {
  onClose: () => void;
  relationshipId?: string;
  linkTypesData: LinkTypesQuery['linkTypes'];
}

/*
NOTE:
each 'realationship' is connected to a set of 'links' each of which in turn has 'field references' which describe how an entity type can fufill that relationsihp.
eg: a 'created by' relationsihp can be fufilled by a 'person' entity, and also an 'organization' entity. and the way the link
between each of those entities and the entity being linked to would neccesarily be slightly different despite still being a 'created by' relationship
(ie: the 'created by' field might show a name in the case of a person, but a corporate id in the case of a company - these are the 'field references'. mapping local fields to foreign ones.).
in short, relationships and links are two seperate, but very related concepts that the data needs to keep track of.

as a result of this, in the entity type data structure, relationships and links are seperate fields. field references are contained in links.
We thought the way that 'relationships', 'links' and 'field references' function might be more clear if the UI showed the links as part of a relationsihps.

this is good for users, but it makes the data management a bit tricky, because we need to index the links by the relationships when creating and editing
 and seperate them back out when saving to the entity type data structure. not only that but we also need to modify
 existing links/relationships so that they fit the 'working'/indexed data structure before the modal is even open

 thats why in the components that deal with creating and editing relationships, youll find 'working' data structures in react state that hold the information being added/edited.
 they are as follows:
 1. linkableTypes - these are the 'links' and represent the entity types that are allowed to be part of a relationship.
 they reference the relationship AND field pairs. each relationship can have multiple of these
 2. fieldReferences - these are pairs of fields (one from this entity type, and one from the foreign entity type) that are linked.
 each link can have multiple of these.
 3. workingRelationshipDefinition - this is the overall definition of the relationship, not including linked entity types or field references.

 each of these data structures are defined in this file and are mapped over and passed to various forms and sub forms as needed.
 when a user hits save, they are transformed into a format that the backend understands and added to the entityTypeInfo recoil atom to be sent to
 the backend to override the entity type info we have there with new stuff we just created/edited
 */

const EntityTypeDetailRelationshipModal = ({
  onClose,
  relationshipId,
  linkTypesData,
}: EntityTypeDetailRelationshipModalProps) => {
  const defaultRelationship: RelationshipDefinition = {
    label: '',
    id: relationshipId || '',
    types: [],
    link_type: '',
    required: false,
    subject_label: '',
    object_label: '',
  };

  const defaultLink: LinkDefinition = {
    relationship: relationshipId || '',
    target_data_set: '',
    references: [],
    extracted_data: [],
  };

  //this is the full entity type info for use ONLY when we hit the 'save' button to save changes to a relationship
  const [entityTypeInfo, setEntityTypeInfo] = useRecoilState(newEntityTypeAtom);
  const [linkTypeValue, setLinkTypeValue] = React.useState('');

  const relationship = React.useMemo(() => {
    return entityTypeInfo.relationships[relationshipId as string];
  }, [relationshipId, entityTypeInfo]);

  // here we create linkable types for all allowed types that do NOT have linked fields.
  // so wouldn't should up as links, which is where we get the other allowable types from below
  const unlinkedAllowables = relationship?.types
    .map((allowable) => {
      return {
        ...defaultLink,
        target_data_set: allowable,
        relationship: relationshipId || '',
      };
    })
    .filter((unlinked) => {
      return !unlinked ? 0 : 1;
    });
  //these are the link definitions that already exist in the entity type that are related to THIS relationship.
  const existingLinkDefinitions: AddedLinkableType[] = React.useMemo(() => {
    const filteredDefs = entityTypeInfo.links.filter((linkDef) => {
      return linkDef.relationship === relationshipId;
    });
    //combine unlinked allowed types with allowed types that have links (called link definitions above)
    const combinedDefs = filteredDefs.concat(unlinkedAllowables);
    //give them temp ids for ease of manipulation in browser.
    return combinedDefs.map((def) => {
      return {
        id: cuid(), // assign arbitrary id for temp use
        link: def,
      };
    });
  }, [unlinkedAllowables, relationshipId, entityTypeInfo]);

  //these are the existing link definitions above turned into a more convenient form for manipulation
  const WorkingLinkedTypes: WorkingLinkableTypes = React.useMemo(() => {
    const workables: WorkingLinkableTypes = {};
    existingLinkDefinitions.forEach((addedType) => {
      if (addedType.link) {
        //if we have an actual link in the linkedType, add it to the workables. otherwise, forget it.
        workables[addedType.id] = addedType;
      }
    });
    return workables;
  }, [existingLinkDefinitions]);

  // here we set the more easily manipulated, already existing link types to a state variable for editing
  // this is what we use to record data entered/changed by a user while the modal is open.
  const [linkableTypes, setLinkableTypes] =
    React.useState<WorkingLinkableTypes>(WorkingLinkedTypes || {});

  // this is where we extract the existing field references from the links
  // and give them temp ids, and match them to linkableTypes for ease of manipulation
  const existingFieldReferences: WorkingFieldRefsByLinkableType[] =
    React.useMemo(() => {
      const linkableTypesValueArray = Object.values(linkableTypes);
      const refsBylinkableType = linkableTypesValueArray.map((type) => {
        const linkableTypeId = type?.id;
        const fieldRefs = type?.link?.references?.flat().map((linkRef) => {
          return linkRef.fields;
        });
        return {
          linkableTypeId: linkableTypeId || '',
          fieldRefs: fieldRefs,
        };
      });
      return refsBylinkableType.map((ref) => {
        const tempFieldRefs: FieldRefs = {};
        ref.fieldRefs?.flat(2).forEach((ref) => {
          const newId = cuid();
          tempFieldRefs[newId] = {
            id: newId,
            ref: ref,
          };
        });
        const returnItem = {
          ...ref,
          fieldRefs: tempFieldRefs,
        };
        return returnItem;
      });
    }, [linkableTypes]);

  const [fieldReferences, setFieldReferences] = React.useState<
    WorkingFieldRefsByLinkableType[]
  >(existingFieldReferences);

  const entityTypesInUse = React.useMemo(() => {
    return Object.values(linkableTypes).map((tempType) => {
      return tempType?.link ? tempType?.link?.target_data_set : '';
    });
  }, [linkableTypes]);

  const options = React.useMemo(() => {
    return linkTypesData || [];
  }, [linkTypesData]);

  const { data: entityTypesData } = useEntityTypesQuery();

  // this is the existing relationship information for this relationship or if there is none
  // (ie: a newly created relationship) we return undefined and add a default relationship in the state below
  const thisRelationship = React.useMemo(() => {
    return entityTypeInfo.relationships[relationshipId || ''];
  }, [relationshipId, entityTypeInfo]);

  // this is the existing relationship information (or default) added to a state vairable for easy manipulation.
  const [workingRelationshipDefinition, setWorkingRelationshipDefinition] =
    React.useState<RelationshipDefinition>(
      thisRelationship || defaultRelationship,
    );

  const handleGeneralInputChange = (e: any) => {
    setWorkingRelationshipDefinition((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleLinkTypeValueChange = (e: any, value: string) => {
    setLinkTypeValue(value);
  };

  const handleSelectLinkTypeChange = (_e: any, value: any) => {
    setWorkingRelationshipDefinition((prev) => {
      return {
        ...prev,
        link_type: value?.id || '',
      };
    });
  };

  const handleClose = () => {
    onClose();
    setFieldReferences([]);
    setLinkableTypes({});
    setWorkingRelationshipDefinition(defaultRelationship);
  };

  const handleAddLinkableType = () => {
    setLinkableTypes((prev) => {
      const newId = cuid();
      return {
        [newId]: {
          link: defaultLink,
          id: newId,
        },
        ...prev,
      };
    });
  };

  const handleRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(
      'the target value: ',
      typeof event.target.value,
      event.target.value,
    );
    const required = event.target.value === 'true' ? true : false;
    setWorkingRelationshipDefinition((prev) => {
      return {
        ...prev,
        required: required,
      };
    });
  };

  const handleDeleteLinkableType = (linkableTypeIdToDelete: string) => {
    setLinkableTypes((prev) => {
      const intermediaryInfo = { ...prev };
      delete intermediaryInfo[linkableTypeIdToDelete];
      return intermediaryInfo;
    });
    setFieldReferences((prev) => {
      const refsToKeep = prev.filter((type) => {
        return type.linkableTypeId !== linkableTypeIdToDelete;
      });
      return [...refsToKeep];
    });
  };

  const handleSave = () => {
    // here we take the working relationsihp and link data that the user has been adding/editing
    // and unwind it from the format that made it easier to work with (ie: remove arbitrary ids)
    // and put it in the format that the back end expects
    let relationshipIdToSave: string = relationshipId || '';
    if (!relationshipIdToSave || relationshipIdToSave.includes('new')) {
      relationshipIdToSave = cuid();
    }
    const typesAllowed = linkableTypeValues.map((type) => {
      return type?.link?.target_data_set || '';
    });

    const linkables = linkableTypeValues.map((linkable) => {
      const referencesForLinkable =
        fieldReferences.find((ref) => {
          return ref.linkableTypeId === linkable?.id;
        })?.fieldRefs || {};
      const fieldsForRefs = Object.values(referencesForLinkable);
      return {
        ...linkable?.link,
        target_data_set: linkable?.link.target_data_set || '',
        relationship: relationshipIdToSave,
        references: [
          {
            use_in_queries: true,
            fields: fieldsForRefs.map((fields) => {
              return fields?.ref || { local_column: '', foreign_column: '' };
            }),
          },
        ],
      };
    });
    // here we save the unwound relationship/link data in our full entity type recoil atom.
    // TODO: send this entire entity type info to the back end to overwrite
    //  what we already have for this entity type with the new relationships and links.
    setEntityTypeInfo((prev) => {
      return {
        ...prev,
        relationships: {
          ...prev.relationships,
          [relationshipIdToSave]: {
            ...workingRelationshipDefinition,
            id: relationshipIdToSave,
            types: typesAllowed,
          },
        },
        links: [...prev.links, ...linkables],
      };
    });
    onClose();
  };

  const linkableTypeValues = Object.values(linkableTypes);

  React.useEffect(() => {
    console.log('required?: ', workingRelationshipDefinition.required);
  }, [workingRelationshipDefinition.required]);

  return (
    <>
      <SNDialogTitle onClose={handleClose}>
        {thisRelationship ? `${thisRelationship.label}` : 'New Relationship'}
      </SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <SNInput
            label="Label"
            name="label"
            onChange={handleGeneralInputChange}
            value={workingRelationshipDefinition.label}
          />
          <Box>
            <Autocomplete
              multiple={false}
              freeSolo={false}
              onChange={handleSelectLinkTypeChange}
              inputValue={linkTypeValue}
              onInputChange={handleLinkTypeValueChange}
              value={options.find((opt) => {
                return opt.id === workingRelationshipDefinition.link_type;
              })}
              options={options}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  component="li"
                  key={option.name}
                  display="flex"
                  overflow="hidden"
                  sx={{ whiteSpace: 'nowrap', textAlign: 'left' }}
                >
                  <Box>
                    <Box>{option.name}</Box>
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{ fontSize: '.6rem' }}
                        color="textSecondary"
                      >
                        {option.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              renderInput={({ InputProps, InputLabelProps, ...params }) => (
                <SNInput
                  {...InputProps}
                  {...params}
                  label="Link Type"
                  className=""
                  placeholder="Select Link Type"
                  startAdornment={
                    <Box display="flex" ml={1}>
                      <SearchIcon />
                    </Box>
                  }
                />
              )}
            />
          </Box>
          <SNInput
            label="Subject Label"
            name="subject_label"
            onChange={handleGeneralInputChange}
            value={workingRelationshipDefinition.subject_label}
          />
          <SNInput
            label="Object Label"
            name="object_label"
            onChange={handleGeneralInputChange}
            value={workingRelationshipDefinition.object_label}
          />
          <Box mt={1}>
            <label>
              <Box py={1} color="grey.200">
                <Typography variant="h6" color="inherit">
                  Required?
                </Typography>
              </Box>
              <RadioGroup
                aria-label="Required?"
                value={workingRelationshipDefinition.required}
                onChange={handleRequiredChange}
              >
                <Box display="flex">
                  <FormControlLabel
                    value
                    control={<Radio />}
                    label="This relationship is required"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="This relationship is NOT required"
                  />
                </Box>
              </RadioGroup>
            </label>
          </Box>
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Typography variant="h6" color="textSecondary">
              Linkable Types
            </Typography>
            <Box>
              <SNButton onClick={handleAddLinkableType}>Add Type</SNButton>
            </Box>
          </Box>
          <Box>
            {linkableTypeValues.map((linkableType) => {
              return (
                <EntityTypeRelationshipModalLinkableTypeForm
                  key={linkableType?.id}
                  onDelete={() =>
                    handleDeleteLinkableType(linkableType?.id || '')
                  }
                  linkableTypeInfo={linkableType}
                  entityTypes={entityTypesData?.entityTypes}
                  entityTypesInUse={entityTypesInUse}
                  setLinkableType={setLinkableTypes}
                  fieldRefsForThisLinkableType={fieldReferences?.find((ref) => {
                    return ref.linkableTypeId === linkableType?.id;
                  })}
                  setFieldReferences={setFieldReferences}
                />
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleSave}>Save</SNButton>
      </DialogActions>
    </>
  );
};

export default EntityTypeDetailRelationshipModal;
