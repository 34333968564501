import { atom } from 'recoil';
import { HttpsSnthesisComSchemasTypeDefinition } from '../types/newEntityTypeTypes';

export const newEntityTypeAtom = atom<HttpsSnthesisComSchemasTypeDefinition>({
  key: 'newEntityTypeAtom',
  default: {
    schema: 'type-definition',
    id: '',
    name: '',
    description: '',
    data_set_type: '',
    export_details: {
      bucket: '',
      export_ordering: [],
      partition_template: '',
    },
    version: '0.0.1',
    mappers: [],
    relationships: {},
    links: [],
    fields: {},
  },
});
