import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/system';
import cuid from 'cuid';
import SNButton from '../SNButton';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import SelectManualListItem from './SelectManualListItem';
import {
  LocalSelectInputOptions,
  SelectOption,
} from '../../types/newEntityTypeTypes';

interface EntityTypeFieldManualSelectInputOptionsFormProps {
  setInputOptions: Dispatch<SetStateAction<LocalSelectInputOptions>>;
  inputOptions: LocalSelectInputOptions;
}

const EntityTypeFieldManualSelectInputOptionsForm = ({
  setInputOptions,
  inputOptions,
}: EntityTypeFieldManualSelectInputOptionsFormProps) => {
  const options: SelectOption[] = React.useMemo(() => {
    return inputOptions.options as SelectOption[];
  }, [inputOptions]);

  const handleAllowNewToggle = () => {
    setInputOptions((prev) => {
      return {
        ...prev,
        allowNew: !prev.allowNew,
      };
    });
  };

  const handleAddItem = () => {
    setInputOptions((prevState) => {
      return {
        ...prevState,
        options: [
          { value: '', text: '', description: '', id: cuid() },
          ...prevState.options,
        ],
      };
    });
  };

  return (
    <Box mt={2}>
      <Box>
        <label>
          <Box py={1} color="grey.200">
            <Typography variant="h6" color="inherit">
              Allow users to add new entries?
            </Typography>
          </Box>
          <RadioGroup
            value={inputOptions.allowNew}
            onChange={handleAllowNewToggle}
          >
            <Box display="flex">
              <FormControlLabel value control={<Radio />} label="Allow" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Disallow"
              />
            </Box>
          </RadioGroup>
        </label>
      </Box>
      <SNButton onClick={handleAddItem}>Add Item</SNButton>
      <Box>
        {options?.length === 0 ? (
          <Box display="flex" mt={2}>
            <Typography>
              Please add an item to this manually defined list by clicking the
              Add Item button
            </Typography>
          </Box>
        ) : (
          <Box mt={2}>
            {options?.map((option) => {
              return (
                <SelectManualListItem
                  key={option.id as string}
                  setInputOptions={setInputOptions}
                  id={option.id as string}
                  name={option.text}
                  value={option.value}
                  description={option.description}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EntityTypeFieldManualSelectInputOptionsForm;
