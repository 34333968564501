import { Link, LinkProps, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '../../services/styled';
import { Link as RouterLink } from 'react-router-dom';

export interface TopBarNavItemProps extends PropsWithChildren {
  to: string;
  additionalMatches?: string[];
  onClick?: () => void;
}

const StyledTypography = styled(Typography)`
  transition: ${({ theme }) => theme.transitions.create('color')};
`;

interface StyledUnderlineProps extends LinkProps {
  to: string;
  match: boolean;
}

const StyledUnderline = styled(({ to, children }: StyledUnderlineProps) => (
  <Link component={RouterLink} to={to}>
    {children}
  </Link>
))`
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  :hover {
    border-bottom-color: ${({ match, theme }) =>
      match ? theme.palette.text.primary : theme.palette.text.secondary};
  }
  transition: border-bottom-color 300ms ease-in-out;
`;

const manuallyMatch = (desiredMatches: string[], path: string) => {
  const matchArray = desiredMatches.map((word) => {
    return path.includes(word);
  });
  return matchArray.includes(true);
};

const TopBarNavItem: React.FC<TopBarNavItemProps> = ({
  children,
  to,
  additionalMatches = [],
  onClick,
}) => {
  const { pathname } = useLocation();
  const active = manuallyMatch([to, ...additionalMatches], pathname);
  return (
    <StyledUnderline match={Boolean(active)} to={to} onClick={onClick}>
      <StyledTypography
        variant="h6"
        color={active ? 'textPrimary' : 'textSecondary'}
      >
        {children}
      </StyledTypography>
    </StyledUnderline>
  );
};

export default TopBarNavItem;
