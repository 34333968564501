import React from 'react';
import { Box, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import SNTableRow from '../SNTableRow';

interface DataPointsTableRowProps {
  name?: string | null;
  id?: string;
  description?: string | null;
  dataType: string;
  index: number;
}

const DataPointsTableRow = ({
  name,
  id,
  description,
  dataType,
  index,
}: DataPointsTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Box display="flex" alignItems="center" height={56}>
          <Box fontWeight="bold">
            <Typography>{name}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Typography>{description}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Typography>{dataType}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Typography>{id}</Typography>
        </Box>
      </td>
    </SNTableRow>
  );
};

export default DataPointsTableRow;
