import React, { PropsWithChildren, ReactElement } from 'react';
import { Menu, Box, MenuProps } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import SNButton from '../SNButton';
import { SNVariant } from '../SNButton/SNButton';

interface DropdownProps extends Omit<MenuProps, 'open'> {
  closeOnSelect?: boolean;
  label: React.ReactNode;
  snVariant?: SNVariant;
}

const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = ({
  closeOnSelect,
  label,
  children,
  snVariant = 'text',
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box position="relative">
      <SNButton
        snVariant={snVariant}
        onClick={handleClick}
        endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
      >
        {label}
      </SNButton>
      <Menu
        transitionDuration={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...rest}
      >
        {React.Children.map(children, (child) => {
          if (closeOnSelect && React.isValidElement(child)) {
            const c: ReactElement = child;
            return React.cloneElement(c, {
              onClick: () => {
                if (child.props.onClick) child.props.onClick();
                handleClose();
              },
            });
          }
          return child;
        })}
      </Menu>
    </Box>
  );
};

export default Dropdown;
