import React, { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { useDebounce } from '../../hooks';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '../Autocomplete';
import SNInput from '../SNInput';
import { useGetLazyGroupsQuery } from '../../queries/useGetGroupsQuery';
import { Group } from '@mui/icons-material';
import DialogCloseButton from '../DialogCloseButton';
import SNListItem from '../SNListItem';
import GroupProjectAccess from './GroupProjectAccess';
import GroupMembership from './GroupMembership';

export type GroupOption = {
  name: string;
  id: string;
  description: string;
};

export type SelectedGroups = Record<string, GroupOption>;

interface GroupsToAddSelectorProps {
  selectedGroups: SelectedGroups;
  setSelectedGroups: React.Dispatch<React.SetStateAction<SelectedGroups>>;
  showProjectAccess?: boolean;
  showGroupMembership?: boolean;
}

const GroupsToAddSelector = ({
  selectedGroups,
  setSelectedGroups,
  showProjectAccess,
  showGroupMembership,
}: GroupsToAddSelectorProps) => {
  const pageSize = 10;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [value, setValue] = React.useState<GroupOption | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [getGroups, { data }] = useGetLazyGroupsQuery();

  React.useEffect(() => {
    getGroups({
      variables: {
        first: pageSize,
        filter: {
          searchTerm: debouncedSearchTerm,
        },
      },
    });
  }, [debouncedSearchTerm, pageSize, getGroups]);

  const options = React.useMemo<GroupOption[]>(
    () =>
      data?.groups?.data?.edges.map((edge) => ({
        name: edge.node.name || '',
        id: edge.node.id,
        description: edge.node.description || '',
      })) || [],
    [data],
  );

  const handleInputChange = (event: ChangeEvent<unknown>, value: string) => {
    setSearchTerm(value);
  };

  const handleGroupSelect = React.useCallback(
    (_event: ChangeEvent<unknown>, value: GroupOption | null) => {
      setSearchTerm('');
      setValue(null);
      if (value !== null) {
        setSelectedGroups((previous) => {
          return {
            ...previous,
            [value?.id]: value,
          };
        });
      }
    },
    [setSelectedGroups],
  );

  const handleRemoveGroup = React.useCallback(
    (groupId: string) => () => {
      const { [groupId]: omit, ...newGroups } = selectedGroups;
      setSelectedGroups(newGroups);
    },
    [setSelectedGroups, selectedGroups],
  );

  const filterOptions = React.useCallback(
    (options: GroupOption[]) => options,
    [],
  );

  const getOptionLabel = React.useCallback(() => '', []);

  return (
    <Box display="flex" flexDirection="column">
      <Autocomplete
        multiple={false}
        freeSolo={false}
        onChange={handleGroupSelect}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
        options={options}
        value={value}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => (
          <Box
            {...props}
            component="li"
            key={option.id}
            display="flex"
            sx={{ whiteSpace: 'nowrap' }}
            overflow="hidden"
          >
            <Box>{option.name}</Box>
            <Box
              pl={1}
              overflow="hidden"
              textOverflow="ellipsis"
              color="text.secondary"
            >
              <em>{option.description}</em>
            </Box>
          </Box>
        )}
        renderInput={({ InputProps, InputLabelProps, ...params }) => (
          <SNInput
            {...InputProps}
            {...params}
            className=""
            autoFocus
            label="Groups"
            placeholder="Search for Groups"
            startAdornment={
              <Box display="flex" ml={1}>
                <SearchIcon />
              </Box>
            }
          />
        )}
      />
      <Box mt={2}>
        <Box display="flex">
          <Box width="55%">
            <Typography variant="h6" color="textSecondary">
              Selected Groups
            </Typography>
          </Box>
          {showProjectAccess && (
            <Box width="45%">
              <Typography variant="h6" color="textSecondary">
                Project Access
              </Typography>
            </Box>
          )}
          {showGroupMembership && (
            <Box width="45%">
              <Typography variant="h6" color="textSecondary">
                Users
              </Typography>
            </Box>
          )}
        </Box>
        {Object.values(selectedGroups).map((group) => (
          <SNListItem key={group.id}>
            <Box display="flex" alignItems="center" width="55%">
              <Box
                borderRadius="50%"
                bgcolor="primary.main"
                width={32}
                height={32}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={2}
              >
                <Group />
              </Box>
              <Typography variant="h5">{group.name}</Typography>
            </Box>
            {showProjectAccess && (
              <Box display="flex" alignItems="center" width="45%">
                <GroupProjectAccess id={group.id} />
              </Box>
            )}
            {showGroupMembership && (
              <Box display="flex" alignItems="center" width="45%">
                <GroupMembership id={group.id} />
              </Box>
            )}
            <DialogCloseButton
              onClose={handleRemoveGroup(group.id)}
              spacing={12}
            />
          </SNListItem>
        ))}
      </Box>
      {Object.keys(selectedGroups).length === 0 && <SNListItem showEmpty />}
    </Box>
  );
};

export default GroupsToAddSelector;
