import React from 'react';
import { Box } from '@mui/system';
import ListSelectionControl from '../ListSelectionControl';
import SNPagination from '../SNPagination';
import SelectableListToolbar from '../SelectableListToolbar';
import ToolbarActionGroup from '../ToolbarActionGroup';
import { Grid } from '@mui/material';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import ListSearchControl from '../ListSearchControl';
import { useDebounce } from '../../hooks';
import SNTable from '../SNTable';
import { useLinkTypesQuery } from '../../queries/useLinkTypesQuery';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { LinkType, LinkTypesQuery } from '../../types/new/graphql';
import LinkTypesTableRow from '../LinkTypeTableRow/LinkTypeTableRow';
import { useSetRecoilState } from 'recoil';
import { pageIdsAtom } from '../../atoms/listSelection';
import EditIcon from '@mui/icons-material/Edit';

const LinkTypesTableHeaders = ['Name', 'Description', 'Fields'];

const LinkTypesTable = () => {
  const pageSize = DEFAULT_PAGE_SIZE;
  const [searchTerm, setSearchTerm] = React.useState('');
  const _debouncedSearchTerm = useDebounce(searchTerm, 200);
  const setPageIds = useSetRecoilState(pageIdsAtom);

  const {
    data: linkTypesData,
    loading,
    fetchMore,
  } = useLinkTypesQuery({
    onCompleted: (res: LinkTypesQuery) => {
      setPageIds(
        res?.linkTypes?.map((type) => {
          return type.id;
        }) || [],
      );
    },
  });

  const linkTypes: LinkType[] = React.useMemo(() => {
    return linkTypesData?.linkTypes || [];
  }, [linkTypesData]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            loading={loading}
            fetchMore={fetchMore}
            pageSize={pageSize}
            pageTotal={linkTypes?.length || 0}
            totalCount={linkTypes?.length || 0}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <SNButton startIcon={<AddIcon />} snVariant="text" disabled>
                  New Link Type
                </SNButton>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <SNButton startIcon={<EditIcon />} snVariant="text" disabled>
                  Edit Link Type
                </SNButton>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        headers={LinkTypesTableHeaders}
        hasResults={!!linkTypes}
        id="link-type-table"
        loading={false}
        rowCount={linkTypes?.length || 0}
        selectable
      >
        {linkTypes?.map((type: LinkType, index: number) => {
          return (
            <LinkTypesTableRow key={type.id} linkType={type} index={index} />
          );
        })}
      </SNTable>
    </Box>
  );
};

export default LinkTypesTable;
