import { Box, Dialog, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import DeleteIcon from '@mui/icons-material/Delete';
import SNPagination from '../SNPagination';
import SNTable from '../SNTable';
import ListSelectionControl from '../ListSelectionControl';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useParams } from 'react-router';
import { useGetListQuery } from '../../queries';
import ListItemsTableRow from '../ListItemsTableRow';
import SNButton from '../SNButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ToolbarActionGroup from '../ToolbarActionGroup';
import ListSearchControl from '../ListSearchControl';
import { useDebounce } from '../../hooks';
import AddIcon from '@mui/icons-material/Add';
import AddTermToListModal from '../AddTermToListModal';
import { useDeleteListItemMutation } from '../../mutations/useDeleteListItemMutation';
import GeneralConfirmationModalProps from '../GeneralConfirmationModal';

const headers = ['value', 'description', 'created at', 'updated at'];

const ListItemsTable = () => {
  const pageSize = 10;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const { id } = useParams<{ id: string }>();
  const [warningModelOpen, setWarningModalOpen] = React.useState(false);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [addToListModalOpen, setAddToListModalOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { data, loading, error, fetchMore, refetch } = useGetListQuery({
    variables: {
      id: id ? id : '',
      filter: { searchTerm: debouncedSearchTerm },
    },
    onCompleted: (result) => {
      setPageIds(
        result?.getControlledVocabularyCategory?.data?.items?.edges.map(
          (edge) => edge.node.id,
        ) || [],
      );
    },
    onError: (e) => {
      setGeneralError({ message: e.message, details: e.message, open: true });
    },
  });
  const [deleteItems] = useDeleteListItemMutation({
    variables: { input: { categoryId: id ? id : '', ids: selectedIds } },
    onCompleted: () => {
      resetSelectedIds();
    },
    onError: (e) => {
      setGeneralError({ message: e.message, details: e.message, open: true });
    },
  });

  const items = React.useMemo(
    () =>
      data?.getControlledVocabularyCategory?.data?.items?.edges.map(
        (edge) => edge.node,
      ),
    [data],
  );

  const handleDeleteTerm = () => {
    deleteItems().then(() => {
      refetch();
    });
  };

  const closeAddModal = () => {
    setAddToListModalOpen(false);
    refetch();
  };

  const handleBackClick = React.useCallback(() => {
    history.back();
  }, []);

  const pageTotal = items?.length || 0;

  const selectedItemNames = React.useMemo(() => {
    return items
      ? items
          .filter((item) => {
            return selectedIds.includes(item.id);
          })
          .map((list) => list.value)
          .join(',\n ')
      : '';
  }, [selectedIds, items]);

  if (!id) {
    return null;
  }

  return (
    <Box>
      <Dialog
        fullWidth
        open={warningModelOpen}
        onClose={() => setWarningModalOpen(false)}
      >
        <GeneralConfirmationModalProps
          onClose={() => setWarningModalOpen(false)}
          onProceed={handleDeleteTerm}
          message={`Are you sure you want to permanently delete the following terms: ${selectedItemNames}`}
        />
      </Dialog>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Box mt={1} px={4}>
          <Box my={1} ml={-1}>
            <SNButton
              snVariant="underline"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon fontSize="small" />}
            >
              Back
            </SNButton>
          </Box>

          {loading ? (
            <Skeleton width={200} />
          ) : (
            <Box>
              <Box flexDirection="column" mb={1}>
                <Typography variant="h1">
                  {data?.getControlledVocabularyCategory?.data?.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h5" color="textSecondary">
                  {data?.getControlledVocabularyCategory?.data?.description}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.getControlledVocabularyCategory?.data?.items?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={
              data?.getControlledVocabularyCategory?.data?.items?.totalCount
            }
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item>
              <Dialog
                fullWidth
                open={addToListModalOpen}
                onClose={closeAddModal}
              >
                <AddTermToListModal
                  onClose={closeAddModal}
                  listId={id}
                  listName={
                    data?.getControlledVocabularyCategory?.data?.name || ''
                  }
                />
              </Dialog>
              <SNButton
                startIcon={<AddIcon />}
                snVariant="text"
                onClick={() => setAddToListModalOpen(true)}
              >
                Add Term
              </SNButton>
            </Grid>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count > 0}
            >
              <Grid item>
                <SNButton
                  startIcon={<DeleteIcon />}
                  snVariant="destructive"
                  onClick={() => setWarningModalOpen(true)}
                >
                  Delete Term
                </SNButton>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!items}
        headers={headers}
        id="list-items"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {items?.map((item, index) => (
          <ListItemsTableRow
            description={item.description}
            key={item.id}
            index={index}
            value={item.value}
            id={item.id}
            updatedAt={item.updatedAt}
            createdAt={item.createdAt}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default ListItemsTable;
