import {
  DataPoint,
  DataType,
  SearchFilterValueType,
} from '../../types/new/graphql';
import React from 'react';
import SNDialogTitle from '../SNDialogTitle';
import { Box } from '@mui/system';
import SNInput from '../SNInput';
import {
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
} from '@mui/material';
import SNButton from '../SNButton';

interface DataPointCreateEditModalProps {
  dataPoints?: DataPoint[];
  onClose: () => void;
  pointId: string;
}

const DataPointCreateEditModal = ({
  dataPoints,
  onClose,
  pointId,
}: DataPointCreateEditModalProps) => {
  const dataPoint = dataPoints?.find(
    (point) => {
      return point.id === pointId;
    },
    [dataPoints, pointId],
  );

  const defaultDataPoint = {
    name: '',
    description: '',
    id: pointId,
    dataType: DataType.STRING,
    UIType: SearchFilterValueType.STRING,
  };

  const [workingDataPoint, setWorkingDataPoint] = React.useState<DataPoint>(
    dataPoint || defaultDataPoint,
  );

  const dataTypes = Object.values(DataType);

  const handleTextboxChange = (e: any) => {
    setWorkingDataPoint((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleDataTypeSelect = (type: string) => {
    setWorkingDataPoint((prev) => {
      return {
        ...prev,
        dataType: type as DataType,
      };
    });
  };

  const handleDataPointSave = () => {
    console.log('AIRHORN! data type saved!');
    console.log(workingDataPoint);
  };

  return (
    <>
      <SNDialogTitle onClose={onClose}>
        {workingDataPoint?.name
          ? `Data Point: ${workingDataPoint.name}`
          : 'New Data Point'}
      </SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <SNInput
            value={workingDataPoint?.name}
            label="Name"
            name="name"
            onChange={handleTextboxChange}
          />
          <SNInput
            value={workingDataPoint?.description}
            label="Description"
            name="description"
            onChange={handleTextboxChange}
          />
          <SNInput value={workingDataPoint.dataType} label="Data Type" select>
            {dataTypes.map((type) => {
              return (
                <MenuItem
                  key={type}
                  onClick={() => handleDataTypeSelect(type)}
                  value={type}
                >
                  <Typography>{type}</Typography>
                </MenuItem>
              );
            })}
          </SNInput>
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleDataPointSave}>Save</SNButton>
      </DialogActions>
    </>
  );
};

export default DataPointCreateEditModal;
