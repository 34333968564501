import React, { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { useDebounce } from '../../hooks';
import { useLazyUsersPaginatedQuery } from '../../queries/useUsersPaginatedQuery';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '../Autocomplete';
import {
  UserRole,
  Users_users_data_edges_node as UserOption,
  UserStatus,
} from '../../types/schemaTypes';
import UserAvatar from '../UserAvatar';
import RoleDropdown from '../RoleDropdown';
import SNInput from '../SNInput';
import { useUserState } from '../../contexts';
import SNListItem from '../SNListItem';
import DialogCloseButton from '../DialogCloseButton';
import { collectErrors } from '../../utils/collectErrors';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

export type SelectedUsers = Record<string, UserOption>;

interface UsersToAddSelectorProps {
  selectedUsers: SelectedUsers;
  setSelectedUsers: React.Dispatch<React.SetStateAction<SelectedUsers>>;
  showRole?: boolean;
  addCurrentUser?: boolean;
}

const noOptions: UserOption[] = [];

const UsersToAddSelector = ({
  selectedUsers,
  setSelectedUsers,
  addCurrentUser,
  showRole,
}: UsersToAddSelectorProps) => {
  const pageSize = 10;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [value, setValue] = React.useState<UserOption | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const user = useUserState();
  const [getUsers, { data }] = useLazyUsersPaginatedQuery();

  React.useEffect(() => {
    getUsers({
      variables: {
        first: pageSize,
        filter: {
          searchTerm: debouncedSearchTerm,
          status: UserStatus.ACTIVE,
        },
      },
    });
  }, [debouncedSearchTerm, pageSize, getUsers]);

  const options = React.useMemo(
    () =>
      data?.users?.data?.edges
        .map((edge) => edge.node)
        .filter((node) => !Object.keys(selectedUsers).includes(node.id)) ||
      noOptions,
    [data?.users?.data?.edges, selectedUsers],
  );

  const handleInputChange = (_event: ChangeEvent<unknown>, value: string) => {
    setSearchTerm(value);
  };

  const handleUserSelect = React.useCallback(
    (_event: ChangeEvent<unknown>, value: UserOption | null) => {
      setSearchTerm('');
      setValue(null);
      if (value !== null) {
        setSelectedUsers((previous) => {
          return {
            ...previous,
            [value?.id]: value,
          };
        });
      }
    },
    [setSelectedUsers],
  );

  const handleChangeUserRole = React.useCallback(
    (userId: string, role: UserRole) => {
      setSelectedUsers((previous) => {
        return {
          ...previous,
          [userId]: { ...previous[userId], role: role },
        };
      });
    },
    [setSelectedUsers],
  );

  const handleRemoveUser = React.useCallback(
    (userId: string) => () => {
      const { [userId]: removed, ...newUsers } = selectedUsers;
      setSelectedUsers(newUsers);
    },
    [setSelectedUsers, selectedUsers],
  );

  const filterOptions = React.useCallback(
    (options: UserOption[]) =>
      addCurrentUser
        ? options.filter((option) => option.id !== user.userId)
        : options,
    [addCurrentUser, user.userId],
  );

  const getOptionLabel = React.useCallback(() => '', []);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.users?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Unable to fetch users',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box display="flex" flexDirection="column">
      <Autocomplete
        multiple={false}
        freeSolo={false}
        onChange={handleUserSelect}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => (
          <Box
            {...props}
            component="li"
            key={option.id}
            display="flex"
            sx={{ whiteSpace: 'nowrap' }}
            overflow="hidden"
          >
            <Box>{option.name}</Box>
          </Box>
        )}
        renderInput={({ InputProps, InputLabelProps, ...params }) => (
          <SNInput
            {...InputProps}
            {...params}
            className=""
            label="Users"
            placeholder="Search for Users"
            startAdornment={
              <Box display="flex" ml={1}>
                <SearchIcon />
              </Box>
            }
          />
        )}
      />

      <Box mt={2}>
        <Box display="flex">
          <Box width="55%">
            <Typography variant="h6" color="textSecondary">
              Name
            </Typography>
          </Box>
          {showRole && (
            <Box width="45%">
              <Typography variant="h6" color="textSecondary">
                Role
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {addCurrentUser && (
        <SNListItem key={user.userId}>
          <Box display="flex" alignItems="center" width="55%">
            <Box pr={2}>
              <UserAvatar name={user.name} picture={user.picture} size={32} />
            </Box>
            <Typography variant="h5">{user.name}</Typography>
          </Box>
          <Box display="flex" alignItems="center" width="45%">
            {showRole && (
              <RoleDropdown
                currentRole={UserRole.OWNER}
                id={user.userId}
                disabled
              />
            )}
          </Box>
        </SNListItem>
      )}
      {Object.values(selectedUsers).map((user) => (
        <SNListItem key={user.id}>
          <Box display="flex" alignItems="center" width="55%">
            <Box pr={2}>
              <UserAvatar
                size={32}
                name={user.name || ''}
                picture={user.picture || ''}
              />
            </Box>
            <Typography variant="h5">{user.name}</Typography>
          </Box>
          {showRole && (
            <Box display="flex" alignItems="center" width="45%">
              <RoleDropdown
                currentRole={user.defaultRole}
                id={user.id}
                onRoleChange={handleChangeUserRole}
              />
            </Box>
          )}

          <DialogCloseButton onClose={handleRemoveUser(user.id)} spacing={12} />
        </SNListItem>
      ))}
      {!addCurrentUser && Object.keys(selectedUsers).length === 0 && (
        <SNListItem showEmpty />
      )}
    </Box>
  );
};

export default UsersToAddSelector;
