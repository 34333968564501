import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  ControlledVocabularyCategories,
  ControlledVocabularyCategoriesVariables,
} from '../types/schemaTypes';

export const LISTS = gql`
  query ControlledVocabularyCategories(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: CVCategoryFilter
  ) {
    getControlledVocabularyCategories(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          endIndex @client
          startIndex @client
        }
        edges {
          cursor
          node {
            id
            name
            description
            updatedAt
            createdAt
            listEndpoint
            items(first: 2) {
              edges {
                cursor
                node {
                  id
                  value
                }
              }
              totalCount
            }
          }
        }
      }
      errors
    }
  }
`;

export const useGetListsQuery = (
  options: QueryHookOptions<
    ControlledVocabularyCategories,
    ControlledVocabularyCategoriesVariables
  >,
) => {
  return useQuery<
    ControlledVocabularyCategories,
    ControlledVocabularyCategoriesVariables
  >(LISTS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
