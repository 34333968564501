import React from 'react';
import { Location } from 'history';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { BlockerFunction, useNavigate } from 'react-router-dom';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';
import ReactRouterPrompt from 'react-router-prompt';

interface SearchSavePromptProps {
  message: string;
  onNavigate: () => void;
  preventPush?: boolean;
  primary: string;
  shouldBlockNavigation: (location: Location) => boolean;
  title: string;
  when?: boolean | undefined;
}

const SearchSavePrompt: React.FC<SearchSavePromptProps> = ({
  message,
  onNavigate,
  preventPush,
  primary,
  shouldBlockNavigation,
  title,
  when,
}) => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockNavigation: BlockerFunction = (location) => {
    if (location) {
      if (
        when &&
        !confirmedNavigation &&
        shouldBlockNavigation(location.nextLocation)
      ) {
        setModalVisible(true);
        setLastLocation(location.nextLocation);
        return true;
      }
    }
    return false;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  const nav = React.useCallback(
    (path: string) => {
      onNavigate();
      if (!preventPush) {
        navigate(path);
      }
    },
    [onNavigate, preventPush, navigate],
  );
  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      nav(lastLocation.pathname);
    }
  }, [nav, confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <ReactRouterPrompt when={handleBlockNavigation}>
        {() => {
          return (
            <Dialog open={modalVisible} onClose={closeModal}>
              <SNDialogTitle onClose={closeModal}>{title}</SNDialogTitle>
              <DialogContent>
                <DialogContentText>{message}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <SNButton onClick={closeModal}>Cancel</SNButton>
                <SNButton
                  snVariant="primary"
                  onClick={handleConfirmNavigationClick}
                >
                  {primary}
                </SNButton>
              </DialogActions>
            </Dialog>
          );
        }}
      </ReactRouterPrompt>
    </>
  );
};

export default SearchSavePrompt;
