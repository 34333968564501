import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import { useParams } from 'react-router';
import { useAddUsersToProjectsMutation } from '../../mutations/useAddUsersToProjects';
import ProjectsToAddSelector from '../ProjectsToAddSelector';
import { SelectedProjects } from '../ProjectsToAddSelector/ProjectsToAddSelector';
import { useUserQuery } from '../../queries';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { collectErrors } from '../../utils/collectErrors';

interface AddUsersToProjectFormProps {
  onClose: () => void;
}

const AddProjectsToUserForm = ({ onClose }: AddUsersToProjectFormProps) => {
  const { id: userId } = useParams<{ id: string | undefined }>();
  const { data } = useUserQuery({ variables: { id: userId || '' } });
  const [selectedProjects, setSelectedProjects] =
    React.useState<SelectedProjects>({});
  const [addUsersToProject, { data: usersAddedData }] =
    useAddUsersToProjectsMutation({
      refetchQueries: ['ProjectsOfUser'],
      onCompleted: () => {
        onClose();
      },
    });

  const handleAddUsersToProject = React.useCallback(() => {
    const grants = Object.values(selectedProjects).map((project) => {
      return {
        userId: userId || '',
        projectId: project.id,
        userRole: project.role,
      };
    });
    addUsersToProject({ variables: { input: { grants } } });
  }, [addUsersToProject, selectedProjects, userId]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([usersAddedData?.grantUsersAccessToProjects?.errors]);
  }, [usersAddedData]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Add User to Projects</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <ProjectsToAddSelector
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          defaultRole={data?.user?.user?.defaultRole}
          showRole
        />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddUsersToProject}
          disabled={Object.keys(selectedProjects).length === 0}
        >
          Save
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddProjectsToUserForm;
