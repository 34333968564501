import React from 'react';
import { Box } from '@mui/system';
import {
  PossibleAddedValidation,
  WorkingAreaValidations,
} from './EntityTypeDetailsFieldValidationsModal';
import SNInput from '../SNInput';
import {
  FieldDefinition,
  UniquenessValidation,
} from '../../types/newEntityTypeTypes';
import { IconButton, Typography } from '@mui/material';
import Autocomplete from '../Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface UniqueFieldValidationFormProps {
  setAddedValidation: React.Dispatch<
    React.SetStateAction<WorkingAreaValidations>
  >;
  addedValidation: PossibleAddedValidation;
  fields: FieldDefinition[];
}

const UniqueFieldValidationForm = ({
  addedValidation,
  setAddedValidation,
  fields,
}: UniqueFieldValidationFormProps) => {
  const currentValues = React.useMemo(() => {
    return addedValidation.validation as UniquenessValidation;
  }, [addedValidation]);
  const [scopeInput, setScopeInput] = React.useState('');

  const filteredOptions = React.useMemo(() => {
    if (currentValues.scope) {
      return fields.filter((field) => {
        return !currentValues.scope.includes(field.id);
      });
    } else {
      return fields;
    }
  }, [currentValues, fields]);

  const handleScopeInputChange = (e: any) => {
    if (e) {
      setScopeInput(e.target.value);
    }
  };

  const handleDeleteScope = (scopeId: string) => {
    setAddedValidation((prev) => {
      const indexToDelete = currentValues.scope.indexOf(scopeId);
      const newScope = currentValues.scope.toSpliced(indexToDelete, 1);
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            scope: [...newScope],
          },
        },
      };
    });
  };

  const handleSelectScope = (e: any, value: FieldDefinition | null) => {
    if (value) {
      setAddedValidation((prev) => {
        const oldScopes = currentValues.scope || [];
        return {
          ...prev,
          [addedValidation.id]: {
            ...addedValidation,
            validation: {
              ...currentValues,
              scope: [...oldScopes, value?.id],
            },
          },
        };
      });
    }
    setScopeInput('');
  };

  const handleChange = (e: any) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  return (
    <Box width="100%">
      <Box my={2}>
        <Typography color="textSecondary">
          Unique validations ensure that a value in this column is unique within
          an optional scope
        </Typography>
      </Box>
      <Box m={1}>
        <Autocomplete
          multiple={false}
          freeSolo={false}
          onChange={handleSelectScope}
          onInputChange={handleScopeInputChange}
          inputValue={scopeInput}
          options={filteredOptions}
          getOptionLabel={(option) => option?.id.split('.')[1] || ''}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              key={option?.id}
              display="flex"
              sx={{ whiteSpace: 'nowrap' }}
              overflow="hidden"
            >
              <Box>{option?.id.split('.')[1]}</Box>
              <Box
                pl={1}
                overflow="hidden"
                textOverflow="ellipsis"
                color="text.secondary"
              >
                <em>{option?.description}</em>
              </Box>
            </Box>
          )}
          renderInput={({ InputProps, InputLabelProps, ...params }) => (
            <SNInput
              {...InputProps}
              {...params}
              className=""
              autoFocus
              label="Unique among which columns?"
              placeholder="Search for column"
              startAdornment={
                <Box display="flex" ml={1}>
                  <SearchIcon />
                </Box>
              }
            />
          )}
        />
      </Box>
      {currentValues.scope && currentValues.scope.length > 0 && (
        <Box ml={1}>
          <Typography color="textSecondary">Selected columns:</Typography>
        </Box>
      )}
      <Box width="100%" display="flex" flexWrap="wrap">
        {currentValues.scope?.map((scope) => {
          return (
            <Box
              bgcolor="grey.900"
              borderRadius={3}
              key={scope}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              m={1}
              px={1}
              minWidth="15%"
            >
              {scope.split('.')[1]}
              <Box mx={1} />
              <IconButton onClick={() => handleDeleteScope(scope)}>
                <ClearIcon />
              </IconButton>
            </Box>
          );
        })}
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Error Message"
          name="message"
          helperText={
            currentValues.message || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.message}
        />
      </Box>
    </Box>
  );
};

export default UniqueFieldValidationForm;
