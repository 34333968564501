import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  UpdateGroupMutation,
  UpdateGroupMutationVariables,
} from '../types/new/graphql';

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      group {
        id
        name
        description
        groupMembership {
          totalCount
        }
        groupProjectAccess {
          totalCount
        }
      }
      errors
    }
  }
`;

export const useUpdateGroup = (
  options?: MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >,
) => {
  return useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UPDATE_GROUP,
    options,
  );
};
