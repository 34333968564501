import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useParams } from 'react-router';
import { useEntityTypeQuery } from '../../queries';
import DetailTabs from '../DetailTabs';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import EntityTypeDetailsFieldsTable from '../EntityTypeDetailFieldsTable';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SNButton from '../SNButton';
import EntityTypeDetailsRelationshipTable from '../EntityTypeDetailsRelationshipTable';
import SNEditableLabel from '../SNEditableLabel';

const entityTypeDetailTabs = [
  'data types',
  'relationships',
  'complex validations',
];

const EntityTypeDetails = () => {
  const [newEntityTypeInfo, setNewEntityTypeInfo] =
    useRecoilState(newEntityTypeAtom);

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const [testError, setTestError] = React.useState<undefined | boolean>(
    undefined,
  );

  const resetNewEntityType = useResetRecoilState(newEntityTypeAtom);

  const { data: existingEntityTypeData, loading: existingEntityTypeLoading } =
    useEntityTypeQuery({
      variables: { id: id as string },
      onCompleted: (res) => {
        if (res.entityType) {
          //if the id in the url is a valid one, load the existing entity type into our recoil atom for editing.
          setNewEntityTypeInfo(res?.entityType?.rawDefinition);
        }
      },
    });

  const handleTestTypeClick = () => {
    setTestError(Math.round(Math.random()) === 0 ? false : true);
  };

  const getStartIcon = React.useCallback(() => {
    if (testError === undefined) {
      return <RadioButtonUncheckedIcon />;
    } else if (testError) {
      return <ErrorOutlineIcon color="error" />;
    } else {
      return <CheckCircleOutlineIcon color="success" />;
    }
  }, [testError]);

  const handleNameSave = (value: string) => {
    setNewEntityTypeInfo((prev) => {
      return {
        ...prev,
        name: value,
      };
    });
  };
  const handleDescriptionSave = (value: string) => {
    setNewEntityTypeInfo((prev) => {
      return {
        ...prev,
        description: value,
      };
    });
  };

  const entityTitle: ReactNode = (
    <Box display="flex" justifyContent="space-between" width="100%">
      {existingEntityTypeLoading ? null : (
        <Box>
          <SNEditableLabel
            value={
              newEntityTypeInfo?.name ||
              existingEntityTypeData?.entityType?.name ||
              ''
            }
            name="name"
            onSave={handleNameSave}
            subtitle={false}
          />
          <SNEditableLabel
            value={
              newEntityTypeInfo?.description ||
              existingEntityTypeData?.entityType?.description ||
              ''
            }
            onSave={handleDescriptionSave}
            subtitle
            name="description"
          />
        </Box>
      )}
      <Box display="flex">
        <Box pr={2}>
          <SNButton
            snVariant="text"
            startIcon={getStartIcon()}
            onClick={handleTestTypeClick}
          >
            Test
          </SNButton>
        </Box>
        <SNButton snVariant="confirm" disabled={testError || false}>
          Finalize
        </SNButton>
      </Box>
    </Box>
  );

  if (id === undefined) {
    return null;
  }

  const handleGoBack = () => {
    resetNewEntityType();
    navigate('/settings/entity-types');
  };

  return (
    <DetailTabs
      baseRoute="settings/entity-type"
      id={id}
      loading={existingEntityTypeLoading}
      onBack={handleGoBack}
      tabs={entityTypeDetailTabs}
      title={entityTitle}
    >
      <Routes>
        <Route path="data-types" element={<EntityTypeDetailsFieldsTable />} />
        <Route
          path="relationships"
          element={<EntityTypeDetailsRelationshipTable />}
        />
        <Route
          path="complex-validations"
          element={
            <Box
              width="100%"
              height="80vh"
              display="flex"
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" color="textSecondary">
                No Results
              </Typography>
            </Box>
          }
        />
        <Route path="*" element={<Navigate to="data-types" replace />} />
      </Routes>
    </DetailTabs>
  );
};

export default EntityTypeDetails;
