import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import SNTableRow from '../SNTableRow';
import { LinkType } from '../../types/new/graphql';
import { Link as RouterLink } from 'react-router-dom';

interface LinkTypesTableRowProps {
  linkType: LinkType;
  index: number;
}

const LinkTypesTableRow = ({ linkType, index }: LinkTypesTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));
  const id = linkType.id;

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const fields = linkType.fields.map((fields) => {
    return fields.name;
  });

  return (
    <SNTableRow selected={selected} key={linkType.id}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Box display="flex" alignItems="center" height={56}>
          <Link to={`/settings/link-types/${id}/info`} component={RouterLink}>
            <Box fontWeight="bold">
              <Typography>{linkType.name}</Typography>
            </Box>
          </Link>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Link to={`/settings/link-types/${id}/info`} component={RouterLink}>
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{linkType.description}</Typography>
            </Box>
          </Link>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Link to={`/settings/link-types/${id}/info`} component={RouterLink}>
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{fields}</Typography>
            </Box>
          </Link>
        </Box>
      </td>
    </SNTableRow>
  );
};

export default LinkTypesTableRow;
