import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/system';
import SNInput from '../SNInput';
import { NumericInputOptions } from '../../types/newEntityTypeTypes';
import { PossibleInputOptions } from '../EntityTypeDetailInputOptionsModal/EntityTypeDetailInputOptionsModal';

interface EntityTypeFieldTextInputOptionsFormProps {
  setInputOptions: Dispatch<SetStateAction<PossibleInputOptions>>;
  inputOptions: NumericInputOptions;
  setDefaultValue: Dispatch<SetStateAction<number | string | boolean>>;
  defaultValue: string | number | boolean;
}

const EntityTypeFieldNumberInputOptionsForm = ({
  setInputOptions,
  inputOptions,
  defaultValue,
  setDefaultValue,
}: EntityTypeFieldTextInputOptionsFormProps) => {
  const onMaxChange = (e: any) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        maxValue: e.target.value,
      };
    });
  };
  const onMinChange = (e: any) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        minValue: e.target.value,
      };
    });
  };
  const onPrecisionChange = (e: any) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        precision: e.target.value,
      };
    });
  };
  const onDefaultChange = (e: any) => {
    setDefaultValue(e.target.value);
  };

  return (
    <Box>
      The following options will determine specifics for how this numeric column
      must be filled in. fill out as many or as few of the options as you
      prefer.
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <SNInput
          value={inputOptions.minValue}
          type="number"
          label="Minimum"
          onChange={onMinChange}
        />
        <SNInput
          value={inputOptions.maxValue}
          type="number"
          label="Maximum"
          onChange={onMaxChange}
        />
        <SNInput
          value={inputOptions.precision}
          type="number"
          label="Precision"
          onChange={onPrecisionChange}
        />
        <SNInput
          value={defaultValue}
          type="number"
          label="Default Value"
          onChange={onDefaultChange}
        />
      </Box>
    </Box>
  );
};

export default EntityTypeFieldNumberInputOptionsForm;
