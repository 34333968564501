import React from 'react';
import { Box, Typography } from '@mui/material';
import SNButton from '../SNButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CreateIcon from '@mui/icons-material/Create';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublishIcon from '@mui/icons-material/Publish';
import LinkIcon from '@mui/icons-material/Link';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import styled from '../../services/styled';
import UserAvatar from '../UserAvatar';
import {
  EntityHistory_entityHistory_edges_node,
  EntityHistoryEventType,
} from '../../types/schemaTypes';
import HistoryEventDetail from '../HistoryEventDetail';
import moment from 'moment/moment';
import { Collapse } from '@mui/material';
import { Maybe } from '../../types';

interface HistoryEventCardProps {
  first: boolean;
  last: boolean;
  eventId: string | undefined;
  event: Maybe<EntityHistory_entityHistory_edges_node>;
}
const TimelineHolder = styled(Box)`
  display: flex;
  justify-content: center;
  margin-left: 40px;
  width: 50px;
`;
interface TimelineProps {
  first?: boolean;
  last?: boolean;
}
const TimelineLarge = styled(Box)<TimelineProps>`
  position: relative;
  width: 2px;
  height: calc(100% - 48px);
  background: ${(p) =>
    p.last ? 'transparent' : p.theme.palette.primary.light};
`;
const TimelineSmall = styled(Box)<TimelineProps>`
  position: relative;
  width: 2px;
  height: 48px;
  background: ${(p) =>
    p.first ? 'transparent' : p.theme.palette.primary.light};
`;
const CardBase = styled(Box)`
  width: 70%;
  min-height: 100px;
  background: ${(p) => p.theme.palette.background.paper};
  display: flex;
  flex-direction: column;
`;
const EventIcon = styled(Box)`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  background: ${(p) => p.theme.palette.primary.light};
  z-index: 1;
`;

const HistoryEventCard = ({
  event,
  first,
  last,
  eventId = '',
}: HistoryEventCardProps) => {
  const [open, setOpen] = React.useState(false);

  const getTypeIcon = (type: EntityHistoryEventType) => {
    switch (type) {
      case EntityHistoryEventType.EDIT:
        return <CreateIcon />;
      case EntityHistoryEventType.APPROVAL:
        return <CheckCircleIcon />;
      case EntityHistoryEventType.CREATE:
        return <PublishIcon />;
      case EntityHistoryEventType.LINKING:
        return <LinkIcon />;
      case EntityHistoryEventType.DELETE:
        return <DeleteForeverIcon />;
      default:
        return <PublishIcon />;
    }
  };
  const getTypeName = (type: EntityHistoryEventType) => {
    switch (type) {
      case EntityHistoryEventType.EDIT:
        return 'Edited';
      case EntityHistoryEventType.APPROVAL:
        return 'Approved';
      case EntityHistoryEventType.CREATE:
        return 'Uploaded';
      case EntityHistoryEventType.LINKING:
        return 'Linked';
      case EntityHistoryEventType.DELETE:
        return 'Deleted';
      default:
        return 'Uploaded';
    }
  };

  if (event === null || event === undefined) {
    return null;
  }

  return (
    <Box display="flex" width="100%">
      <TimelineHolder>
        <Box display="flex" flexDirection="column">
          <TimelineSmall first={first} />
          <TimelineLarge last={last} />
        </Box>
        <EventIcon mt={4}>{getTypeIcon(event.eventType)}</EventIcon>
      </TimelineHolder>
      <CardBase p={2} m={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box pr={2}>
              <UserAvatar
                name={event.user.id}
                picture={
                  event.user.picture === null ? undefined : event.user.picture
                }
                size={32}
              />
            </Box>
            <Typography color="textPrimary" variant="h3">
              {getTypeName(event.eventType)} by {event.user.name}
            </Typography>
          </Box>
          <Typography color="textSecondary">
            {moment(event.updatedAt).format('YYYY-MM-DD hh:mm')}
          </Typography>
        </Box>
        <Collapse in={open}>
          <Box mt={2} p={1} ml={5}>
            {event?.changes?.map((change, index) => {
              return (
                <HistoryEventDetail
                  change={change}
                  key={`${eventId}_${index}`}
                />
              );
            })}
          </Box>
        </Collapse>
        <Box mt={2} ml={4}>
          <SNButton
            snVariant="text"
            onClick={() => setOpen(!open)}
            endIcon={open ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}
          >
            {open ? 'Hide Details' : 'Show Details'}
          </SNButton>
        </Box>
      </CardBase>
    </Box>
  );
};

export default HistoryEventCard;
