import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedTotalSelector } from '../../atoms/listSelection';
import useShiftKeyDown from '../../hooks/useShiftKeyDown';
import SNToolbar from '../SNToolbar';

interface SelectableListToolbarProps extends PropsWithChildren {
  isEditing?: boolean;
}

const SelectableListToolbar: React.FC<SelectableListToolbarProps> = ({
  isEditing,
  children,
}) => {
  useShiftKeyDown();
  const selectedRowsCount = useRecoilValue(selectedTotalSelector);
  const background = React.useMemo(() => {
    if (isEditing) {
      return 'primary.main';
    }
    if (selectedRowsCount > 0) {
      return 'grey.700';
    }
    return 'grey.900';
  }, [isEditing, selectedRowsCount]);

  return (
    <SNToolbar
      bgcolor={background}
      borderColor={isEditing ? 'transparent' : 'grey.600'}
    >
      {children}
    </SNToolbar>
  );
};

export default SelectableListToolbar;
