import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExtensionIcon from '../ExtensionIcon';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  uploadFilesByIdAtom,
  uploadMetadataByIdAtom,
  uploadSubsetsByIdAtom,
} from '../../atoms/uploadFiles';

const UploadModalFileList = () => {
  const [filesById, setUploadFiles] = useRecoilState(uploadFilesByIdAtom);
  const setUploadMetadata = useSetRecoilState(uploadMetadataByIdAtom);
  const setUploadSubsets = useSetRecoilState(uploadSubsetsByIdAtom);

  const handleFileDelete = React.useCallback(
    (fileId: string) => () => {
      setUploadFiles((prevState) => {
        const { [fileId]: omit, ...rest } = prevState;
        return rest;
      });
      setUploadMetadata((prevState) => {
        const { [fileId]: omit, ...rest } = prevState;
        return rest;
      });
      setUploadSubsets((prevState) => {
        const { [fileId]: omit, ...rest } = prevState;
        return rest;
      });
    },
    [setUploadFiles, setUploadMetadata, setUploadSubsets],
  );

  return (
    <Box py={3}>
      {Object.entries(filesById).map(([fileId, file]) => {
        return (
          <Box
            key={fileId}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              height={44}
            >
              <Box display="flex" alignItems="center">
                <Box pr={2} display="flex" alignItems="center">
                  <ExtensionIcon fileName={file.name} />
                </Box>
                <Typography variant="h5">{file.name}</Typography>
              </Box>
              <IconButton onClick={handleFileDelete(fileId)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default UploadModalFileList;
