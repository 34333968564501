import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { SearchSaveAdd, SearchSaveEdit } from '../../components/SearchSave';
import SNButton from '../../components/SNButton';
import {
  searchTreeActions,
  TabOverflowProvider,
  useSaveSearchDispatch,
  useSearchTreeDispatch,
  useUserState,
} from '../../contexts';
import { useGetSavedSearchQuery } from '../../queries';
import styled from '../../services/styled';
import { searchQueryTransform } from '../../utils';
import SearchContainer from '../SearchContainer';
import { IconMenu } from '../../components';
import LoadingOverlay from '../../components/LoadingOverlay';
import {
  useShowFiltersDispatch,
  useShowFiltersState,
} from '../../contexts/ShowFiltersContext';
import SearchFilterToggle from '../../components/SearchFilterToggle';
import { unsavedSearchVar } from '../../localState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ScreenWidthAtom } from '../../atoms/screenWidth';
import SNDialogTitle from '../../components/SNDialogTitle';
import { latestSearchAtom } from '../../atoms/latestSearchAtom';

const SearchToolbarButton = styled(SNButton)`
  min-width: 150px;
`;

const SearchEdit = () => {
  const [localName, setLocalName] = React.useState('');
  const { id } = useParams<{ id: string }>();
  const setSaveSearchState = useSaveSearchDispatch();
  const dispatch = useSearchTreeDispatch();
  const user = useUserState();
  const navigate = useNavigate();
  const screenWidth = useRecoilValue(ScreenWidthAtom);
  const showFilters = useShowFiltersState();
  const setShowFilters = useShowFiltersDispatch();
  const setLatestSearch = useSetRecoilState(latestSearchAtom);

  const { data, loading } = useGetSavedSearchQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: id ? id : '',
    },
  });

  React.useEffect(() => {
    if (data?.getSavedSearch.data?.name) {
      setLocalName(data.getSavedSearch.data.name);
    }
  }, [data?.getSavedSearch.data?.name]);

  React.useEffect(() => {
    if (data?.getSavedSearch.data?.searchQuery) {
      const { branches, roots } = searchQueryTransform(
        data.getSavedSearch.data.searchQuery,
      );
      dispatch(
        searchTreeActions.tree.set({
          key: id ? id : '',
          branches,
          roots,
        }),
      );
      unsavedSearchVar(false);
      setLatestSearch(id ? id : '');
    }
  }, [data?.getSavedSearch.data?.searchQuery, dispatch, id, setLatestSearch]);

  const overwritePermission = React.useMemo(() => {
    if (data?.getSavedSearch.data?.user) {
      return data.getSavedSearch.data.user.id === user.userId;
    }
  }, [data, user]);

  const notFound = Boolean(
    (!loading && !data?.getSavedSearch?.data) ||
      (data?.getSavedSearch?.errors && data.getSavedSearch.errors.length > 0),
  );

  const handleSaveClick = React.useCallback(() => {
    setSaveSearchState('SAVE');
  }, [setSaveSearchState]);

  const handleSaveAsClick = React.useCallback(() => {
    setSaveSearchState('SAVE_AS');
  }, [setSaveSearchState]);

  const handleSaveComplete = React.useCallback(() => {
    unsavedSearchVar(false);
  }, []);

  if (!id) {
    return null;
  }

  return (
    <Box position="relative">
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        py={2}
        px={4}
      >
        <Typography variant="h1">{localName}</Typography>
        <Box>
          <Grid container spacing={1}>
            {screenWidth > 1160 && (
              <>
                <Grid item>
                  <SearchFilterToggle />
                </Grid>
                {overwritePermission && (
                  <Grid item>
                    <SearchToolbarButton
                      snVariant="text"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                    >
                      Save Search
                    </SearchToolbarButton>
                  </Grid>
                )}
              </>
            )}
            <Grid item>
              <IconMenu
                orientation="horizontal"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    width: 142,
                  },
                }}
              >
                {overwritePermission && screenWidth < 1160 && (
                  <MenuItem onClick={handleSaveClick}>Save Search</MenuItem>
                )}
                <MenuItem onClick={handleSaveAsClick}>Save As...</MenuItem>
                {screenWidth < 1160 && (
                  <MenuItem
                    onClick={() => setShowFilters((prevState) => !prevState)}
                  >
                    {showFilters ? 'Hide' : 'Show'} filters
                  </MenuItem>
                )}
              </IconMenu>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <TabOverflowProvider>
        <SearchContainer searchId={id} />
      </TabOverflowProvider>
      <LoadingOverlay isLoading={loading} />
      <SearchSaveEdit
        id={id}
        onSaveComplete={handleSaveComplete}
        searchId={id}
        title="Save Search"
      />
      <SearchSaveAdd
        defaultName={`${localName} - copy`}
        defaultDescription={data?.getSavedSearch.data?.description || undefined}
        onSaveComplete={handleSaveComplete}
        searchId={id}
        title="Save Search As"
      />
      <Dialog open={notFound} fullWidth maxWidth="sm">
        <SNDialogTitle>Search Not Found</SNDialogTitle>
        <DialogContent>
          {data?.getSavedSearch?.errors?.map((error) => (
            <Typography key={error}>{error}</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <SNButton onClick={() => navigate(-1)}>Go Back</SNButton>
          <SNButton snVariant="primary" onClick={() => navigate('/search/new')}>
            New Search
          </SNButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SearchEdit;
