import { Box } from '@mui/system';
import {
  AddedLinkableType,
  WorkingFieldRefsByLinkableType,
  WorkingLinkableTypes,
} from './EntityTypeDetailRelationshipModal';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  EntityType,
  EntityTypeQuery,
  EntityTypesQuery,
} from '../../types/new/graphql';
import React, { Dispatch, SetStateAction } from 'react';
import Autocomplete from '../Autocomplete';
import SNInput from '../SNInput';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Typography } from '@mui/material';
import SNButton from '../SNButton';
import { FieldReference } from '../../types/newEntityTypeTypes';
import cuid from 'cuid';
import EntityTypeRelationshipModalPairedColumnsForm from './EntityTypeRelationshipModalPairedColumnsForm';

type EntityTypeForEvent = Pick<
  NonNullable<EntityTypeQuery['entityType']>,
  'id'
> | null;

interface EntityTypeRelationshipModalLinkableTypeFormProps {
  onDelete: () => void;
  linkableTypeInfo?: AddedLinkableType;
  setLinkableType: Dispatch<SetStateAction<WorkingLinkableTypes>>;
  setFieldReferences: Dispatch<
    SetStateAction<WorkingFieldRefsByLinkableType[]>
  >;
  entityTypes: EntityTypesQuery['entityTypes'];
  fieldRefsForThisLinkableType: WorkingFieldRefsByLinkableType | undefined;
  entityTypesInUse: string[];
}

const EntityTypeRelationshipModalLinkableTypeForm = ({
  onDelete,
  linkableTypeInfo,
  entityTypes,
  setLinkableType,
  fieldRefsForThisLinkableType,
  setFieldReferences,
  entityTypesInUse,
}: EntityTypeRelationshipModalLinkableTypeFormProps) => {
  const [entityTypeInputValue, setEntityTypeInputValue] = React.useState('');

  const linkableTypeInfoId = React.useMemo(() => {
    return linkableTypeInfo?.id || '';
  }, [linkableTypeInfo]);
  if (!linkableTypeInfoId) {
    return null;
  }

  const defaultFieldRef: FieldReference = {
    local_column: '',
    foreign_column: '',
  };

  const handleDeleteLinkableType = () => {
    onDelete();
  };
  const handleEntityTypeChangeValue = (e: any, value: string) => {
    setEntityTypeInputValue(value);
  };
  const handleEntityTypeSelect = (e: any, value: EntityTypeForEvent) => {
    setLinkableType((prev) => {
      return {
        ...prev,
        [linkableTypeInfoId]: {
          ...prev[linkableTypeInfoId],
          id: prev[linkableTypeInfoId]?.id || '',
          link: {
            ...prev[linkableTypeInfoId]?.link,
            references: prev[linkableTypeInfoId]?.link.references || [],
            relationship: prev[linkableTypeInfoId]?.link.relationship || '',
            target_data_set: value?.id || '',
          },
        },
      };
    });
  };
  const handleAddPairedFields = () => {
    setFieldReferences((prev) => {
      const fieldRefToChange = prev.find((fieldRef) => {
        return fieldRef.linkableTypeId === linkableTypeInfo?.id;
      });
      const otherFieldRefs = prev.filter((fieldRef) => {
        return fieldRef.linkableTypeId !== linkableTypeInfo?.id;
      });
      const newId = cuid();
      return [
        ...otherFieldRefs,
        {
          linkableTypeId: linkableTypeInfo?.id || '',
          fieldRefs: {
            [newId]: {
              id: newId,
              ref: defaultFieldRef,
            },
            ...fieldRefToChange?.fieldRefs,
          },
        },
      ];
    });
  };

  const fieldRefValues = Object.values(
    fieldRefsForThisLinkableType?.fieldRefs || {},
  );

  return (
    <Box bgcolor="grey.900" p={2} mt={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Autocomplete
            multiple={false}
            freeSolo={false}
            onChange={handleEntityTypeSelect}
            inputValue={entityTypeInputValue}
            onInputChange={handleEntityTypeChangeValue}
            value={entityTypes?.find((type) => {
              return type.id === linkableTypeInfo?.link?.target_data_set;
            })}
            options={
              entityTypes?.filter((type) => {
                return !entityTypesInUse.includes(type.id);
              }) || []
            }
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                {...props}
                component="li"
                key={option.name}
                display="flex"
                overflow="hidden"
              >
                <Box>{option.name}</Box>
              </Box>
            )}
            renderInput={({ InputProps, InputLabelProps, ...params }) => (
              <SNInput
                {...InputProps}
                {...params}
                label="Entity Type"
                className=""
                placeholder="Select Entity Type to link to"
                startAdornment={
                  <Box display="flex" ml={1}>
                    <SearchIcon />
                  </Box>
                }
              />
            )}
          />
        </Box>
        <Box mt={-6}>
          <IconButton onClick={handleDeleteLinkableType}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        mt={6}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" color="textSecondary">
          Paired Columns:
        </Typography>
        <Box mt={-2}>
          <SNButton
            onClick={handleAddPairedFields}
            disabled={!linkableTypeInfo?.link?.target_data_set}
          >
            Add Paired columns
          </SNButton>
        </Box>
      </Box>
      <Box>
        {fieldRefValues.map((ref) => {
          return (
            <EntityTypeRelationshipModalPairedColumnsForm
              key={ref?.id}
              linkableTypeId={linkableTypeInfo?.id || ''}
              foreignEntityType={
                entityTypes?.find((type) => {
                  return type.id === linkableTypeInfo?.link?.target_data_set;
                }) as EntityType
              }
              fieldReference={ref}
              setFieldReferences={setFieldReferences}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default EntityTypeRelationshipModalLinkableTypeForm;
