import React from 'react';
import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  ProvideEditorCallback,
} from '@glideapps/glide-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components';
import { drawTextCell } from './CustomCells';
import { AnnotationSeverity } from '../../types/schemaTypes';
import Moment, { Moment as MomentType } from 'moment';

interface DateTimeCellProps {
  readonly kind: 'date-time-cell';
  readonly date: MomentType | undefined;
  readonly displayDate: string;
  readonly displayStatus: AnnotationSeverity | null;
}

type DateTimeCell = CustomCell<DateTimeCellProps>;
export type { DateTimeCell as DateTimeCellType };

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Editor: ReturnType<ProvideEditorCallback<DateTimeCell>> = (p) => {
  const cellData = p.value.data;
  const { date } = cellData;

  return (
    <Wrap>
      <DatePicker
        slotProps={{
          popper: {
            className: 'click-outside-ignore',
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 30],
                  },
                },
              ],
            },
          },
          field: {
            format: 'YYYY-MM-DD',
          },
        }}
        onChange={(date) => {
          p.onChange({
            ...p.value,
            data: {
              ...p.value.data,
              date: date ?? undefined,
            },
          });
        }}
        value={date ?? null}
      />
    </Wrap>
  );
};

const renderer: CustomRenderer<DateTimeCell> = {
  isMatch: (cell: CustomCell): cell is DateTimeCell =>
    (cell.data as any).kind === 'date-time-cell',
  draw: (args, cell) => {
    const { data, readonly } = cell;
    const { displayDate: value, displayStatus: status } = data;
    return drawTextCell(args, { value, status, readonly });
  },
  // eslint-disable-next-line react/display-name
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
  }),
  onPaste: (v, d) => {
    let newDate: MomentType | undefined;
    try {
      newDate = Moment(v, false);
    } catch {
      /* do nothing */
    }

    return {
      ...d,
      date: Number.isNaN(newDate) ? undefined : newDate,
    };
  },
  kind: GridCellKind.Custom,
};

export default renderer;
