import React from 'react';
import { Box } from '@mui/system';
import ListSelectionControl from '../ListSelectionControl';
import SNPagination from '../SNPagination';
import SelectableListToolbar from '../SelectableListToolbar';
import ToolbarActionGroup from '../ToolbarActionGroup';
import { Dialog, Grid } from '@mui/material';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import ListSearchControl from '../ListSearchControl';
import { useDebounce } from '../../hooks';
import SNTable from '../SNTable';
import { useRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import EntityTypeDetailFieldsTableRow from '../EntityTypeDetailFieldsTableRow';
import { useDataPointsQuery } from '../../queries';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DropResult,
} from '@hello-pangea/dnd';
import EntityTypeDetailInputOptionsModal from '../EntityTypeDetailInputOptionsModal';
import EntityTypeDetailsFieldValidationsModal from '../EntityTypeDetailFieldValidationsModal';

const EntityTypeDetailsFieldsTableHeaders = [
  'Reorder',
  'Column Name',
  'Data Type',
  'Description',
  'Validation Rules',
  'Input Options',
];

export interface GenericItemModalOpen {
  columnId?: string;
  open: boolean;
}

const EntityTypeDetailFieldsTable = () => {
  const [entityTypeInfo, setEntityTypeInfo] = useRecoilState(newEntityTypeAtom);

  const [searchTerm, setSearchTerm] = React.useState('');
  const _debouncedSearchTerm = useDebounce(searchTerm, 200);

  const [inputOptionsModalOpenId, setInputOptionsModalOpenId] =
    React.useState<GenericItemModalOpen>({ open: false, columnId: undefined });
  const [fieldValidationModalOpen, setFieldValidationModalOpen] =
    React.useState<GenericItemModalOpen>({ open: false, columnId: undefined });

  // we want to do this once and pass it to the rows, instead of having each row make a network call.
  const { data: dataPointsData } = useDataPointsQuery();

  const handleAddNewColumn = () => {
    setEntityTypeInfo((prev) => {
      const additionalOrdering =
        prev.export_details.export_ordering === undefined
          ? []
          : prev.export_details.export_ordering;
      return {
        ...prev,
        fields: {
          [`new.${prev.id}.`]: {
            id: `new.${prev.id}.`,
            label: '',
            type: 'string',
          },
          ...prev.fields,
        },
        export_details: {
          ...prev.export_details,
          export_ordering: [`new.${prev.id}.`, ...additionalOrdering],
        },
      };
    });
  };

  const currentFields = React.useMemo(() => {
    const fieldObjects = Object.values(entityTypeInfo.fields);

    return fieldObjects.map((field) => {
      return {
        ...field,
      };
    });
  }, [entityTypeInfo]);

  const orderedCurrentFields = React.useMemo(() => {
    const exportArray = entityTypeInfo.export_details.export_ordering;
    if (exportArray === undefined || exportArray?.length === 0) {
      return currentFields;
    } else {
      return currentFields.sort((a, b) => {
        return exportArray?.indexOf(a.id) - exportArray?.indexOf(b.id);
      });
    }
  }, [currentFields, entityTypeInfo]);

  const unnamedFieldExists: boolean = React.useMemo(() => {
    const newFieldArray = Object.keys(entityTypeInfo.fields).map((fieldId) => {
      const idArray = fieldId.split('.');
      return idArray[0] === 'new';
    });
    return newFieldArray.includes(true);
  }, [entityTypeInfo]);

  const handleDragEnd = React.useCallback(
    (e: DropResult) => {
      const dragDestination = e.destination;
      const draggedId = e.draggableId
        .split('-')
        .splice(1, e.draggableId.split('-').length)
        .join('-');

      const orderArray =
        entityTypeInfo.export_details.export_ordering === undefined
          ? []
          : [...entityTypeInfo.export_details.export_ordering];

      orderArray.splice(orderArray.indexOf(draggedId), 1); // remove the id from the array
      orderArray.splice(
        dragDestination?.index || orderArray.indexOf(draggedId),
        0,
        draggedId,
      ); // add it back in at its new home

      setEntityTypeInfo((prev) => {
        return {
          ...prev,
          export_details: {
            ...prev.export_details,
            export_ordering: [...orderArray],
          },
        };
      });
    },
    [entityTypeInfo, setEntityTypeInfo],
  );

  return (
    <Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <SelectableListToolbar>
          <Box display="flex" alignItems="center" pl={2}>
            <Box display="flex" alignItems="center" pr={2}>
              <ListSelectionControl />
            </Box>
            <SNPagination
              loading={false}
              pageSize={15}
              pageTotal={1}
              totalCount={currentFields.length}
            />
          </Box>
          <Box>
            <Grid container spacing={1}>
              <ToolbarActionGroup
                comparisonFunction={(count: number) => count === 0}
              >
                <Grid item>
                  <SNButton
                    startIcon={<AddIcon />}
                    snVariant="text"
                    onClick={handleAddNewColumn}
                    disabled={unnamedFieldExists}
                  >
                    Add Column
                  </SNButton>
                </Grid>
                <Grid item>
                  <ListSearchControl
                    setValue={setSearchTerm}
                    value={searchTerm}
                  />
                </Grid>
              </ToolbarActionGroup>
              <Dialog open={inputOptionsModalOpenId.open} fullWidth>
                <EntityTypeDetailInputOptionsModal
                  onClose={() =>
                    setInputOptionsModalOpenId({
                      open: false,
                      columnId: undefined,
                    })
                  }
                  columnId={inputOptionsModalOpenId.columnId || ''}
                  dataPoints={dataPointsData?.dataPoints || []}
                />
              </Dialog>
              <Dialog open={fieldValidationModalOpen.open} fullWidth>
                <EntityTypeDetailsFieldValidationsModal
                  columnId={fieldValidationModalOpen.columnId || ''}
                  onClose={() => {
                    setFieldValidationModalOpen({
                      columnId: undefined,
                      open: false,
                    });
                  }}
                />
              </Dialog>
            </Grid>
          </Box>
        </SelectableListToolbar>
        <Droppable droppableId="droppable-field-table">
          {(provided, _snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <SNTable
                headers={EntityTypeDetailsFieldsTableHeaders}
                hasResults={orderedCurrentFields.length > 0}
                id="entity-type-fields-table"
                loading={false}
                rowCount={orderedCurrentFields.length}
                selectable
              >
                {orderedCurrentFields.map((field, index) => {
                  return (
                    <Draggable
                      draggableId={`draggable-${field.id}`}
                      index={index}
                      key={field.id}
                    >
                      {(provided, _snapshot) => (
                        <EntityTypeDetailFieldsTableRow
                          key={field.id}
                          setInputOptionsOpenId={setInputOptionsModalOpenId}
                          setValidationsModalOpenId={
                            setFieldValidationModalOpen
                          }
                          dataPoints={dataPointsData}
                          field={field}
                          index={index}
                          dragRef={provided.innerRef}
                          dragProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </SNTable>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default EntityTypeDetailFieldsTable;
