import React, { ReactNode } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SelectProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import { ExpandMore } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { PaletteStatus } from '../../types';
import styled from '../../services/styled';
import { Severity } from '../../types/schemaTypes';

export interface SNInputProps extends InputProps {
  status?: PaletteStatus | Severity;
  helperText?: string | ReactNode;
  label?: string;
  select?: boolean;
  children?: React.ReactNode;
  displayEmpty?: boolean;
  renderValue?: SelectProps['renderValue'];
}
interface StyledInputProps extends InputProps {
  status: SNInputProps['status'];
}

const StyledInput = styled(Input)<StyledInputProps>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ status, theme }) => {
    switch (status) {
      case 'error':
      case Severity.ERROR:
        return theme.palette.error.main;
      case 'info':
      case Severity.INFO:
        return theme.palette.info.main;
      case 'success':
        return theme.palette.success.main;
      case 'warning':
      case Severity.WARNING:
        return theme.palette.warning.main;
      default:
        return theme.palette.grey['600'];
    }
  }};
  :hover {
    background-color: ${({ theme }) => theme.palette.grey['700']};
    border-color: ${({ status, theme }) => {
      switch (status) {
        case 'error':
          return theme.palette.error.light;
        case 'info':
          return theme.palette.info.light;
        case 'success':
          return theme.palette.success.light;
        case 'warning':
          return theme.palette.warning.light;
        default:
          return theme.palette.grey['300'];
      }
    }};
  }
  &.Mui-focused {
    background-color: ${({ theme }) => theme.palette.background.default};
    border-color: ${({ theme }) => theme.palette.primary.main};
    :hover {
      border-color: ${({ theme }) => theme.palette.info.light};
    }
  }
`;

interface StatusIconProps {
  status: SNInputProps['status'];
}
export const StatusIcon = styled(WarningIcon)<StatusIconProps>`
  color: ${(p) => {
    switch (p.status) {
      case 'error':
        return p.theme.palette.error.main;
      case 'info':
        return p.theme.palette.info.main;
      case 'success':
        return p.theme.palette.success.main;
      case 'warning':
        return p.theme.palette.warning.main;
      default:
        return p.theme.palette.grey['300'];
    }
  }};
  :hover {
    color: ${(p) => {
      switch (p.status) {
        case 'error':
          return p.theme.palette.error.light;
        case 'info':
          return p.theme.palette.info.light;
        case 'success':
          return p.theme.palette.success.light;
        case 'warning':
          return p.theme.palette.warning.light;
        default:
          return p.theme.palette.grey['300'];
      }
    }};
  }
`;

const SNInput = React.forwardRef<any, SNInputProps>(
  (
    {
      displayEmpty,
      renderValue,
      helperText,
      label,
      select,
      children,
      value,
      size,
      status,
      ...rest
    },
    ref,
  ) => {
    const InputElement = (
      <StyledInput
        status={status}
        size={size}
        disableUnderline
        aria-describedby={helperText ? `${rest.id}_helperText` : undefined}
        value={value}
        endAdornment={
          status ? (
            <Box mr="7px" display="flex">
              <StatusIcon status={status} />
            </Box>
          ) : undefined
        }
        {...rest}
      />
    );
    return (
      <FormControl fullWidth={rest.fullWidth} ref={ref}>
        {label && (
          <Box
            py={1}
            color={rest.disabled ? 'text.disabled' : 'grey.200'}
            display="flex"
            alignItems="center"
          >
            {rest.required && (
              <Tooltip placement="left" title="Required field">
                <Box mr={1}>
                  <Typography color="error">*</Typography>
                </Box>
              </Tooltip>
            )}
            <Box whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              <FormLabel
                focused={false}
                htmlFor={rest.id}
                id={`${rest.id}-label`}
              >
                {label}
              </FormLabel>
            </Box>
          </Box>
        )}
        {select ? (
          <Select
            aria-describedby={`${rest.id}_helperText`}
            id={rest.id}
            labelId={label ? `${rest.id}-label` : undefined}
            value={value}
            input={InputElement}
            IconComponent={ExpandMore}
            displayEmpty={displayEmpty}
            renderValue={renderValue}
            MenuProps={{
              sx: { marginTop: '1px' },
              transitionDuration: 0,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {children}
          </Select>
        ) : (
          InputElement
        )}

        {helperText && (
          <FormHelperText id={`${rest.id}_helperText`} disabled={rest.disabled}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

SNInput.displayName = 'SNInput';

export default SNInput;
