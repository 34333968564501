import { Box } from '@mui/material';
import React from 'react';
import { useLinkSummaryDetailsQuery } from '../../queries/useLinkSummaryDetailsQuery';
import SelectableListToolbar from '../SelectableListToolbar';
import { useSetRecoilState } from 'recoil';
import { pageIdsAtom } from '../../atoms/listSelection';
import SNPagination from '../SNPagination';
import SNTable from '../SNTable';
import EntityLinksTableRow from './EntityLinksTableRow';
import { useMatch } from 'react-router';
import ListSelectionControl from '../ListSelectionControl';

interface EntityLinksTableProps {
  id: string;
}

const EntityLinksTable: React.FC<EntityLinksTableProps> = ({ id }) => {
  const isReview = useMatch('/review');
  const pageSize = 20;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const { data, loading, error, fetchMore } = useLinkSummaryDetailsQuery({
    variables: { filter: { id }, first: pageSize },
    onCompleted: (result) => {
      setPageIds(
        result?.linksForSummary?.edges.map((edge) => edge.node.id) || [],
      );
    },
  });

  const columns = React.useMemo(
    () => data?.linksForSummary?.linkDefinition.type.fields || [],
    [data],
  );

  const headers = React.useMemo(
    () => ['entity name', ...(columns?.map((column) => column.name) || [])],
    [columns],
  );

  const rows = React.useMemo(
    () => data?.linksForSummary?.edges.map((edge) => edge.node),
    [data],
  );

  const pageTotal = rows?.length || 0;

  return (
    <Box maxHeight="100vh" position="relative">
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.linksForSummary?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.linksForSummary?.totalCount}
          />
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!rows}
        headers={headers}
        id="entity-links"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {rows?.map((row, index) => (
          <EntityLinksTableRow
            key={row.id}
            row={row}
            columns={columns}
            index={index}
            pathPrefix={isReview ? '/review' : '/search'}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default EntityLinksTable;
