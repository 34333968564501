import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useParams } from 'react-router';
import DetailTabs from '../DetailTabs';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { useLinkTypesQuery } from '../../queries/useLinkTypesQuery';
import { LinkType } from '../../types/new/graphql';

const entityTypeDetailTabs = ['info', 'fields'];

const LinkTypeDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [linkType, setLinkType] = React.useState<LinkType | undefined>(
    undefined,
  );

  const navigate = useNavigate();

  const { loading } = useLinkTypesQuery({
    onCompleted: (res) => {
      setLinkType(
        res?.linkTypes?.find((type: LinkType) => type.id === id) as LinkType,
      );
    },
  });

  const LinkTypeTitle: ReactNode = (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box>
        <Typography variant="h1">{linkType?.name}</Typography>
        <Box>
          <Typography variant="h5" color="textSecondary">
            {linkType?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const handleGoBack = () => {
    navigate('/settings/link-types');
  };

  return (
    <DetailTabs
      baseRoute="settings/link-types"
      id={id || ''}
      loading={loading}
      onBack={handleGoBack}
      tabs={entityTypeDetailTabs}
      title={LinkTypeTitle}
    >
      <Routes>
        <Route path="info" element={null} />
        <Route path="fields" element={null} />
        <Route path="*" element={<Navigate to="info" replace />} />
      </Routes>
    </DetailTabs>
  );
};

export default LinkTypeDetails;
