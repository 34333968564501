import React, { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { useDebounce } from '../../hooks';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '../Autocomplete';
import { UserRole } from '../../types/schemaTypes';
import RoleDropdown from '../RoleDropdown';
import SNInput from '../SNInput';
import { useGetLazyProjectsQuery } from '../../queries/useGetProjectsQuery';
import SNListItem from '../SNListItem';
import DialogCloseButton from '../DialogCloseButton';

export type ProjectOption = {
  name: string | null;
  id: string;
  description: string | null;
  role: UserRole;
};

export type SelectedProjects = Record<string, ProjectOption>;

const noOptions: ProjectOption[] = [];

interface ProjectsToAddSelectorProps {
  selectedProjects: SelectedProjects;
  setSelectedProjects: React.Dispatch<React.SetStateAction<SelectedProjects>>;
  defaultRole?: UserRole;
  showRole?: boolean;
}

const ProjectsToAddSelector = ({
  selectedProjects,
  defaultRole = UserRole.GUEST,
  setSelectedProjects,
  showRole = false,
}: ProjectsToAddSelectorProps) => {
  const pageSize = 10;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [value, setValue] = React.useState<ProjectOption | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [getProjects, { data }] = useGetLazyProjectsQuery();

  React.useEffect(() => {
    getProjects({
      variables: {
        first: pageSize,
        filter: {
          searchTerm: debouncedSearchTerm,
        },
      },
    });
  }, [debouncedSearchTerm, pageSize, getProjects]);

  const options = React.useMemo(
    () =>
      data?.projects?.data?.edges
        .map<ProjectOption>((edge) => {
          const { id, name, description } = edge.node;
          return {
            id,
            name,
            description,
            role: defaultRole,
          };
        })
        .filter((node) => !Object.keys(selectedProjects).includes(node.id)) ||
      noOptions,
    [data?.projects?.data?.edges, defaultRole, selectedProjects],
  );

  const handleInputChange = (event: ChangeEvent<unknown>, value: string) => {
    setSearchTerm(value);
  };

  const handleProjectSelect = React.useCallback(
    (_event: ChangeEvent<unknown>, value: ProjectOption | null) => {
      setSearchTerm('');
      setValue(null);
      if (value !== null) {
        setSelectedProjects((previous) => {
          return {
            ...previous,
            [value?.id]: { ...value, role: UserRole[defaultRole] },
          };
        });
      }
    },
    [defaultRole, setSelectedProjects],
  );

  const handleChangeUserRoleOnProject = React.useCallback(
    (projectId: string, role: UserRole) => {
      setSelectedProjects((previous) => {
        return {
          ...previous,
          [projectId]: { ...previous[projectId], role: role },
        };
      });
    },
    [setSelectedProjects],
  );

  const handleRemoveProject = React.useCallback(
    (projectId: string) => () => {
      const { [projectId]: omit, ...newProjects } = selectedProjects;
      setSelectedProjects(newProjects);
    },
    [setSelectedProjects, selectedProjects],
  );

  const filterOptions = React.useCallback(
    (options: ProjectOption[]) => options,
    [],
  );

  const getOptionLabel = React.useCallback(() => '', []);

  return (
    <Box display="flex" flexDirection="column">
      <Autocomplete
        multiple={false}
        freeSolo={false}
        onChange={handleProjectSelect}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
        options={options}
        value={value}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => (
          <Box
            {...props}
            component="li"
            key={option.id}
            display="flex"
            sx={{ whiteSpace: 'nowrap' }}
            overflow="hidden"
          >
            <Box>{option.name}</Box>
            <Box
              pl={1}
              overflow="hidden"
              textOverflow="ellipsis"
              color="text.secondary"
            >
              <em>{option.description}</em>
            </Box>
          </Box>
        )}
        renderInput={({ InputProps, InputLabelProps, ...params }) => (
          <SNInput
            {...InputProps}
            {...params}
            className=""
            autoFocus
            label="Projects"
            placeholder="Search for Projects"
            startAdornment={
              <Box display="flex" ml={1}>
                <SearchIcon />
              </Box>
            }
          />
        )}
      />
      <Box mt={2}>
        <Box display="flex">
          <Box width="55%">
            <Typography variant="h6" color="textSecondary">
              Selected Projects
            </Typography>
          </Box>
          {showRole && (
            <Box width="45%">
              <Typography variant="h6" color="textSecondary">
                Role on Project
              </Typography>
            </Box>
          )}
        </Box>
        {Object.values(selectedProjects).map((project) => (
          <SNListItem key={project.id}>
            <Box display="flex" alignItems="center" width="55%">
              <Typography variant="h5">{project.name}</Typography>
            </Box>
            {showRole && (
              <Box display="flex" alignItems="center" width="45%">
                <RoleDropdown
                  currentRole={UserRole[defaultRole]}
                  id={project.id}
                  onRoleChange={handleChangeUserRoleOnProject}
                />
              </Box>
            )}
            <DialogCloseButton
              onClose={handleRemoveProject(project.id)}
              spacing={12}
            />
          </SNListItem>
        ))}
        {Object.keys(selectedProjects).length === 0 && <SNListItem showEmpty />}
      </Box>
    </Box>
  );
};

export default ProjectsToAddSelector;
