import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import SNInput from '../SNInput';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

interface SNEditableLabelProps {
  value: string;
  onSave: (value: string) => void;
  subtitle?: boolean;
  placeholder?: string;
  name?: string;
}

const SNEditableLabel = ({
  value,
  onSave,
  subtitle = false,
  name = '',
  placeholder = 'Type something here',
}: SNEditableLabelProps) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputValue(e.target.value);
  };

  const handleSave = () => {
    setEditing(false);
    onSave(inputValue);
  };

  return (
    <Box display="flex" alignItems="center">
      {editing ? (
        <SNInput
          value={inputValue}
          onChange={handleInputChange}
          name={name}
          placeholder={placeholder}
        />
      ) : (
        <Typography
          variant={subtitle ? 'h5' : 'h1'}
          color={subtitle ? 'textSecondary' : 'textPrimary'}
        >
          {value || name?.toUpperCase()}
        </Typography>
      )}
      <Box ml={2}>
        {editing ? (
          <IconButton onClick={handleSave}>
            <CheckIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true);
            }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default SNEditableLabel;
