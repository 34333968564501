import {
  Navigate,
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useEntityDetailsQuery } from '../../queries/useEntityDetailsQuery';
import DetailTabs from '../../components/DetailTabs';
import { EditEntitiesProvider } from '../../contexts';
import EntityData from '../../components/EntityData';
import EntityHistory from '../../components/EntityHistory';
import EntityLinkSummaryTabs from '../../components/EntityLinkSummaryTabs';
import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import EntityDetailStatusBadge from '../../components/EntityDetailStatusBadge';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useSetRecoilState } from 'recoil';

const entityDetailTabs = ['data', 'history', 'links'];

const EntityDetails = () => {
  const navigation = useNavigate();
  const { id } = useParams<{ id: string; tab: string }>();
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const { data, loading } = useEntityDetailsQuery({
    variables: { id: id ? id : '' },
  });
  const { pathname } = useLocation();
  const isReviewRoute = React.useMemo(
    () => pathname.includes('review'),
    [pathname],
  );

  const entityTitle: ReactNode = (
    <Box>
      <Box>
        <Typography variant="h1">
          {data?.entityById?.data?.displayName}
        </Typography>
      </Box>
      <Box my={1} display="flex" alignItems="center">
        <Typography variant="h5">Entity Type: &nbsp;&nbsp;</Typography>
        <Typography variant="h5" color="textSecondary">
          {data?.entityById?.data?.type.name}
        </Typography>
        <EntityDetailStatusBadge
          entityStatus={data?.entityById?.data?.metadata.status.state}
        />
      </Box>
    </Box>
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entityById?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching this entity',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  if (id === undefined) {
    return null;
  }

  return (
    <DetailTabs
      baseRoute={isReviewRoute ? 'review/entity' : 'search/entity'}
      id={id}
      loading={loading}
      onBack={() => navigation(isReviewRoute ? '/review' : '/search')}
      tabs={entityDetailTabs}
      title={
        data?.entityById?.data?.displayName && data?.entityById?.data?.type.name
          ? entityTitle
          : 'Entity Not Found'
      }
    >
      <Routes>
        <Route
          path="data"
          element={
            <EditEntitiesProvider>
              <EntityData id={id} />
            </EditEntitiesProvider>
          }
        />
        <Route path="history" element={<EntityHistory />} />
        <Route path="links" element={<EntityLinkSummaryTabs id={id} />} />
        <Route
          path="data"
          element={
            <EditEntitiesProvider>
              <EntityData id={id} />
            </EditEntitiesProvider>
          }
        />
        <Route path="history" element={<EntityHistory />} />
        <Route path="links" element={<EntityLinkSummaryTabs id={id} />} />
        <Route
          path="*"
          element={
            <Navigate
              to={
                isReviewRoute
                  ? `/review/entity/${id}/data`
                  : `/search/entity/${id}/data`
              }
            />
          }
        />
      </Routes>
    </DetailTabs>
  );
};

export default EntityDetails;
