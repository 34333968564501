import React from 'react';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ProjectsTable from '../../components/ProjectsTable';
import GroupsTable from '../../components/GroupsTable';
import UsersTable from '../../components/UsersTable';
import SNTabs from '../../components/SNTabs';
import ListsTable from '../../components/ListsTable';
import EntityTypesTable from '../../components/EntityTypesTable';
import * as changeCase from 'change-case';
import DataPointsTable from '../../components/DataPointsTable';
import LinkTypesTable from '../../components/LinkTypesTable';

const settingTabsTypesArray = [
  'projects',
  'users',
  'groups',
  'lists',
  'entity types',
  'Data Points',
  'Link Types',
];

const SettingsTabs = () => {
  const { tab = '' } = useParams<{ tab?: string }>();
  const navigation = useNavigate();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      navigation(`/settings/${changeCase.kebabCase(tab)}`);
    },
    [navigation],
  );

  return (
    <div>
      <SNTabs
        active={tab}
        onTabClick={handleTabClick}
        tabs={settingTabsTypesArray}
      />
      <Routes>
        <Route index path="projects" element={<ProjectsTable />} />
        <Route path="users" element={<UsersTable />} />
        <Route path="groups" element={<GroupsTable />} />
        <Route path="lists" element={<ListsTable />} />
        <Route path="entity-types" element={<EntityTypesTable />} />
        <Route path="data-points" element={<DataPointsTable />} />
        <Route path="link-types" element={<LinkTypesTable />} />
        <Route path="*" element={<Navigate to="projects" />} />
      </Routes>
    </div>
  );
};

export default SettingsTabs;
