import React from 'react';
import { Box } from '@mui/system';
import {
  PossibleAddedValidation,
  WorkingAreaValidations,
} from './EntityTypeDetailsFieldValidationsModal';
import SNInput from '../SNInput';
import { MatchValidation } from '../../types/newEntityTypeTypes';
import { Typography } from '@mui/material';

interface MatchFieldValidationFormProps {
  setAddedValidation: React.Dispatch<
    React.SetStateAction<WorkingAreaValidations>
  >;
  addedValidation: PossibleAddedValidation;
}

const MatchFieldValidationForm = ({
  addedValidation,
  setAddedValidation,
}: MatchFieldValidationFormProps) => {
  const currentValues = React.useMemo(() => {
    return addedValidation.validation as MatchValidation;
  }, [addedValidation]);

  const handleChange = (e: any) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  return (
    <Box>
      <Box my={2}>
        <Typography color="textSecondary">
          A match validation compares the column value to a regular expression
          pattern
        </Typography>
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Regex Pattern"
          name="pattern"
          helperText={
            currentValues.pattern || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.pattern}
        />
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Error Message"
          name="message"
          helperText={
            currentValues.message || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.message}
        />
      </Box>
    </Box>
  );
};

export default MatchFieldValidationForm;
