import { Box, Grid } from '@mui/material';
import React from 'react';
import SearchByEntity from '../../components/SearchByEntity/SearchByEntity';
import SNTab from '../../components/SNTab';
import { SavedSearchType } from '../../types/schemaTypes';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SavedSearchesTable from '../../components/SavedSearchesTable';
import { useParams } from 'react-router';

const DataViews = () => {
  const { tab = 'presets' } = useParams<{ tab?: string }>();
  const navigate = useNavigate();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      navigate(`/data-views/${tab}`);
    },
    [navigate],
  );

  const handleSearchSelect = React.useCallback(
    (id: string) => {
      navigate(`/search/${id}`);
    },
    [navigate],
  );

  return (
    <div>
      <Box
        border={1}
        borderTop={0}
        borderRight={0}
        borderLeft={0}
        borderColor="grey.600"
        width="100%"
      >
        <Box px={4} display="flex" mb="-1px">
          <Grid container spacing={4}>
            <Grid item>
              <SNTab
                active={tab}
                onClick={handleTabClick('presets')}
                value="presets"
              >
                Presets
              </SNTab>
            </Grid>
            <Grid item>
              <SNTab active={tab} onClick={handleTabClick('mine')} value="mine">
                My Data Views
              </SNTab>
            </Grid>
            <Grid item>
              <SNTab
                active={tab}
                onClick={handleTabClick('public')}
                value="public"
              >
                Public Data Views
              </SNTab>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Routes>
        <Route
          path="presets"
          element={
            <Box pt={4}>
              <SearchByEntity target="search-new" />
            </Box>
          }
        />
        <Route
          path="mine"
          element={
            <SavedSearchesTable
              onSearchSelect={handleSearchSelect}
              savedSearchType={SavedSearchType.SEARCH}
            />
          }
        />
        <Route
          path="public"
          element={
            <SavedSearchesTable
              isPublic
              onSearchSelect={handleSearchSelect}
              savedSearchType={SavedSearchType.SEARCH}
            />
          }
        />
        <Route path="*" element={<Navigate to="presets" />} />
      </Routes>
    </div>
  );
};

export default DataViews;
