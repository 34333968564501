import React, { ChangeEvent, SyntheticEvent } from 'react';
import { Box } from '@mui/system';
import SNDialogTitle from '../SNDialogTitle';
import {
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import {
  MatchValidation,
  MemberOfValidation,
  NumericValidation,
  OneOfValidation,
  PresenceValidation,
  UniquenessValidation,
} from '../../types/newEntityTypeTypes';
import SNButton from '../SNButton';
import CollapsePanel from '../CollapsePanel';
import { useRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import SNInput from '../SNInput';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '../Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import cuid from 'cuid';
import SNTooltip from '../SNTooltip';
import OneOfFieldValidationForm from './OneOfFieldValidationForm';
import NumericFieldValidationForm from './NumericFieldValidationForm';
import PresenceFieldValidationForm from './PresenceFieldValidationForm';
import MemberOfFieldValidationForm from './MemberOfFieldValidationForm';
import MatchFieldValidationForm from './MatchFieldValidationForm';
import UniqueFieldValidationForm from './UniqueFieldValidationForm';
import NestedBorderBox from '../NestedBorderBox';

interface FinalValidationsContainer {
  match?: MatchValidation;
  present?: PresenceValidation | boolean;
  numeric?: NumericValidation;
  one_of?: OneOfValidation;
  member_of?: MemberOfValidation;
  unique?: UniquenessValidation | boolean;
}

interface TypeToNameMapType {
  [key: string]: string;
}
const typeToNameMap: TypeToNameMapType = {
  match: 'Match Validation',
  present: 'Presence Validation',
  numeric: 'Numeric Validation',
  one_of: "'One Of' Validation",
  member_of: "'Member Of' Validation",
  unique: 'Unique Validation',
};

type AnyValidation =
  | boolean
  | MatchValidation
  | PresenceValidation
  | NumericValidation
  | OneOfValidation
  | MemberOfValidation
  | UniquenessValidation;

export interface PossibleAddedValidation {
  id: string;
  open: boolean;
  name: string;
  inputError?: boolean;
  showInputErrors?: boolean;
  validation: AnyValidation;
}

export interface WorkingAreaValidations {
  [key: string]: PossibleAddedValidation;
}

interface EntityTypeDetailsFieldValidationsModalProps {
  onClose: () => void;
  columnId: string;
}

interface ValidationOption {
  name: string;
  description: string;
}

// validation type guards
const isOneOfValidation = (
  validation: AnyValidation,
): validation is OneOfValidation =>
  typeof validation === 'object' && 'terms' in validation;

const isMemberOfValidation = (
  validation: AnyValidation,
): validation is MemberOfValidation =>
  typeof validation === 'object' && 'ontology' in validation;

const isNumericValidation = (
  validation: AnyValidation,
): validation is NumericValidation =>
  typeof validation === 'object' && 'integer' in validation;

const isMatchValidation = (
  validation: AnyValidation,
): validation is MatchValidation =>
  typeof validation === 'object' && 'pattern' in validation;

const isUniquenessValidation = (
  validation: AnyValidation,
): validation is UniquenessValidation =>
  typeof validation === 'object' && 'scope' in validation;

const isPresenceValidation = (
  validation: AnyValidation,
): validation is PresenceValidation =>
  typeof validation === 'object' && 'message' in validation;

const EntityTypeDetailsFieldValidationsModal = ({
  onClose,
  columnId,
}: EntityTypeDetailsFieldValidationsModalProps) => {
  const [entityTypeInfo, setEntityTypeInfo] = useRecoilState(newEntityTypeAtom);
  const [validationInput, setValidationInput] = React.useState('');
  const [selectedValidation, setSelectedValidation] =
    React.useState<ValidationOption | null>(null);
  const columnInfo = React.useMemo(() => {
    return entityTypeInfo.fields[columnId || ''];
  }, [entityTypeInfo, columnId]);

  // this is all to turn the single possible validations of each type into a format useful for displaying multiple validations of each type
  // we do this because while we only support single validations per type NOW, we want to support multiple of each type in the future.
  const rawExistingValidations = columnInfo?.validations || {};
  const existingKeys = Object.keys(rawExistingValidations);
  const existingValues = Object.values(rawExistingValidations);
  const existingIntermediaryValidations = existingKeys.map((key, index) => {
    const tempId = cuid();
    return {
      [tempId]: {
        name: typeToNameMap[key as string],
        id: tempId,
        open: false,
        validation: existingValues[index],
      },
    };
  });

  const existingValidations: WorkingAreaValidations = {};
  existingIntermediaryValidations.forEach((intermediary) => {
    const id = Object.keys(intermediary)[0];
    existingValidations[id] = { ...intermediary[id] };
  });

  const [addedValidations, setAddedValidations] =
    React.useState<WorkingAreaValidations>(existingValidations || {});

  const allOptions = React.useMemo(() => {
    return [
      {
        name: 'Match Validation',
        description:
          'A match validation compares the column value to a regular expression pattern',
      },
      {
        name: 'Presence Validation',
        description:
          'Presence validations ensures that this column has a value',
      },
      {
        name: 'Numeric Validation',
        description:
          'Numeric validations ensure that a column is a number and between specific thresholds',
      },
      {
        name: "'One Of' Validation",
        description: "'One of' validations check a value against a set list",
      },
      {
        name: "'Member Of' Validation",
        description:
          "'Member Of' validations check a value against an ontology graph",
      },
      {
        name: 'Unique Validation',
        description:
          'Unique validations ensure that a value is unique within an optional scope',
      },
    ];
  }, []);

  // to allow multiple validations of each type, simply make filteredOptions = allOptions.
  const filteredOptions = React.useMemo(() => {
    return allOptions.filter((option) => {
      const addedNames = Object.values(addedValidations).map(
        (val: PossibleAddedValidation) => {
          return val.name;
        },
      );
      return !addedNames.includes(option.name);
    });
  }, [allOptions, addedValidations]);

  const handleValidationInputChange = (
    event: ChangeEvent<object>,
    value: string,
  ) => {
    setValidationInput(value);
  };

  const handleSelectValidation = (
    _event: SyntheticEvent<Element, Event>,
    value: ValidationOption | null,
  ) => {
    setValidationInput('');
    setSelectedValidation(value);
  };

  const getValidationDefaults = (validationName: string | undefined) => {
    switch (validationName) {
      case "'One Of' Validation":
        return {
          message: '',
          terms: [],
        };
      case "'Member Of' Validation":
        return {
          message: '',
          ontology: '',
          depth: 0,
          parent_field: '',
        };
      case 'Numeric Validation':
        return {
          message: '',
          integer: false,
          max: null,
          min: null,
          max_inclusive: true,
          min_inclusive: true,
        };
      case 'Unique Validation':
        return {
          message: 'This field must be unique',
          scope: [],
        };
      case 'Match Validation':
        return {
          message: '',
          pattern: '',
        };
      case 'Presence Validation':
      default:
        return {
          message: 'This field is required',
        };
    }
  };

  const handleAddValidation = () => {
    setAddedValidations((prev) => {
      const newId = cuid();
      const validationToAdd = {
        id: newId,
        open: true,
        inputError: false,
        showInputErrors: false,
        name: selectedValidation?.name,
        validation: getValidationDefaults(selectedValidation?.name),
      };
      return {
        ...prev,
        [newId as keyof WorkingAreaValidations]:
          validationToAdd as PossibleAddedValidation,
      };
    });
    setValidationInput('');
    setSelectedValidation(null);
  };

  const deleteValidationButton = (
    validationId: string,
    setAddedValidations: React.Dispatch<
      React.SetStateAction<WorkingAreaValidations>
    >,
  ) => {
    const handleDeleteClick = () => {
      setAddedValidations((prev) => {
        const rest = { ...prev };
        delete rest[validationId];
        return rest;
      });
    };

    return (
      <SNTooltip title="Delete Validation?">
        <Box>
          <IconButton onClick={handleDeleteClick}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      </SNTooltip>
    );
  };

  const addedValidationKeys = React.useMemo(() => {
    return Object.keys(addedValidations);
  }, [addedValidations]);
  const addedValidationValues = React.useMemo(() => {
    return Object.values(addedValidations);
  }, [addedValidations]);

  const getValidationForm = (index: number) => {
    switch (addedValidationValues[index].name) {
      case "'One Of' Validation":
        return (
          <OneOfFieldValidationForm
            addedValidation={addedValidationValues[index]}
            setAddedValidation={setAddedValidations}
          />
        );
      case "'Member Of' Validation":
        return (
          <MemberOfFieldValidationForm
            addedValidation={addedValidationValues[index]}
            setAddedValidation={setAddedValidations}
            fields={Object.values(entityTypeInfo.fields)}
          />
        );
      case 'Presence Validation':
        // if the presence validation exists, but it's the boolean false,
        // indicating its presence isn't required, return nothing.
        if (!addedValidationValues[index].validation) {
          return null;
        } else {
          return (
            <PresenceFieldValidationForm
              addedValidation={addedValidationValues[index]}
              setAddedValidation={setAddedValidations}
            />
          );
        }
      case 'Numeric Validation':
        return (
          <NumericFieldValidationForm
            addedValidation={addedValidationValues[index]}
            setAddedValidation={setAddedValidations}
          />
        );
      case 'Unique Validation':
        // if the uniqueness validation exists, but it's the boolean false,
        // indicating it need not be unique, return nothing.
        if (!addedValidationValues[index].validation) {
          return null;
        } else {
          return (
            <UniqueFieldValidationForm
              addedValidation={addedValidationValues[index]}
              setAddedValidation={setAddedValidations}
              fields={Object.values(entityTypeInfo.fields)}
            />
          );
        }
      case 'Match Validation':
        return (
          <MatchFieldValidationForm
            addedValidation={addedValidationValues[index]}
            setAddedValidation={setAddedValidations}
          />
        );
    }
  };

  const checkValidationInput = (validation: PossibleAddedValidation) => {
    const validationInput = validation.validation;
    if (isOneOfValidation(validationInput)) {
      return !!(
        validationInput.terms &&
        validationInput.terms.length > 0 &&
        validationInput.message &&
        validationInput.message.length > 0
      );
    } else if (isNumericValidation(validationInput)) {
      return !!(validationInput.message && validationInput.message.length > 0);
    } else if (isMemberOfValidation(validationInput)) {
      return !!(
        validationInput.message &&
        validationInput.message.length > 0 &&
        validationInput.ontology
      );
    } else if (isUniquenessValidation(validationInput)) {
      return !!(validationInput.message && validationInput.message.length > 0);
    } else if (isMatchValidation(validationInput)) {
      return !!(
        validationInput.message &&
        validationInput.message.length > 0 &&
        validationInput.pattern
      );
    } else if (isPresenceValidation(validationInput)) {
      return !!(validationInput.message && validationInput.message.length > 0);
    } else {
      return false;
    }
  };

  // when we transition to doing an arbitrary number of all validations, this function will have to be re-written
  const handleSave = () => {
    const validInputs = addedValidationValues.map((validation) => {
      return checkValidationInput(validation);
    });
    if (!validInputs.includes(false)) {
      const matchValidation = addedValidationValues.find((validation) => {
        return validation.name === 'Match Validation';
      })?.validation as MatchValidation;
      const uniqueValidation = addedValidationValues.find((validation) => {
        return validation.name === 'Unique Validation';
      })?.validation as UniquenessValidation;
      const numericValidation = addedValidationValues.find((validation) => {
        return validation.name === 'Numeric Validation';
      })?.validation as NumericValidation;
      const presenceValidation = addedValidationValues.find((validation) => {
        return validation.name === 'Presence Validation';
      })?.validation as PresenceValidation;
      const memberOfValidation = addedValidationValues.find((validation) => {
        return validation.name === "'Member Of' Validation";
      })?.validation as MemberOfValidation;
      const oneOfValidation = addedValidationValues.find((validation) => {
        return validation.name === "'One Of' Validation";
      })?.validation as OneOfValidation;
      // we assign the validations this way because otherwise id have to put undefined in the ones where there are no validations,
      // and that messes up the form re-hydrating process when we reopen the modal for editing later
      const finalValidations: FinalValidationsContainer = {};
      if (matchValidation) {
        finalValidations.match = matchValidation;
      }
      if (uniqueValidation) {
        finalValidations.unique = uniqueValidation;
      }
      if (numericValidation) {
        finalValidations.numeric = numericValidation;
      }
      if (presenceValidation) {
        finalValidations.present = presenceValidation;
      }
      if (memberOfValidation) {
        finalValidations.member_of = memberOfValidation;
      }
      if (oneOfValidation) {
        finalValidations.one_of = oneOfValidation;
      }

      setEntityTypeInfo((prev) => {
        return {
          ...prev,
          fields: {
            ...prev.fields,
            [columnId]: {
              ...prev.fields[columnId],
              showInputErrors: true,
              validations: finalValidations,
            },
          },
        };
      });
      onClose();
    } else {
      // if one or more of the inputs is invalid set the inputError field for those validations
      setAddedValidations((_prev) => {
        const validationsWithErrorsAdded = addedValidationValues.map(
          (validation, index) => {
            return {
              ...validation,
              inputError: !validInputs[index],
              showInputErrors: true,
            };
          },
        );
        const checkedValidationsAsObjects: WorkingAreaValidations = {};
        addedValidationKeys.map((validationKey, index) => {
          checkedValidationsAsObjects[validationKey] =
            validationsWithErrorsAdded[index];
        });
        return checkedValidationsAsObjects;
      });
    }
  };

  return (
    <>
      <SNDialogTitle onClose={onClose}>
        <Typography variant="h2">
          {entityTypeInfo?.fields[columnId]?.label
            ? `Validations for ${entityTypeInfo?.fields[columnId]?.label}`
            : 'Validations for unnamed column'}
        </Typography>
      </SNDialogTitle>
      <DialogContent>
        <Typography>
          Validations are a set of rules that control the kinds of data that may
          be entered in this field by users. Validations are also used to
          determine what entity type is being uploaded during an upload and if
          the entity in question has any errors. Choose and add a validation
          from the list below to get started.
        </Typography>
        <Box display="flex" justifyContent="space-around" my={3}>
          <Autocomplete
            multiple={false}
            freeSolo={false}
            onChange={handleSelectValidation}
            inputValue={validationInput}
            onInputChange={handleValidationInputChange}
            value={selectedValidation}
            options={filteredOptions}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                {...props}
                component="li"
                key={option.name}
                display="flex"
                flexDirection="column"
                overflow="hidden"
              >
                <Box>{option.name}</Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: '.6rem' }}
                    color="textSecondary"
                  >
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={({ InputProps, InputLabelProps, ...params }) => (
              <SNInput
                {...InputProps}
                {...params}
                className=""
                placeholder="Select Validation Type"
                startAdornment={
                  <Box display="flex" ml={1}>
                    <SearchIcon />
                  </Box>
                }
              />
            )}
          />
          <SNButton
            disabled={!selectedValidation}
            onClick={handleAddValidation}
            snVariant="primary"
          >
            Add Validation
          </SNButton>
        </Box>

        <Box display="flex" flexDirection="column" my={3}>
          {addedValidationKeys?.map((validation, index) => {
            // check for false booleans in validations, indicating
            // 'allowed to be absent' or 'allowed to not be unique'
            if (!addedValidationValues[index].validation) {
              return null;
            }
            return (
              <Box key={addedValidationValues[index].id}>
                <Box my={1}>
                  <CollapsePanel
                    name={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={460}
                      >
                        <Typography
                          variant="h6"
                          color={
                            addedValidationValues[index].inputError
                              ? 'error'
                              : 'textPrimary'
                          }
                        >
                          {addedValidationValues[index].name}
                        </Typography>
                        <Box>
                          {deleteValidationButton(
                            addedValidationValues[index].id,
                            setAddedValidations,
                          )}
                        </Box>
                      </Box>
                    }
                    open={addedValidationValues[index].open}
                  >
                    <NestedBorderBox pl={2} py={2} depth={1}>
                      {getValidationForm(index)}
                    </NestedBorderBox>
                  </CollapsePanel>
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <SNButton snVariant="primary" onClick={handleSave}>
            Save
          </SNButton>
        </Box>
      </DialogActions>
    </>
  );
};

export default EntityTypeDetailsFieldValidationsModal;
