import { Box, capitalize, Grid } from '@mui/material';
import SNTab from '../SNTab';
import * as changeCase from 'change-case';

interface SNTabsProps {
  active: string;
  onTabClick: (tab: string) => () => void;
  tabs: string[];
}

const SNTabs = ({ active, onTabClick, tabs }: SNTabsProps) => {
  return (
    <Box
      border={1}
      borderTop={0}
      borderRight={0}
      borderLeft={0}
      borderColor="grey.600"
      width="100%"
    >
      <Box px={4} display="flex" mb="-1px">
        <Grid container spacing={4}>
          {tabs.map((tab) => (
            <Grid item key={tab}>
              <SNTab
                active={active}
                onClick={onTabClick(tab)}
                value={changeCase.kebabCase(tab)}
              >
                {capitalize(tab)}
              </SNTab>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SNTabs;
