import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import EntityHistoryToolbar from '../EntityHistoryToolbar';
import { useEntityHistoryQuery } from '../../queries/useEntityHistoryQuery';
import LoadingOverlay from '../LoadingOverlay';
import ErrorResult from '../ErrorResult';
import HistoryEventCard from '../HistoryEventCard';
import { EntityHistoryEventType, SortDirection } from '../../types/schemaTypes';
import { useParams } from 'react-router-dom';
import { useDebounce } from '../../hooks';
import ScrollingContainer from '../ScrollingContainer';

const EntityHistory = () => {
  const { id } = useParams<{ id: string }>();
  const [textFilter, setTextFilter] = React.useState('');
  const debouncedTextFilter = useDebounce(textFilter, 300);
  const [historyDirection, setHistoryDirection] = React.useState<SortDirection>(
    SortDirection.DESC,
  );
  const [eventTypes, setEventTypes] = React.useState<EntityHistoryEventType[]>([
    EntityHistoryEventType.APPROVAL,
    EntityHistoryEventType.EDIT,
    EntityHistoryEventType.CREATE,
    EntityHistoryEventType.LINKING,
    EntityHistoryEventType.DELETE,
  ]);

  const { data, error, loading, fetchMore, networkStatus } =
    useEntityHistoryQuery({
      variables: {
        id: id ? id : '',
        filter: {
          eventTypes: eventTypes,
          searchTerm: debouncedTextFilter,
        },
        first: 10,
        order: historyDirection,
      },
      fetchPolicy: 'network-only',
    });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const currentTarget = event.currentTarget;
    if (
      data?.entityHistory?.pageInfo.hasNextPage &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
        currentTarget.scrollHeight
    ) {
      fetchMore({
        variables: {
          after: data?.entityHistory?.pageInfo.endCursor,
          before: undefined,
          first: 10,
          last: undefined,
        },
      });
    }
  };

  return (
    <Box>
      <EntityHistoryToolbar
        eventTypes={eventTypes}
        setEventTypes={setEventTypes}
        textFilter={textFilter}
        setTextFilter={setTextFilter}
        historyDirection={historyDirection}
        setHistoryDirection={setHistoryDirection}
      />
      <ScrollingContainer onScroll={handleScroll}>
        {!error ? (
          data?.entityHistory?.edges?.map((edge, index) => (
            <HistoryEventCard
              key={edge?.node?.id}
              eventId={edge?.node?.id}
              first={index === 0}
              last={
                data?.entityHistory?.edges?.length === undefined
                  ? false
                  : index === data?.entityHistory?.edges?.length - 1
              }
              event={edge?.node}
            />
          ))
        ) : (
          <ErrorResult data-testid="entity-history-error" />
        )}
        {data?.entityHistory?.pageInfo.hasNextPage && (
          <Box
            height={272}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={30} />
          </Box>
        )}
      </ScrollingContainer>
      <LoadingOverlay
        isLoading={loading && networkStatus === 1}
        data-testid="loading-history"
      />
    </Box>
  );
};

export default EntityHistory;
