import { useNavigate, useParams } from 'react-router-dom';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useUserQuery } from '../../queries';

import UserDetailProjectsTable from '../../components/UserProjectsTable';
import UserDetailGroupsTable from '../../components/UserGroupsTable';
import DetailTabs from '../../components/DetailTabs';

const userDetailTabsArray = ['projects', 'groups'];

const UserDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useUserQuery({ variables: { id: id ? id : '' } });
  const navigate = useNavigate();

  const handleOnBack = () => {
    navigate('/settings/groups');
  };

  if (!id) {
    return null;
  }

  return (
    <DetailTabs
      baseRoute="settings/users"
      id={id}
      loading={loading}
      tabs={userDetailTabsArray}
      title={data?.user?.user?.name || 'User Not Found'}
      onBack={handleOnBack}
    >
      <Routes>
        <Route path="projects" element={<UserDetailProjectsTable />} />
        <Route path="groups" element={<UserDetailGroupsTable />} />
        <Route path="*" element={<Navigate to="projects" replace />} />
      </Routes>
    </DetailTabs>
  );
};

export default UserDetail;
