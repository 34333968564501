import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { EntityTypes } from '../types/schemaTypes';

export const ENTITY_TYPES = gql`
  query EntityTypes {
    entityTypes {
      id
      name
      fields {
        id
        name
        description
        dataPoint {
          dataType
        }
        type @client
        inputOptions {
          __typename
          ... on TextInputOptions {
            type
          }
          ... on Autogenerated {
            displayOnEntry
          }
          ... on DateTimeInputOptions {
            captureTime
          }
          ... on LocalSelectInputOptions {
            type
            options {
              text
              value
            }
          }
          ... on NumericInputOptions {
            type
            precision
          }
        }
      }
      objectLinks {
        id
        name
        objectLabel
      }
      subjectLinks {
        id
        name
      }
      totalCount
    }
  }
`;

export const useEntityTypesQuery = (
  options?: QueryHookOptions<EntityTypes>,
) => {
  return useQuery<EntityTypes>(ENTITY_TYPES, { ...options });
};
