import { Box } from '@mui/material';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import UploadModalLinkingPanel from '../UploadModalLinkingPanel';
import produce from 'immer';
import { useRecoilState } from 'recoil';
import { uploadLinksAtom } from '../../atoms/uploadFiles';

const UploadModalLinking = () => {
  const [links, setLinks] = useRecoilState(uploadLinksAtom);

  const handleAddLink = (index: number) => () => {
    setLinks((prevState) => [
      ...prevState.slice(0, index),
      {
        selectedUploadIds: [],
        linkData: [{ field: '', value: '' }],
        linkTypeId: '',
        linkTargetId: '',
        linkTargetIds: [],
      },
      ...prevState.slice(index + 1),
    ]);
  };

  const handleRemoveLink = (index: number) => () => {
    setLinks((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };

  const handleFileSelect =
    (index: number) => (selectedId: string, checked: boolean) => {
      setLinks((prevState) =>
        produce(prevState, (draftState) => {
          if (checked) {
            draftState[index].selectedUploadIds = [
              ...new Set([...draftState[index].selectedUploadIds, selectedId]),
            ];
          } else {
            draftState[index].selectedUploadIds = draftState[
              index
            ].selectedUploadIds.filter((fileId) => fileId !== selectedId);
          }
        }),
      );
    };

  const handleRelationshipSelect = (index: number) => (linkTypeId: string) => {
    setLinks((prevState) =>
      produce(prevState, (draftState) => {
        draftState[index].linkTypeId = linkTypeId;
      }),
    );
  };

  const handleTargetSelect =
    (index: number) => (innerIndex: number) => (targetId: string) => {
      setLinks((prevState) =>
        produce(prevState, (draftState) => {
          const linkTargetIds = draftState[index].linkTargetIds;
          if (linkTargetIds) {
            linkTargetIds[innerIndex] = targetId;
          } else {
            draftState[index].linkTargetIds = [targetId];
          }
        }),
      );
    };

  const handleTargetDelete = (index: number) => (innerIndex: number) => () => {
    setLinks((prevState) =>
      produce(prevState, (draftState) => {
        const linkTargetIds = draftState[index].linkTargetIds;
        if (linkTargetIds) {
          delete linkTargetIds[innerIndex];
        }
      }),
    );
  };

  return (
    <Box py={1.5}>
      {links.map((link, index) => (
        <Box key={index} py={1.5}>
          <UploadModalLinkingPanel
            {...link}
            name={`Link ${index + 1}`}
            onRemove={links.length > 1 ? handleRemoveLink(index) : undefined}
            onFileSelect={handleFileSelect(index)}
            onRelationshipSelect={handleRelationshipSelect(index)}
            onTargetDelete={handleTargetDelete(index)}
            onTargetSelect={handleTargetSelect(index)}
          />
        </Box>
      ))}
      <Box py={1.5}>
        <SNButton
          startIcon={<AddIcon />}
          onClick={handleAddLink(links.length + 1)}
          hover
        >
          Add another link
        </SNButton>
      </Box>
    </Box>
  );
};

export default UploadModalLinking;
