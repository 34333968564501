import React from 'react';
import SNDialogTitle from '../SNDialogTitle';
import { useRecoilState } from 'recoil';
import { newEntityTypeAtom } from '../../atoms/NewEntityTypeAtom';
import {
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  DataPoints_dataPoints,
  DataType,
  InputType,
} from '../../types/schemaTypes';
import { Box } from '@mui/system';
import SNInput from '../SNInput';
import EntityTypeFieldTextInputOptionsForm from '../EntityTypeFieldTextInputOptionsForm';
import EntityTypeFieldDateTimeInputOptionsForm from '../EntityTypeFieldDateTimeInputOptionsForm';
import EntityTypeFieldSelectInputOptionsForm from '../EntityTypeFieldSelectInputOptionsForm';
import SNButton from '../SNButton';
import {
  AutogeneratedInputOptions,
  DateTimeInputOptions,
  LocalSelectInputOptions,
  NumericInputOptions,
  RemoteSelectInputOptions,
  TextInputOptions,
} from '../../types/newEntityTypeTypes';
import EntityTypeFieldNumberInputOptionsForm from '../EntityTypeFieldNumberInputOptionsForm';

export type PossibleInputOptions =
  | AutogeneratedInputOptions
  | DateTimeInputOptions
  | LocalSelectInputOptions
  | RemoteSelectInputOptions
  | NumericInputOptions
  | TextInputOptions;

export type SelectInputOptions =
  | RemoteSelectInputOptions
  | LocalSelectInputOptions;

interface EntityTypeDetailInputOptionsModalProps {
  columnId: string;
  dataPoints: DataPoints_dataPoints[];
  onClose: () => void;
}

const typeToAllowedInputMapping: { [type: string]: InputType[] } = {
  string: [InputType.SELECT, InputType.TEXT, InputType.TEXTAREA],
  int: [InputType.SELECT, InputType.TEXT],
  float: [InputType.SELECT, InputType.TEXT],
  uri: [InputType.SELECT, InputType.TEXT],
  url: [InputType.SELECT, InputType.TEXT],
  boolean: [InputType.SELECT],
  datetime: [InputType.TIMESTAMP],
};

const EntityTypeDetailInputOptionsModal = ({
  columnId,
  onClose,
  dataPoints,
}: EntityTypeDetailInputOptionsModalProps) => {
  const [entityTypeInfo, setEntityTypeInfo] = useRecoilState(newEntityTypeAtom);

  const columnInfo = React.useMemo(() => {
    return entityTypeInfo.fields[columnId || ''];
  }, [entityTypeInfo, columnId]);

  const defaultInputOptions = React.useMemo(() => {
    return {} as PossibleInputOptions;
  }, []);
  const [inputOptions, setInputOptions] = React.useState<PossibleInputOptions>(
    columnInfo?.inputOptions || defaultInputOptions,
  );

  const selectedInputType = React.useMemo(() => {
    return (
      inputOptions.type ||
      entityTypeInfo?.fields[columnId]?.inputOptions?.type ||
      undefined
    );
  }, [inputOptions, columnId, entityTypeInfo]);

  // NOTE: this might also be a 'generator' of predetermined type ie: a special string like 'date.today'. need to figure out how to deal with that.
  const startingDefault = entityTypeInfo?.fields[columnId]?.default_value
    ?.value as string | number | boolean;
  const startingGenerator = entityTypeInfo?.fields[columnId]?.default_value
    ?.generator as string;
  const [defaultValue, setDefaultValue] = React.useState<
    string | number | boolean
  >(startingDefault || startingGenerator || '');

  const selectedDataPoint = React.useMemo(() => {
    return dataPoints?.find((point) => {
      return point.id === entityTypeInfo?.fields[columnId || '']?.data_point;
    });
  }, [entityTypeInfo, dataPoints, columnId]);

  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSaveChanges = React.useCallback(() => {
    setEntityTypeInfo((prev) => {
      return {
        ...prev,
        fields: {
          ...prev.fields,
          [columnId]: {
            ...prev.fields[columnId],
            inputOptions: inputOptions,
            default_value: {
              value: defaultValue,
            },
          },
        },
      };
    });
    //clear out local input options when values are saved to recoil atom.
    setInputOptions(defaultInputOptions);
    handleClose();
  }, [
    defaultInputOptions,
    setInputOptions,
    handleClose,
    defaultValue,
    setEntityTypeInfo,
    inputOptions,
    columnId,
  ]);

  if (!selectedDataPoint) {
    return <Box>You must select a data point first</Box>;
  }

  const selectedDataType = (selectedDataPoint?.dataType || '').toLowerCase();

  const handleSelectInputType = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const key = e.target.value as keyof typeof InputType;
    const type = InputType[key] as InputType;
    //clear out local input options and default value state on input type change
    setInputOptions({ type: type });
    setDefaultValue('');
  };

  return (
    <>
      <SNDialogTitle onClose={handleClose}>Input Options</SNDialogTitle>
      <DialogContent>
        <Box m={3} ml={0}>
          <SNInput
            label="Choose an input option"
            value={selectedInputType}
            select
            onChange={handleSelectInputType}
          >
            {typeToAllowedInputMapping[selectedDataType].map(
              (inputType: InputType) => {
                return (
                  <MenuItem key={inputType} value={inputType}>
                    {inputType}
                  </MenuItem>
                );
              },
            )}
          </SNInput>
        </Box>
        <Box>
          {!selectedInputType && (
            <Box
              m={3}
              ml={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="textSecondary">
                This data column ({columnInfo.label}) is a{' '}
                {selectedDataPoint?.name} which has a data type of{' '}
                {selectedDataPoint?.dataType}.<br />
                Its possible to input a {selectedDataPoint?.dataType} in several
                ways. please choose your preference for how this{' '}
                {selectedDataPoint?.dataType} will be input using the select
                field above.
              </Typography>
            </Box>
          )}
          {(selectedInputType === InputType.TEXT ||
            selectedInputType === InputType.TEXTAREA) && (
            <>
              {selectedDataPoint?.dataType === DataType.FLOAT ||
              selectedDataPoint?.dataType === DataType.INT ? (
                <EntityTypeFieldNumberInputOptionsForm
                  inputOptions={inputOptions as NumericInputOptions}
                  setInputOptions={setInputOptions}
                  defaultValue={defaultValue}
                  setDefaultValue={setDefaultValue}
                />
              ) : (
                <EntityTypeFieldTextInputOptionsForm
                  inputOptions={inputOptions as TextInputOptions}
                  setInputOptions={setInputOptions}
                  defaultValue={defaultValue}
                  setDefaultValue={setDefaultValue}
                />
              )}
            </>
          )}
          {selectedInputType === InputType.TIMESTAMP && (
            <EntityTypeFieldDateTimeInputOptionsForm
              inputOptions={inputOptions as DateTimeInputOptions}
              setInputOptions={setInputOptions}
              defaultValue={defaultValue}
              setDefaultValue={setDefaultValue}
            />
          )}
          {selectedInputType === InputType.SELECT && (
            <EntityTypeFieldSelectInputOptionsForm
              inputOptions={inputOptions as SelectInputOptions}
              setInputOptions={setInputOptions}
              columnId={columnId}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleSaveChanges}>Save Changes</SNButton>
      </DialogActions>
    </>
  );
};

export default EntityTypeDetailInputOptionsModal;
