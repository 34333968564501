import { Box } from '@mui/system';
import React from 'react';
import ListSelectionControl from '../ListSelectionControl';
import SNPagination from '../SNPagination';
import { useGetEntityTypesListQuery } from '../../queries';
import SelectableListToolbar from '../SelectableListToolbar';
import SNTable from '../SNTable';
import EntityTypesTableRow from '../EntityTypesTableRow';
import { Dialog, Grid } from '@mui/material';
import ToolbarActionGroup from '../ToolbarActionGroup';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pageIdsAtom, selectedIdsAtom } from '../../atoms/listSelection';
import DeleteIcon from '@mui/icons-material/Delete';
import ListSearchControl from '../ListSearchControl';
import { useDebounce } from '../../hooks';
import NewEntityTypeModal from '../NewEntityTypeModal';
import { DEFAULT_PAGE_SIZE } from '../../constants';

const idealHeaders = [
  'Name',
  'Description',
  'Creator',
  'Updated',
  'Status',
  'Entity Count',
];

const EntityTypesTable = () => {
  const pageSize = DEFAULT_PAGE_SIZE;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [newTypeModalOpen, setNewTypeModalOpen] = React.useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const {
    data: entityTypesListData,
    loading: entityTypesListLoading,
    error: entityTypeListErrors,
    fetchMore: entityTypesListFetchMore,
  } = useGetEntityTypesListQuery({
    variables: {
      first: pageSize,
      filter: { query: debouncedSearchTerm },
    },
    onCompleted: (res) => {
      const pageIds = res.entityTypesList.data?.edges.map((edge) => {
        return edge.node.id;
      });
      setPageIds(pageIds || []);
    },
  });

  const typesToList = React.useMemo(() => {
    return (
      entityTypesListData?.entityTypesList.data?.edges.map((edge) => {
        return edge.node;
      }) || []
    );
  }, [entityTypesListData]);

  const pageTotal = entityTypesListData?.entityTypesList.data?.totalCount || 0;

  const handleNewTypeModalOpen = () => {
    setNewTypeModalOpen(true);
  };
  const handleNewTypeModalClosed = () => {
    setNewTypeModalOpen(false);
  };

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...entityTypesListData?.entityTypesList.data?.pageInfo}
            fetchMore={entityTypesListFetchMore}
            loading={entityTypesListLoading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={typesToList.length}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Dialog open={newTypeModalOpen} fullScreen>
                <NewEntityTypeModal onClose={handleNewTypeModalClosed} />
              </Dialog>
              <Grid item>
                <SNButton
                  startIcon={<AddIcon />}
                  snVariant="text"
                  onClick={handleNewTypeModalOpen}
                >
                  Add Type
                </SNButton>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count > 0}
            >
              <Grid item>
                <SNButton
                  startIcon={<DeleteIcon />}
                  snVariant="destructive"
                  onClick={() =>
                    //TODO: make mutaion call to delete entity types when we have the mutation available
                    console.log('we deletin things: ', selectedIds)
                  }
                >
                  Delete Type
                </SNButton>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={entityTypeListErrors}
        headers={idealHeaders}
        hasResults={typesToList.length > 0}
        id="entity-types-table"
        loading={entityTypesListLoading}
        rowCount={20}
        selectable
      >
        {typesToList.map(
          (
            { name, totalCount, id, description, createdBy, updatedAt, state },
            index,
          ) => {
            return (
              <EntityTypesTableRow
                key={id}
                index={index}
                id={id}
                name={name}
                totalCount={totalCount || 0}
                state={state}
                description={description}
                createdBy={createdBy}
                updatedAt={updatedAt}
              />
            );
          },
        )}
      </SNTable>
    </Box>
  );
};

export default EntityTypesTable;
