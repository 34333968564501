import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import React, { PropsWithChildren } from 'react';
import env from '@beam-australia/react-env';

import { typeDefs, resolvers } from './localState';
import { useAuth0 } from '@auth0/auth0-react';
import createCache from './ApolloCache';
import { onError } from '@apollo/client/link/error';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from './atoms/GeneralErrorSnackbarAtom';

type ServerSideError = ServerError | ServerParseError;

function isServerSideError(error: any): error is ServerSideError {
  return typeof error.statusCode === 'number';
}

const cache = createCache();

const AuthorizedApolloProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [cachedToken, setCachedToken] = React.useState<string | null>(null);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  React.useEffect(() => {
    async function fetchToken() {
      try {
        const response = await getAccessTokenSilently();
        setCachedToken(response);
      } catch {
        setCachedToken(null);
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Cypress) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);
      setCachedToken(auth0.token);
    } else {
      fetchToken();
    }
  }, [getAccessTokenSilently]);

  const baseLink = createHttpLink({
    uri: env('GRAPHQL_API_URL'), // your URI here...
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${cachedToken}`,
      },
    });
    return forward(operation);
  });

  const errorLink = new ApolloLink((operation, forward) => {
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        setGeneralError({
          open: true,
          message: graphQLErrors[0].message,
          details: graphQLErrors[0].toString(),
        });
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        setGeneralError({
          open: true,
          message: networkError.message,
          details: networkError.toString(),
        });
        if (
          isServerSideError(networkError) &&
          networkError.statusCode === 401
        ) {
          setCachedToken(null);
          return forward(operation);
        }
        console.log(`[Network error]: ${networkError.message}`);
      }
    });
    return forward(operation);
  });

  const link = from([authLink, errorLink, baseLink]);

  if (!cachedToken) return null;

  const apolloClient = new ApolloClient({
    link,
    cache,
    resolvers,
    typeDefs,
    assumeImmutableResults: true,
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
