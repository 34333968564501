import React, { PropsWithChildren } from 'react';
import { Box, Grid } from '@mui/material';
import { UserMenu } from '../../components';
import { SnthesisLogo } from '../../components/SvgIcon';

const TopBar: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Box
      px={4}
      height={72}
      bgcolor="common.black"
      zIndex={1}
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="fixed"
      top={0}
    >
      <Box display="flex" alignItems="center">
        <Box pr={4}>
          <SnthesisLogo />
        </Box>
        {children}
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item>
            <div id="new-search" />
          </Grid>
          <Grid item>
            <UserMenu />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TopBar;
