import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  DataPointsListQuery,
  DataPointsListQueryVariables,
} from '../types/new/graphql';

export const DATA_POINTS_LIST = gql`
  query DataPointsList(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: DataPointFilter
  ) {
    dataPointsList(
      last: $last
      first: $first
      before: $before
      after: $after
      filter: $filter
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
          endIndex @client
          startIndex @client
        }
        edges {
          cursor
          node {
            id
            name
            dataType
            description
            UIType @client
          }
        }
      }
      errors
    }
  }
`;

export const useGetLazyDataPointsListQuery = (
  options?: LazyQueryHookOptions<
    DataPointsListQuery,
    DataPointsListQueryVariables
  >,
) => {
  return useLazyQuery<DataPointsListQuery, DataPointsListQueryVariables>(
    DATA_POINTS_LIST,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useGetDataPointsListQuery = (
  options?: QueryHookOptions<DataPointsListQuery, DataPointsListQueryVariables>,
) => {
  return useQuery<DataPointsListQuery, DataPointsListQueryVariables>(
    DATA_POINTS_LIST,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};
