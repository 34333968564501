import {
  IconButton,
  Menu,
  IconButtonProps,
  IconProps,
  MenuProps,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { ReactElement } from 'react';

interface IconMenuProps extends Omit<MenuProps, 'open'> {
  buttonSize?: IconButtonProps['size'];
  fontSize?: IconProps['fontSize'];
  orientation?: 'vertical' | 'horizontal';
}

const IconMenu: React.FC<IconMenuProps> = ({
  buttonSize,
  fontSize,
  orientation = 'vertical',
  children,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={handleClick}
        size={buttonSize}
      >
        {orientation === 'vertical' && <MoreVertIcon fontSize={fontSize} />}
        {orientation === 'horizontal' && <MoreHorizIcon fontSize={fontSize} />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        {...rest}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const c: ReactElement = child;
            return React.cloneElement(c, {
              onClick: () => {
                if (child.props.onClick) child.props.onClick();
                handleClose();
              },
            });
          }
          return child;
        })}
      </Menu>
    </>
  );
};

export default IconMenu;
