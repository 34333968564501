import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import Autocomplete from '../Autocomplete';
import SNInput from '../SNInput';
import { useDebounce } from '../../hooks';
import generateTargetEntitySearch from '../../utils/generateTargetEntitiesSearch';
import {
  LinkEntities_entities_data_edges_node_entity,
  TargetEntity,
} from '../../types/schemaTypes';
import { useTargetEntitySearchQuery } from '../../queries';
import Checkbox from '../Checkbox/Checkbox';
import { entityTypes } from '../../constants/wellKnownDataTypes';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';

interface LinkingModalTargetEntityProps {
  allowedEntityTypeIds?: string[];
  disabled?: boolean;
  index: number;
  label?: string;
  onTargetSelect: (targetId: string) => void;
  targetEntityId: string | null;
  byPassable?: boolean;
}
const defaultAllowedEntities = [entityTypes.any];

const LinkingModalTargetEntity = ({
  allowedEntityTypeIds = defaultAllowedEntities,
  index,
  disabled,
  label,
  onTargetSelect,
  targetEntityId,
  byPassable,
}: LinkingModalTargetEntityProps) => {
  const [getTargetEntities, { data }] = useTargetEntitySearchQuery();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<TargetEntity | null>(null);

  const [expectedEntitiesOnly, setExpectedEntitiesOnly] = React.useState(true);

  const debouncedValue = useDebounce(inputValue, 300);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const client = useApolloClient();

  React.useEffect(() => {
    const foundFragment = client.readFragment<TargetEntity>({
      id: `Entity:${targetEntityId}`,
      fragment: gql`
        fragment TargetEntity on Entity {
          id
          displayName
        }
      `,
    });
    setValue(foundFragment);
  }, [client, targetEntityId]);

  React.useEffect(() => {
    const searchInput = generateTargetEntitySearch(
      expectedEntitiesOnly ? allowedEntityTypeIds : [entityTypes.any],
      debouncedValue,
    );
    getTargetEntities({
      variables: {
        first: 5,
        searchQuery: searchInput,
      },
    });
  }, [
    allowedEntityTypeIds,
    getTargetEntities,
    debouncedValue,
    expectedEntitiesOnly,
  ]);

  React.useEffect(() => {
    if (disabled) {
      setExpectedEntitiesOnly(true);
    }
  }, [disabled]);

  const handleInputChange = (
    event: React.ChangeEvent<unknown>,
    value: string,
  ) => {
    setInputValue(value);
  };

  const handleSelect = (
    _event: React.ChangeEvent<unknown>,
    value: LinkEntities_entities_data_edges_node_entity | null,
  ) => {
    if (value) {
      onTargetSelect(value.id);
      setInputValue('');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setExpectedEntitiesOnly(checked);
  };

  const options =
    (data &&
      data.entities &&
      data.entities.data &&
      data.entities.data.edges.map((edge) => edge.node.entity)) ||
    [];

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entities?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching target entities',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <Tooltip
        placement="top"
        title={disabled ? 'Please select a relationship type' : ''}
      >
        <div>
          <Autocomplete
            value={value}
            disabled={disabled}
            id={`entity_target_${index}`}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={options}
            getOptionLabel={(option) => option.displayName}
            isOptionEqualToValue={(
              option: LinkEntities_entities_data_edges_node_entity,
            ) => option.id === value?.id}
            autoSelect
            onChange={handleSelect}
            freeSolo={false}
            multiple={false}
            renderInput={({ InputProps, InputLabelProps, ...params }) => (
              <SNInput
                {...InputProps}
                {...params}
                className=""
                fullWidth
                label={label}
                placeholder="Search"
                error={!expectedEntitiesOnly}
              />
            )}
          />
        </div>
      </Tooltip>
      {byPassable && (
        <Box
          display="flex"
          mt={1}
          ml={-1}
          alignItems="center"
          component="label"
        >
          <Checkbox
            checked={expectedEntitiesOnly}
            disabled={disabled}
            onChange={handleChange}
          />
          <Box ml={1} color={disabled ? 'text.disabled' : 'text.primary'}>
            <Typography>Only show expected entities</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LinkingModalTargetEntity;
