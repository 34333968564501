import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/system';
import SNInput from '../SNInput';
import { TextInputOptions } from '../../types/newEntityTypeTypes';
import { PossibleInputOptions } from '../EntityTypeDetailInputOptionsModal/EntityTypeDetailInputOptionsModal';

interface EntityTypeFieldTextInputOptionsFormProps {
  setInputOptions: Dispatch<SetStateAction<PossibleInputOptions>>;
  inputOptions: TextInputOptions;
  setDefaultValue: Dispatch<SetStateAction<number | string | boolean>>;
  defaultValue: string | number | boolean;
}

const EntityTypeFieldTextInputOptionsForm = ({
  inputOptions,
  setInputOptions,
  setDefaultValue,
  defaultValue,
}: EntityTypeFieldTextInputOptionsFormProps) => {
  const handleMaxLengthChange = (e: any) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        maxLength: e.target.value,
      };
    });
  };
  const handleMinLengthChange = (e: any) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        minLength: e.target.value,
      };
    });
  };
  const onDefaultChange = (e: any) => {
    setDefaultValue(e.target.value);
  };

  return (
    <Box>
      The following options will determine specifics for how this text column
      must be filled in. fill out as many or as few of the options as you
      prefer.
      <Box>
        <SNInput
          value={inputOptions.minLength}
          type="number"
          label="Minimum length"
          onChange={handleMinLengthChange}
        />
        <SNInput
          value={inputOptions.maxLength}
          type="number"
          label="Maximum length"
          onChange={handleMaxLengthChange}
        />
        <SNInput
          value={defaultValue}
          label="Default Value"
          onChange={onDefaultChange}
        />
      </Box>
    </Box>
  );
};

export default EntityTypeFieldTextInputOptionsForm;
