import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from '../types/new/graphql';

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        id
        name
        description
        projectAccess {
          totalCount
        }
      }
      errors
    }
  }
`;

export const useUpdateProject = (
  options?: MutationHookOptions<
    UpdateProjectMutation,
    UpdateProjectMutationVariables
  >,
) => {
  return useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UPDATE_PROJECT,
    options,
  );
};
