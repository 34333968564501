import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  EntityTypeQuery,
  EntityTypeQueryVariables,
} from '../types/new/graphql';

export const ENTITY_TYPE = gql`
  query EntityType($id: ID!) {
    entityType(id: $id) {
      id
      name
      description
      createdBy {
        name
        picture
      }
      updatedAt
      rawDefinition
      fields {
        id
        name
        description
        dataPoint {
          dataType
        }
        type @client
        inputOptions {
          __typename
          ... on TextInputOptions {
            type
          }
          ... on Autogenerated {
            displayOnEntry
          }
          ... on DateTimeInputOptions {
            captureTime
          }
          ... on LocalSelectInputOptions {
            type
            options {
              text
              value
            }
          }
          ... on NumericInputOptions {
            type
            precision
          }
        }
      }
      objectLinks {
        id
        name
        objectLabel
      }
      subjectLinks {
        id
        name
      }
      totalCount
    }
  }
`;

export const useEntityTypeQuery = (
  options?: QueryHookOptions<EntityTypeQuery, EntityTypeQueryVariables>,
) => {
  return useQuery<EntityTypeQuery, EntityTypeQueryVariables>(ENTITY_TYPE, {
    ...options,
  });
};
