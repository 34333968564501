import React from 'react';
import { Box } from '@mui/system';
import {
  PossibleAddedValidation,
  WorkingAreaValidations,
} from './EntityTypeDetailsFieldValidationsModal';
import SNInput from '../SNInput';
import { NumericValidation } from '../../types/newEntityTypeTypes';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

interface NumericFieldValidationFormProps {
  setAddedValidation: React.Dispatch<
    React.SetStateAction<WorkingAreaValidations>
  >;
  addedValidation: PossibleAddedValidation;
}

const NumericFieldValidationForm = ({
  addedValidation,
  setAddedValidation,
}: NumericFieldValidationFormProps) => {
  const currentValues = React.useMemo(() => {
    return addedValidation.validation as NumericValidation;
  }, [addedValidation]);

  const handleChange = (e: any) => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            [e.target.name]: e.target.value,
          },
        },
      };
    });
  };

  const handleMinInclusiveToggle = () => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            min_inclusive: !currentValues.min_inclusive,
          },
        },
      };
    });
  };

  const handleMaxInclusiveToggle = () => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            max_inclusive: !currentValues.max_inclusive,
          },
        },
      };
    });
  };

  const handleIntToggle = () => {
    setAddedValidation((prev) => {
      return {
        ...prev,
        [addedValidation.id]: {
          ...addedValidation,
          validation: {
            ...currentValues,
            integer: !currentValues.integer,
          },
        },
      };
    });
  };

  return (
    <Box>
      <Box my={2}>
        <Typography color="textSecondary">
          A numeric validation ensures that a column is a number and between
          specific thresholds
        </Typography>
      </Box>
      <Box m={1} display="flex" justifyContent="space-between">
        <label>
          <Box py={1} color="grey.200">
            <Typography variant="h6" color="inherit">
              Is this an Integer?
            </Typography>
          </Box>
          <RadioGroup
            aria-label="Is this an integer?"
            value={currentValues.integer}
            onChange={handleIntToggle}
          >
            <Box display="flex">
              <FormControlLabel value control={<Radio />} label="Integer" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Not an Integer"
              />
            </Box>
          </RadioGroup>
        </label>
      </Box>
      <Box m={1} display="flex" justifyContent="space-between">
        <SNInput
          label="Minimum Value"
          name="min"
          type="number"
          onChange={handleChange}
          value={currentValues.min}
        />
        <Box ml={2}>
          <label>
            <Box py={1} color="grey.200">
              <Typography variant="h6" color="inherit">
                Minimum inclusive?
              </Typography>
            </Box>
            <RadioGroup
              aria-label="Minimum Inclusive"
              value={currentValues.min_inclusive}
              onChange={handleMinInclusiveToggle}
            >
              <Box display="flex">
                <FormControlLabel
                  value
                  control={<Radio />}
                  label="Include minimum"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Exclude minimum"
                />
              </Box>
            </RadioGroup>
          </label>
        </Box>
      </Box>
      <Box m={1} display="flex" justifyContent="space-between">
        <SNInput
          label="Maximum Value"
          name="max"
          type="number"
          onChange={handleChange}
          value={currentValues.max}
        />
        <Box ml={2}>
          <label>
            <Box py={1} color="grey.200">
              <Typography variant="h6" color="inherit">
                Maximum inclusive?
              </Typography>
            </Box>
            <RadioGroup
              aria-label="Maximum Inclusive"
              value={currentValues.max_inclusive}
              onChange={handleMaxInclusiveToggle}
            >
              <Box display="flex">
                <FormControlLabel
                  value
                  control={<Radio />}
                  label="Include maximum"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Exclude maximum"
                />
              </Box>
            </RadioGroup>
          </label>
        </Box>
      </Box>
      <Box m={1}>
        <SNInput
          required
          label="Error Message"
          name="message"
          helperText={
            currentValues.message || !addedValidation.showInputErrors ? (
              ''
            ) : (
              <Typography color="error">This value is required</Typography>
            )
          }
          onChange={handleChange}
          value={currentValues.message}
        />
      </Box>
    </Box>
  );
};

export default NumericFieldValidationForm;
