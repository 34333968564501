import { useApolloClient } from '@apollo/client';
import React from 'react';
import SNInput from '../SNInput';
import gql from 'graphql-tag';
import { Box, MenuItem, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { EntityTypes_entityTypes_fields } from '../../types/schemaTypes';

import { SNInputProps } from '../SNInput/SNInput';
import SNDatePicker from '../SNDatePicker';
import { getSafeDateValue } from '../../utils/getSafeDateValue';

interface EntityFieldProps extends Omit<SNInputProps, 'onChange'> {
  label: string;
  value: string | null;
  fieldId: string;
  isEditable: boolean;
  onChange?: (value: string, fieldDefinitionId: string) => void;
  id: string;
}

const DATA_FIELD_DEFINITION_FRAGMENT = gql`
  fragment FieldDetails on DataFieldDefinition {
    inputOptions {
      ... on TextInputOptions {
        type
      }
      ... on Autogenerated {
        displayOnEntry
      }
      ... on DateTimeInputOptions {
        captureTime
      }
      ... on LocalSelectInputOptions {
        type
        options {
          text
          value
        }
      }
      ... on NumericInputOptions {
        type
        precision
      }
    }
  }
`;
const EntityField: React.FC<EntityFieldProps> = ({
  fieldId,
  id,
  isEditable,
  label,
  onChange,
  value,
  helperText,
  status,
}) => {
  const [localValue, setLocalValue] = React.useState(value);
  const client = useApolloClient();
  const fieldDefinition = client.readFragment<EntityTypes_entityTypes_fields>({
    id: `DataFieldDefinition:${fieldId}`,
    fragment: DATA_FIELD_DEFINITION_FRAGMENT,
  });

  const handleBlur = () => {
    if (onChange) {
      onChange(localValue || '', fieldId);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setLocalValue(event.target.value);
  };

  const handleDateChange = (date: Moment | null) => {
    const newValue = date ? moment(date.toString()).format('YYYY-MM-DD') : null;
    setLocalValue(newValue);
  };

  if (!isEditable) {
    return (
      <Box>
        <Box py={1} color="grey.200">
          <Typography variant="h6" color="inherit">
            {label}
          </Typography>
        </Box>
        <Box border={1} borderColor="transparent">
          <Box display="flex" height={40} alignItems="center" p="10px">
            <Typography variant="overline">{value}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  switch (fieldDefinition?.inputOptions.__typename) {
    case 'LocalSelectInputOptions':
      return (
        <SNInput
          helperText={helperText}
          disabled={!isEditable}
          select
          fullWidth
          id={fieldId}
          label={label}
          value={localValue}
          onBlur={handleBlur}
          onChange={handleChange}
          status={status}
        >
          {fieldDefinition.inputOptions.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </SNInput>
      );
    case 'DateTimeInputOptions':
      return (
        <label>
          <Box py={1} color="grey.200">
            <Typography
              variant="h6"
              color="inherit"
              id={label ? `${id}-label` : undefined}
            >
              {label}
            </Typography>
          </Box>
          <SNDatePicker
            id={id}
            onBlur={handleBlur}
            onChange={handleDateChange}
            value={getSafeDateValue(localValue, value)}
          />
        </label>
      );
    default:
      return (
        <SNInput
          fullWidth
          disabled={!isEditable}
          id={fieldId}
          label={label}
          value={localValue || undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={helperText}
          status={status}
        />
      );
  }
};

export default EntityField;
