import React from 'react';
import { Typography, Box } from '@mui/material';
import SNTabs from './SNTabs';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ProfilePreferences from './ProfilePreferences';
import SNButton from './SNButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { useUserState } from '../contexts';
import UserDetailProjectsTableReadOnly from './UserProjectsTableReadOnly';
import UserGroupsTableReadOnly from './UserGroupsTableReadOnly/UserGroupsTableReadOnly';

const ProfileTabs = () => {
  const user = useUserState();
  const { tab = 'preferences' } = useParams<{ tab?: string }>();
  const navigate = useNavigate();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      navigate(`/profile/${tab}`);
    },
    [navigate],
  );

  return (
    <Box>
      <Box ml={4} pt={1}>
        <Box m={1}>
          <SNButton
            startIcon={<ArrowBackIcon />}
            snVariant="underline"
            onClick={() => navigate(-1)}
          >
            Back
          </SNButton>
        </Box>
        <Typography variant="h1">My Account</Typography>
      </Box>

      <SNTabs
        active={tab}
        onTabClick={handleTabClick}
        tabs={['preferences', 'projects', 'groups']}
      />
      <Routes>
        <Route
          path="projects"
          element={
            <UserDetailProjectsTableReadOnly desiredUserId={user.userId} />
          }
        />
        <Route path="preferences" element={<ProfilePreferences />} />
        <Route
          path="groups"
          element={
            <UserGroupsTableReadOnly
              desiredUserId={user.userId}
              allowSelection={false}
            />
          }
        />
        <Route path="*" element={<Navigate to="preferences" />} />
      </Routes>
    </Box>
  );
};

export default ProfileTabs;
