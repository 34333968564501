import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DialogActions, Box, Grid } from '@mui/material';
import styled from '../../services/styled';
import SNButton from '../SNButton';
import { Route, Routes } from 'react-router-dom';
import { useUpload, useUploadWizardState } from '../../contexts';
import { Link as RouterLink } from 'react-router-dom';

interface UploadModalActionsProps {
  hasSubsets: boolean;
  onUpload: () => void;
  onGuidePromptOptOut: () => void;
}

const UploadDialogActions = styled(DialogActions)`
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.25);
  padding: ${({ theme }) => theme.spacing(3)}px;
`;
const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

const UploadNowButton = ({
  onUpload,
}: Pick<UploadModalActionsProps, 'onUpload'>) => (
  <SNButton
    data-testid="upload-wizard-upload-now"
    snVariant="dark"
    onClick={onUpload}
  >
    Upload Now
  </SNButton>
);

const WizardBackButton = React.memo(({ to }: { to: string }) => (
  <StyledRouterLink to={to}>
    <SNButton snVariant="dark" startIcon={<ArrowBackIcon />}>
      Back
    </SNButton>
  </StyledRouterLink>
));

WizardBackButton.displayName = 'WizardBackButton';

interface WizardNextButtonProps {
  disabled?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
}

const WizardNextButton = React.memo(
  ({ disabled, to, onClick, testId, ...rest }: WizardNextButtonProps) =>
    disabled ? (
      <SNButton
        data-testid={testId}
        disabled
        snVariant="primary"
        endIcon={<ArrowForwardIcon />}
        onClick={onClick}
        {...rest}
      >
        Next
      </SNButton>
    ) : (
      <StyledRouterLink to={to} sx={{ background: 'transparent' }}>
        <SNButton
          data-testid={testId}
          snVariant="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={onClick}
          {...rest}
        >
          Next
        </SNButton>
      </StyledRouterLink>
    ),
);

WizardNextButton.displayName = 'WizardNextButton';

const UploadModalActions = ({
  hasSubsets,
  onUpload,
  onGuidePromptOptOut,
}: UploadModalActionsProps) => {
  const wizardState = useUploadWizardState();
  const upload = useUpload();

  const handleGuideNextClick = () => {
    onGuidePromptOptOut();
  };

  const handleUpload = React.useCallback(() => {
    upload();
    onUpload();
  }, [onUpload, upload]);

  return (
    <UploadDialogActions>
      <Routes>
        <Route
          path="files"
          element={
            <Box>
              <Grid container spacing={1}>
                <Grid item>
                  <UploadNowButton onUpload={handleUpload} />
                </Grid>
                <Grid item>
                  <WizardNextButton
                    testId="upload-wizard-files-next"
                    to={
                      wizardState.skipAllQuestions
                        ? `projects/content`
                        : '/upload/guide'
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          }
        />
        <Route
          path="guide"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <WizardBackButton to="files" />
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-guide-next"
                      disabled={wizardState.skipAllQuestions === null}
                      to={`/upload/projects/${
                        wizardState.skipAllQuestions ? 'content' : 'question'
                      }`}
                      onClick={() => {
                        console.log(
                          'going to either upload/projects/content or question',
                        );
                        handleGuideNextClick();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="projects/question"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                <WizardBackButton to="/upload/guide" />
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    {hasSubsets ? (
                      <WizardNextButton
                        testId="upload-wizard-projects-question-next"
                        disabled={wizardState.addProjects === null}
                        to={
                          wizardState.addProjects === true
                            ? '/upload/projects/content'
                            : '/upload/sheets/question'
                        }
                      />
                    ) : (
                      <WizardNextButton
                        testId="upload-wizard-projects-question-next"
                        disabled={wizardState.addProjects === null}
                        to={
                          wizardState.addProjects === true
                            ? '/upload/projects/content'
                            : '/upload/metadata/question'
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="projects/content"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                {!wizardState.skipAllQuestions && (
                  <WizardBackButton to="/upload/project/question" />
                )}
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    {hasSubsets ? (
                      <WizardNextButton
                        testId="upload-wizard-projects-question-next"
                        to={
                          wizardState.skipAllQuestions
                            ? '/upload/sheets/content'
                            : '/upload/sheets/question'
                        }
                      />
                    ) : (
                      <WizardNextButton
                        testId="upload-wizard-projects-question-next"
                        to={
                          wizardState.addMetadata === true
                            ? '/upload/metadata/content'
                            : '/upload/metadata/question'
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="sheets/question"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                {!wizardState.skipAllQuestions && (
                  <WizardBackButton to="/upload/projects/question" />
                )}
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-sheets-question-next"
                      disabled={wizardState.uploadAllSheets === null}
                      to={
                        wizardState.uploadAllSheets === false
                          ? '/upload/sheets/content'
                          : '/upload/metadata/question'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="sheets/content"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                {!wizardState.skipAllQuestions && (
                  <WizardBackButton to="upload/sheets/question" />
                )}
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-sheets-content-next"
                      to={
                        wizardState.skipAllQuestions
                          ? '/upload/metadata/content'
                          : '/upload/metadata/question'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="metadata/question"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                {!wizardState.skipAllQuestions && hasSubsets ? (
                  <WizardBackButton to="/upload/sheets/question" />
                ) : (
                  <WizardBackButton to="/upload/projects/question" />
                )}
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-metadata-question-next"
                      disabled={wizardState.addMetadata === null}
                      to={
                        wizardState.addMetadata === true
                          ? '/upload/metadata/content'
                          : '/upload/context/question'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="metadata/content"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                {!wizardState.skipAllQuestions && (
                  <WizardBackButton to="/upload/metadata/question" />
                )}
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-metadata-content-next"
                      to={
                        wizardState.skipAllQuestions
                          ? '/upload/context/content'
                          : '/upload/context/question'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="context/question"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <WizardBackButton to="/upload/metadata/question" />
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-context-question-next"
                      disabled={wizardState.createContext === null}
                      to={
                        wizardState.createContext === true
                          ? '/upload/context/content'
                          : '/upload/linking/question'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="context/content"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <WizardBackButton
                to={
                  wizardState.skipAllQuestions
                    ? '/upload/metadata/content'
                    : '/upload/context/question'
                }
              />
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-context-content-next"
                      to={
                        wizardState.skipAllQuestions
                          ? '/upload/linking/content'
                          : '/upload/linking/question'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="linking/question"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <WizardBackButton to="/upload/context/question" />
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      testId="upload-wizard-linking-question-next"
                      disabled={wizardState.linkData === null}
                      to={
                        wizardState.linkData
                          ? '/upload/linking/content'
                          : '/upload/summary'
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="linking/content"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <WizardBackButton
                to={
                  wizardState.skipAllQuestions
                    ? '/upload/context/content'
                    : '/upload/linking/question'
                }
              />
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <UploadNowButton onUpload={handleUpload} />
                  </Grid>
                  <Grid item>
                    <WizardNextButton
                      data-testid="upload-wizard-linking-content-next"
                      to="/upload/summary"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
        />
        <Route
          path="summary"
          element={
            <Box display="flex" justifyContent="space-between" width="100%">
              <WizardBackButton
                to={
                  wizardState.skipAllQuestions
                    ? '/upload/linking/content'
                    : '/upload/linking/question'
                }
              />
              <SNButton
                data-testid="upload-wizard-final-upload"
                hover
                snVariant="light"
                onClick={handleUpload}
              >
                Finalize Upload
              </SNButton>
            </Box>
          }
        />
      </Routes>
    </UploadDialogActions>
  );
};

export default UploadModalActions;
