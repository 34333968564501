import React from 'react';
import { Box, Dialog, Grid } from '@mui/material';
import ListSelectionControl from '../ListSelectionControl';
import SelectableListToolbar from '../SelectableListToolbar';
import ListSearchControl from '../ListSearchControl';
import ToolbarActionGroup from '../ToolbarActionGroup';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import { useGetListsQuery } from '../../queries/useGetListsQuery';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import SNTable from '../SNTable';
import SNPagination from '../SNPagination';
import ListsTableRow from '../ListsTableRow';
import { useDebounce } from '../../hooks';
import CreateListModal from '../CreateListModal/CreateListModal';
import { useDeleteListMutation } from '../../mutations';
import DeleteIcon from '@mui/icons-material/Delete';
import GeneralConfirmationModalProps from '../GeneralConfirmationModal';

const headers = ['name', 'description', 'items', 'created', 'updated'];

const ListsTable = () => {
  const pageSize = 30;
  const [searchTerm, setSearchTerm] = React.useState('');
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [createListModalOpen, setCreateListModalOpen] = React.useState(false);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const [warningModelOpen, setWarningModalOpen] = React.useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } = useGetListsQuery({
    variables: {
      first: pageSize,
      filter: {
        searchTerm: debouncedSearchTerm,
      },
    },
    onCompleted: (res) => {
      const pageIds = res?.getControlledVocabularyCategories?.data?.edges?.map(
        (edge) => edge.node.id,
      );
      setPageIds(pageIds || []);
    },
    onError: (e) => {
      setGeneralError({
        open: true,
        message: e.message,
        details: 'Error getting Lists',
      });
    },
  });
  const [deleteList] = useDeleteListMutation({
    variables: { ids: selectedIds },
    onCompleted: () => {
      resetSelectedIds();
    },
    onError: (e) => {
      setGeneralError({ message: e.message, details: e.message, open: true });
    },
  });

  const lists = React.useMemo(() => {
    return data?.getControlledVocabularyCategories?.data?.edges.map(
      (edge) => edge.node,
    );
  }, [data]);

  const closeAddModal = () => {
    setCreateListModalOpen(false);
    refetch();
  };

  const handleDeleteList = () => {
    deleteList().then(() => {
      refetch();
    });
  };

  const pageTotal = lists?.length || 0;

  const selectedListNames = React.useMemo(() => {
    return lists
      ? lists
          .filter((list) => {
            return selectedIds.includes(list.id);
          })
          .map((list) => list.name)
          .join(',\n ')
      : '';
  }, [selectedIds, lists]);

  return (
    <Box>
      <Dialog
        fullWidth
        open={warningModelOpen}
        onClose={() => setWarningModalOpen(false)}
      >
        <GeneralConfirmationModalProps
          onClose={() => setWarningModalOpen(false)}
          onProceed={handleDeleteList}
          message={`Are you sure you want to permanently delete the following lists: ${selectedListNames}`}
        />
      </Dialog>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.getControlledVocabularyCategories?.data?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={
              data?.getControlledVocabularyCategories?.data?.totalCount
            }
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item>
              <Dialog
                fullWidth
                open={createListModalOpen}
                onClose={closeAddModal}
              >
                <CreateListModal onClose={closeAddModal} />
              </Dialog>
              <SNButton
                startIcon={<AddIcon />}
                snVariant="text"
                onClick={() => setCreateListModalOpen(true)}
              >
                Add List
              </SNButton>
            </Grid>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count > 0}
            >
              <Grid item>
                <SNButton
                  startIcon={<DeleteIcon />}
                  snVariant="destructive"
                  onClick={() => setWarningModalOpen(true)}
                >
                  Delete list
                </SNButton>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!lists}
        headers={headers}
        id="lists"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {lists?.map(({ items, ...list }, index) => (
          <ListsTableRow
            key={list.id}
            index={index}
            itemCount={items?.totalCount || 0}
            {...list}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default ListsTable;
