import React from 'react';
import { Box } from '@mui/system';
import SNInput from '../SNInput';
import { StyledCloseButton } from '../DialogCloseButton/DialogCloseButton';
import Close from '@mui/icons-material/Close';
import { LocalSelectInputOptions } from '../../types/newEntityTypeTypes';

interface ManualItemProps {
  setInputOptions: React.Dispatch<
    React.SetStateAction<LocalSelectInputOptions>
  >;
  id: string;
  name: string;
  value: string;
  description?: string;
}

const SelectManualListItem = ({
  setInputOptions,
  id,
  name,
  value,
  description,
}: ManualItemProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputOptions((prev) => {
      const changedOption = prev.options.find((option) => {
        return option.id === id;
      });
      if (changedOption === undefined) {
        return prev;
      }
      // this is necessary to prevent runtime error related to deep copying objects.
      const newChangedOption = { ...changedOption };
      const remainingOptions = prev.options.filter((option) => {
        return option.id !== id;
      });
      newChangedOption[e.target.name] = e.target.value;

      return {
        ...prev,
        options: [newChangedOption, ...remainingOptions],
      };
    });
  };

  const handleDelete = (idToDelete: string) => {
    setInputOptions((previous) => {
      const remainingOptions = previous.options.filter((option) => {
        return option.id !== idToDelete;
      });
      return {
        ...previous,
        options: remainingOptions,
      };
    });
  };

  return (
    <Box display="flex">
      <Box>
        <SNInput
          required
          label="Text"
          name="text"
          onChange={handleChange}
          value={name}
        />
      </Box>
      <Box ml={2}>
        <SNInput
          required
          label="Value"
          name="value"
          onChange={handleChange}
          value={value}
        />
      </Box>
      <Box ml={2}>
        <SNInput
          label="Description"
          name="description"
          onChange={handleChange}
          value={description || ''}
        />
      </Box>
      <Box display="flex" alignItems="center" mt={4}>
        <StyledCloseButton onClick={() => handleDelete(id)} size="small">
          <Close fontSize="small" />
        </StyledCloseButton>
      </Box>
    </Box>
  );
};

export default SelectManualListItem;
