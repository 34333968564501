import { Box } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import { useSetRecoilState } from 'recoil';
import { pageIdsAtom } from '../../atoms/listSelection';
import { useParams } from 'react-router-dom';
import SNTable from '../SNTable';
import SNPagination from '../SNPagination';
import SearchableListToolbarActions from '../SearchableListToolbarActions';
import UserGroupsTableRowReadOnly from '../UserGroupsTableRowReadOnly';
import { useUserProfileGroupsQuery } from '../../queries';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useDebounce } from '../../hooks';

interface UserDetailGroupsTableProps {
  desiredUserId?: string;
  allowSelection?: boolean;
}

const UserGroupsTableReadOnly = ({
  desiredUserId,
  allowSelection,
}: UserDetailGroupsTableProps) => {
  const headers = ['name', 'description', 'Admin', 'date added'];
  const pageSize = 10;
  const { id } = useParams<{ id: string }>();
  const userId = React.useMemo(() => {
    return desiredUserId ? desiredUserId : id;
  }, [id, desiredUserId]);
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } =
    useUserProfileGroupsQuery({
      variables: {
        id: userId ? userId : '',
        first: pageSize,
        filter: { searchTerm: debouncedSearchTerm },
      },
      onCompleted: (result) => {
        const pageIds = result?.user?.user?.groupMembership?.edges.map(
          (edge) => edge.node.id,
        );
        setPageIds(pageIds || []);
      },
    });
  React.useEffect(() => {
    refetch({
      id: userId,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [data, refetch, userId, debouncedSearchTerm]);

  const pageTotal = data?.user?.user?.groupMembership?.edges.length || 0;

  const groupMembership = React.useMemo(
    () => data?.user?.user?.groupMembership?.edges.map((edge) => edge.node),
    [data],
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.user?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: "Error fetching user's groups",
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={3}>
          {allowSelection && (
            <Box display="flex" alignItems="center" pr={2}>
              <ListSelectionControl />
            </Box>
          )}
          <SNPagination
            {...data?.user?.user?.groupMembership?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.user?.user?.groupMembership?.totalCount}
          />
        </Box>
        <SearchableListToolbarActions
          value={searchTerm}
          setValue={setSearchTerm}
        />
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!groupMembership}
        headers={headers}
        id="user-groups"
        loading={loading}
        rowCount={pageTotal}
        selectable={false}
      >
        {groupMembership?.map(({ id, group, ...groupMembership }) => (
          <UserGroupsTableRowReadOnly
            key={id}
            admin={group?.createdBy}
            id={group?.id}
            name={group?.name}
            description={group?.description}
            {...groupMembership}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default UserGroupsTableReadOnly;
