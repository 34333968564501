import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import React from 'react';
import SNButton from '../SNButton';
import { CurrentEntityType } from '../../types/schemaTypes';
import EntityField from '../EntityField';
import {
  EditEntitiesState,
  useEditEntitiesDispatch,
  useEditEntitiesState,
} from '../../contexts';
import produce from 'immer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SEVERITY_PALETTE } from '../../constants';
import SNTooltip from '../SNTooltip';
import EditValidationsBanner from '../EditValidationsBanner';
import SNDialogTitle from '../SNDialogTitle';
import { gql, useApolloClient } from '@apollo/client';
import { useEntityDetailsQuery } from '../../queries/useEntityDetailsQuery';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

interface WorkbenchEntityFormProps {
  entityId: string;
  entityTypeId: string;
  onApprove: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

const EditEntityModal: React.FC<WorkbenchEntityFormProps> = ({
  entityId,
  entityTypeId,
  onApprove,
  onClose,
  onSubmit,
}) => {
  const editState = useEditEntitiesState();
  const setEditState = useEditEntitiesDispatch();

  const client = useApolloClient();

  const { data } = useEntityDetailsQuery({
    variables: { id: entityId },
  });

  const entityTypeFieldsById = React.useMemo(() => {
    try {
      const entityWithFields = client.readFragment<CurrentEntityType>({
        id: `EntityType:${entityTypeId}`,
        fragment: gql`
          fragment CurrentEntityType on EntityType {
            id
            fields {
              id
              name
            }
          }
        `,
      });
      return (
        entityWithFields?.fields.reduce<Record<string, string>>(
          (result, field) => ({
            ...result,
            [field.id]: field.name,
          }),
          {},
        ) || {}
      );
    } catch {
      return {};
    }
  }, [client, entityTypeId]);

  const handleDataEditChange = (value: string, fieldDefinitionId: string) => {
    setEditState(
      produce(editState, (draft) => {
        draft.edits[entityId] = {
          ...draft.edits[entityId],
          [fieldDefinitionId]: value,
        };
      }),
    );
  };

  const handleDataEditCancel = () => {
    setEditState(
      produce(editState, (draft) => {
        draft.edits = {};
        draft.validations = {};
      }),
    );
    onClose();
  };

  const getDisplayStatus = (
    editState: EditEntitiesState,
    fieldDefinitionId: string,
  ) => {
    if (
      !(
        editState &&
        editState.validations[entityId] &&
        editState.validations[entityId].fieldValidations[fieldDefinitionId]
      )
    ) {
      return undefined;
    } else {
      const status =
        editState.validations[entityId].fieldValidations[fieldDefinitionId]
          .displayStatus;
      return status ? SEVERITY_PALETTE[status] : undefined;
    }
  };

  const getFieldValidationMessages = (
    editState: EditEntitiesState,
    id: string,
    fieldDefinitionId: string,
  ) => {
    if (
      !(
        editState &&
        editState.validations[id] &&
        editState.validations[id].fieldValidations[fieldDefinitionId]
      )
    ) {
      return undefined;
    } else if (
      editState.validations[id].fieldValidations[fieldDefinitionId].annotations
        .length === 1
    ) {
      return (
        <Box>
          <Typography color="textPrimary">
            {
              editState.validations[id].fieldValidations[fieldDefinitionId]
                .annotations[0].message
            }
          </Typography>
        </Box>
      );
    } else {
      return (
        <ul>
          {editState.validations[id].fieldValidations[
            fieldDefinitionId
          ].annotations.map((annotation) => (
            <li key={annotation.message + fieldDefinitionId}>
              <Typography color="textPrimary">{annotation.message}</Typography>
            </li>
          ))}
        </ul>
      );
    }
  };

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entityById.errors]);
  }, [data]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>
        {`Editing ${data?.entityById.data?.displayName}`}
      </SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error, index) => {
                  return <li key={`${error}_${index}`}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        {editState &&
          editState.validations &&
          editState.validations[entityId] &&
          Object.keys(editState.validations[entityId]).length > 0 && (
            <EditValidationsBanner />
          )}
        {data?.entityById.data?.fieldValues.map(
          ({ value, fieldDefinition: { id: fieldDefinitionId } }) => (
            <EntityField
              key={fieldDefinitionId}
              fieldId={fieldDefinitionId}
              id={entityId}
              isEditable
              label={entityTypeFieldsById[fieldDefinitionId]}
              onChange={handleDataEditChange}
              value={value}
              status={getDisplayStatus(editState, fieldDefinitionId)}
              helperText={getFieldValidationMessages(
                editState,
                entityId,
                fieldDefinitionId,
              )}
            />
          ),
        )}
      </DialogContent>
      <Box display="flex" justifyContent="space-between" width="100%">
        <DialogActions>
          {editState?.validations[entityId]?.fieldValidations ? (
            <SNTooltip
              variant="light"
              title="Allows you to add notes and approve data as is"
            >
              <Box>
                <SNButton snVariant="text" onClick={onApprove}>
                  <Box display="flex">
                    <CheckCircleIcon />
                    &nbsp; Approve data
                  </Box>
                </SNButton>
              </Box>
            </SNTooltip>
          ) : null}
        </DialogActions>
        <DialogActions>
          <SNButton onClick={handleDataEditCancel}>Cancel</SNButton>
          <SNButton snVariant="primary" onClick={onSubmit}>
            Submit
          </SNButton>
        </DialogActions>
      </Box>
    </>
  );
};

export default EditEntityModal;
